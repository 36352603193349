export const AssistenciaSocialOptionsDTO = [
  {
    value: 'Prefeitura',
    label: 'Prefeitura',
    selected: false,
  },
  {
    value: 'Voluntários',
    label: 'Voluntários',
    selected: false,
  },
]
