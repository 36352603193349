import { Pipe, PipeTransform } from '@angular/core'
import { Items } from 'src/app/pages/necessidades-form/necessidades-form.component'

@Pipe({
  name: 'itemCategoryFilter',
  // pure: false,
})
export class itemCategoryFilterPipe implements PipeTransform {
  transform(items: Items[], filter: string): any {
    if (!items || !filter) {
      return items
    }
    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    return items.filter((item) => item.category.indexOf(filter) !== -1)
  }
}
