<br-message [state]="messageType" closable *ngIf="this.submitted">{{ message }}</br-message>

<div appModal center-content="" *ngIf="modalShow" style="flex: 1 1 0">
  <div
    *ngIf="modalShow"
    title="{{ titleModal }}"
    show
    closable
    style="
      display: flex;
      max-width: none;
      width: 95vw;
      min-height: 95%;
      max-height: 95%;
      background-color: white;
      padding: 10px;
      margin: auto;
    "
  >
    <div class="d-flex flex-column" style="width: 100%">
      <app-form-cadastro-de-abrigo
        [title]="titleModal"
        [typeEdit]="typeEdit"
        [edit]="editAbrigo"
        (done)="onFormSubmitted()"
        (close)="closeModal()"
      />
    </div>
  </div>
</div>

<div class="row row-action">
  <div class="col">
    <h1>Lista de abrigos</h1>
  </div>
  <div class="col items-col-left" appAuthorization [level]="authorizationService.AUTHORIZATION_LEVEL_SUPORTE">
    <br-button
      id="addAbrigo"
      label="Cadastrar Abrigo"
      type="primary"
      class="mt-3 mr-1"
      (click)="openModalAdd()"
      icon="plus"
      title="Cadastrar um novo abrigo"
    ></br-button>
    <br-button
      *ngIf="permission"
      id="exportTable"
      label="Exportar"
      icon="table"
      type="primary"
      class="mt-3 mr-1"
      title="Exportar dados em excel"
      (click)="exportToExcel()"
    ></br-button>
  </div>
</div>

<div class="row">
  <div class="col">
    <div class="card-deck">
      <div class="card">
        <div class="card-body">
          <h6 class="card-title">Total de Abrigos</h6>
          <p class="card-text">{{ (listaFiltrada | async).length }}</p>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <h6 class="card-title">Total de Vagas</h6>
          <p class="card-text">{{ calcularTotalVagas(listaFiltrada | async) }}</p>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <h6 class="card-title">Total de Vagas Ocupadas</h6>
          <p class="card-text">{{ calcularTotalVagasOcupadas(listaFiltrada | async) }}</p>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <h6 class="card-title">Total de Vagas Disponíveis</h6>
          <p class="card-text">{{ calcularVagasDisponiveis(listaFiltrada | async) }}</p>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <h6 class="card-title">Porcentagem de Vagas Ocupadas</h6>
          <p class="card-text">{{ calcularPorcentagemVagasOcupadas(listaFiltrada | async) }}%</p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col">
    <br-select
      class="mr-1"
      style="z-index: 2"
      [options]="statusOptions"
      label="Status:"
      (change)="handleStatusChange($event)"
    >
    </br-select>
  </div>
  <div class="col">
    <br-select
      multiple
      class="mr-1"
      style="z-index: 2"
      [options]="citiesOptionsSearch"
      label="Cidades:"
      (change)="handleChangeCity($event)"
    >
    </br-select>
  </div>
  <div class="col">
    <br-select
      multiple
      class="mr-1"
      style="z-index: 2"
      [options]="tipoAbrigoOptionsSearch"
      label="Tipo de Abrigo:"
      (change)="handleChangeTipoAbrigo($event)"
    >
    </br-select>
  </div>
  <div class="col">
    <br-input
      type="text"
      placeholder="Pesquisar por abrigo ou cidade"
      label="Busca:"
      [(ngModel)]="searchText"
      (input)="search()"
    ></br-input>
  </div>
  <div class="col">
    <div class="d-flex align-items-end">
      <br-select
        class="mr-1"
        style="z-index: 2"
        [options]="orderByOptions"
        label="Ordernar por:"
        (change)="handleOrderBy($event)"
      >
      </br-select>
      <br-button
        *ngIf="sortOrder === 'asc'"
        icon="sort-amount-up"
        circle
        type="secondary"
        (click)="handleSortOrder('desc')"
      ></br-button>
      <br-button
        *ngIf="sortOrder === 'desc'"
        icon="sort-amount-down"
        circle
        type="secondary"
        (click)="handleSortOrder('asc')"
      ></br-button>
    </div>
  </div>
</div>

<div class="row">
  <div class="col">
    <div class="table-wrapper">
      <table class="br-table">
        <thead>
          <tr>
            <th>Ação</th>
            <th>Nome</th>
            <th>Tipo Abrigo</th>
            <th>Tipo Local</th>
            <th>Cidade</th>
            <th>Endereço</th>
            <th>Gestor da Operação</th>

            <th width="180px">Vagas para Pessoas</th>
            <th>Vagas para Pets</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let abrigo of currentPageItems">
            <td>
              <div class="actions-button-flex">
                <br-button
                  type="primary"
                  icon="edit"
                  circle
                  class="small mr-1"
                  title="Editar abrigo"
                  (click)="openEditModalData(abrigo)"
                  appAuthorization
                  [level]="authorizationService.AUTHORIZATION_LEVEL_CONTROLADOR"
                ></br-button>
              </div>
            </td>
            <td [ngClass]="{ disabled: !abrigo.ativo }">{{ abrigo.nome }}</td>
            <td [ngClass]="{ disabled: !abrigo.ativo }">{{ abrigo.local_info["tipo_abrigo_grid"] }}</td>
            <td [ngClass]="{ disabled: !abrigo.ativo }">{{ abrigo.local_info["tipo_local_grid"] }}</td>
            <td [ngClass]="{ disabled: !abrigo.ativo }">{{ abrigo.endereco.cidade }}</td>
            <td [ngClass]="{ disabled: !abrigo.ativo }">{{ abrigo.endereco.rua }}</td>
            <td [ngClass]="{ disabled: !abrigo.ativo }">
              <div class="d-flex flex-column">
                <span> {{ abrigo.nome_gestor }} </span>
                <span>
                  {{ abrigo.telefone_gestor }}
                </span>
              </div>
            </td>

            <td [ngClass]="{ disabled: !abrigo.ativo }" *ngIf="abrigo.vagas_info.vagas_totais > 0">
              <div class="d-flex flex-column">
                <span> Geral: {{ abrigo.vagas_info.vagas_ocupadas }} de {{ abrigo.vagas_info.vagas_totais }} </span>
                <span *ngIf="abrigo.vagas_info.vaga_mulher">
                  Mulheres: {{ abrigo.vagas_info.vaga_mulher_quantidade_ocupadas }} de
                  {{ abrigo.vagas_info.vaga_mulher_quantidade }}
                </span>
                <span *ngIf="abrigo.vagas_info.vaga_mulheres_criancas">
                  Mulheres/Crianças: {{ abrigo.vagas_info.vaga_mulheres_criancas_quantidade_ocupadas }} de
                  {{ abrigo.vagas_info.vaga_mulheres_criancas_quantidade }}
                </span>
                <span *ngIf="abrigo.vagas_info.vaga_gestantes">
                  Gestantes: {{ abrigo.vagas_info.vaga_gestantes_quantidade_ocupadas }} de
                  {{ abrigo.vagas_info.vaga_gestantes_quantidade }}
                </span>
                <span *ngIf="abrigo.vagas_info.vaga_moradores_rua">
                  Moradores de Rua: {{ abrigo.vagas_info.vaga_moradores_rua_quantidade_ocupadas }} de
                  {{ abrigo.vagas_info.vaga_moradores_rua_quantidade }}
                </span>
                <span *ngIf="abrigo.vagas_info.vaga_adultos">
                  Adultos: {{ abrigo.vagas_info.vaga_adultos_quantidade_ocupadas }} de
                  {{ abrigo.vagas_info.vaga_adultos_quantidade }}
                </span>
                <span *ngIf="abrigo.vagas_info.vaga_idosos">
                  Idosos: {{ abrigo.vagas_info.vaga_idosos_quantidade_ocupadas }} de
                  {{ abrigo.vagas_info.vaga_idosos_quantidade }}
                </span>
                <span *ngIf="abrigo.vagas_info.vaga_autistas">
                  Autistas: {{ abrigo.vagas_info.vaga_autistas_quantidade_ocupadas }} de
                  {{ abrigo.vagas_info.vaga_autistas_quantidade }}
                </span>
                <span *ngIf="abrigo.vagas_info.vaga_pcd">
                  PCD: {{ abrigo.vagas_info.vaga_pcd_quantidade_ocupadas }} de
                  {{ abrigo.vagas_info.vaga_pcd_quantidade }}
                </span>
              </div>
            </td>
            <td *ngIf="abrigo.vagas_info.vagas_disponiveis <= 0"></td>

            <td *ngIf="abrigo.vagas_info.vaga_pets" [ngClass]="{ disabled: !abrigo.ativo }">
              <div class="d-flex flex-column">
                <span
                  >{{ abrigo.vagas_info.vaga_pets_quantidade_ocupadas }} de
                  {{ abrigo.vagas_info.vaga_pets_quantidade }}</span
                >
                <span>
                  {{ abrigo.vagas_info.vaga_pets_quais }}
                </span>
              </div>
            </td>
            <td *ngIf="!abrigo.vagas_info.vaga_pets" [ngClass]="{ disabled: !abrigo.ativo }"></td>
          </tr>
        </tbody>
      </table>
    </div>

    <app-pagination
      label="abrigos"
      [itemsPerPage]="itemsPerPage"
      [currentPage]="currentPage"
      [totalPages]="totalPages"
      [totalItems]="totalItems"
      (pageChange)="handlePageChange($event)"
      (itemsPerPageChange)="handleItemsPerPageChange($event)"
    >
    </app-pagination>
  </div>
</div>
