<fieldset [formGroup]="form">
  <legend>CADACIDADE DO ABRIGO</legend>

  <!-- vagas -->
  <div class="mb-3">
    <div class="row">
      <div formGroupName="vagas_info" class="col-3">
        <br-input
          type="number"
          min="0"
          id="vagas_totais"
          name="vagas_totais"
          placeholder="Número de vagas totais"
          label="Número de vagas totais:"
          formControlName="vagas_totais"
          (input)="formContainer.handleInput(['vagas_info', 'vagas_totais'])"
        >
        </br-input>

        <app-message
          *ngIf="formContainer.hasError(['vagas_info', 'vagas_totais'], 'required')"
          [control]="form.controls.vagas_info.controls.vagas_totais"
          error="required"
          message="O campo é obrigatório"
        ></app-message>
      </div>

      <div formGroupName="vagas_info" class="col-3">
        <br-input
          type="number"
          min="0"
          id="vagas_ocupadas"
          name="vagas_ocupadas"
          placeholder="Número de vagas ocupadas"
          label="Número de vagas ocupadas:"
          formControlName="vagas_ocupadas"
          (input)="formContainer.handleInput(['vagas_info', 'vagas_ocupadas'])"
        >
        </br-input>

        <app-message
          *ngIf="formContainer.hasError(['vagas_info', 'vagas_ocupadas'], 'required')"
          [control]="form.controls.vagas_info.controls.vagas_ocupadas"
          error="required"
          message="O campo é obrigatório"
        ></app-message>
      </div>
      
      <div formGroupName="vagas_info" class="col-3">
        <div class="br-input">
          <label for="input-default">Vagas disponíveis</label>
          <input id="input-default" type="text" formControlName="vagas_disponiveis" placeholder="Placeholder"/>
        </div>
      </div>
    </div>
  </div>

  <!-- vagas exclusivas -->
  <div formGroupName="vagas_info" class="mb-3">
    <div class="row">
      <div class="col-4 d-flex justify-content-start align-items-end switch-col pb-2">
        <div class="br-switch" role="presentation" style="width: 100%">
          <input
            checked="checked"
            type="checkbox"
            role="switch"
            id="vaga_mulher"
            name="vaga_mulher"
            formControlName="vaga_mulher"
            (change)="formContainer.changeSwitch([['vagas_info', 'vaga_mulher']], $event)"
          />
          <label for="vaga_mulher" style="width: 100%">Há vagas exclusivas para mulheres?</label>
        </div>
      </div>

      <div class="col-4" *ngIf="form.value.vagas_info.vaga_mulher">
        <br-input
          type="number"
          min="0"
          id="vaga_mulher_quantidade"
          name="vaga_mulher_quantidade"
          label="Vagas disponíveis:"
          placeholder="Vagas disponíveis"
          formControlName="vaga_mulher_quantidade"
          (input)="formContainer.handleInput(['vagas_info', 'vaga_mulher_quantidade'])"
        >
        </br-input>
        <div *ngIf="form.get(['vagas_info', 'vaga_mulher_quantidade']).invalid">
          <app-message
            [control]="form.get(['vagas_info', 'vaga_mulher_quantidade'])"
            error="required"
            message="O campo é obrigatório"
          ></app-message>
        </div>
      </div>

      <div class="col-4" *ngIf="form.value.vagas_info.vaga_mulher">
        <br-input
          type="number"
          min="0"
          id="vaga_mulher_quantidade_ocupadas"
          name="vaga_mulher_quantidade_ocupadas"
          placeholder="Vagas ocupadas"
          label="Vagas ocupadas:"
          formControlName="vaga_mulher_quantidade_ocupadas"
          (input)="formContainer.handleInput(['vagas_info', 'vaga_mulher_quantidade_ocupadas'])"
        >
        </br-input>
        <div *ngIf="form.get(['vagas_info', 'vaga_mulher_quantidade_ocupadas']).invalid">
          <app-message
            [control]="form.get(['vagas_info', 'vaga_mulher_quantidade_ocupadas'])"
            error="required"
            message="O campo é obrigatório"
          ></app-message>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-4 d-flex justify-content-start align-items-end switch-col pb-2">
        <div class="br-switch" style="width: 100%" role="presentation">
          <input
            checked="checked"
            type="checkbox"
            role="switch"
            id="vaga_mulheres_criancas"
            name="vaga_mulheres_criancas"
            formControlName="vaga_mulheres_criancas"
            (change)="formContainer.changeSwitch([['vagas_info', 'vaga_mulheres_criancas']], $event)"
          />
          <label for="vaga_mulheres_criancas" style="width: 100%">Há vagas exclusivas para mulheres e crianças?</label>
        </div>
      </div>

      <div class="col-4" *ngIf="form.value.vagas_info.vaga_mulheres_criancas">
        <br-input
          type="number"
          min="0"
          id="vaga_mulheres_criancas_quantidade"
          name="vaga_mulheres_criancas_quantidade"
          label="Vagas disponíveis:"
          placeholder="Vagas disponíveis"
          formControlName="vaga_mulheres_criancas_quantidade"
          (input)="formContainer.handleInput(['vagas_info', 'vaga_mulheres_criancas_quantidade'])"
        >
        </br-input>
        <div *ngIf="form.get(['vagas_info', 'vaga_mulheres_criancas_quantidade']).invalid">
          <app-message
            [control]="form.get(['vagas_info', 'vaga_mulheres_criancas_quantidade'])"
            error="required"
            message="O campo é obrigatório"
          ></app-message>
        </div>
      </div>

      <div class="col-4" *ngIf="form.value.vagas_info.vaga_mulheres_criancas">
        <br-input
          type="number"
          min="0"
          id="vaga_mulheres_criancas_quantidade_ocupadas"
          name="vaga_mulheres_criancas_quantidade_ocupadas"
          placeholder="Vagas ocupadas"
          label="Vagas ocupadas:"
          formControlName="vaga_mulheres_criancas_quantidade_ocupadas"
          (input)="formContainer.handleInput(['vagas_info', 'vaga_mulheres_criancas_quantidade_ocupadas'])"
        >
        </br-input>
        <div *ngIf="form.get(['vagas_info', 'vaga_mulheres_criancas_quantidade_ocupadas']).invalid">
          <app-message
            [control]="form.get(['vagas_info', 'vaga_mulheres_criancas_quantidade_ocupadas'])"
            error="required"
            message="O campo é obrigatório"
          ></app-message>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-4 d-flex justify-content-start align-items-end switch-col pb-2">
        <div class="br-switch" style="width: 100%" role="presentation">
          <input
            checked="checked"
            type="checkbox"
            role="switch"
            id="vaga_gestantes"
            name="vaga_gestantes"
            formControlName="vaga_gestantes"
            (change)="formContainer.changeSwitch([['vagas_info', 'vaga_gestantes']], $event)"
          />
          <label for="vaga_gestantes" style="width: 100%">Há vagas exclusivas para gestantes?</label>
        </div>
      </div>

      <div class="col-4" *ngIf="form.value.vagas_info.vaga_gestantes">
        <br-input
          type="number"
          min="0"
          id="vaga_gestantes_quantidade"
          name="vaga_gestantes_quantidade"
          label="Vagas disponíveis:"
          placeholder="Vagas disponíveis"
          formControlName="vaga_gestantes_quantidade"
          (input)="formContainer.handleInput(['vagas_info', 'vaga_gestantes_quantidade'])"
        >
        </br-input>
        <div *ngIf="form.get(['vagas_info', 'vaga_gestantes_quantidade']).invalid">
          <app-message
            [control]="form.get(['vagas_info', 'vaga_gestantes_quantidade'])"
            error="required"
            message="O campo é obrigatório"
          ></app-message>
        </div>
      </div>

      <div class="col-4" *ngIf="form.value.vagas_info.vaga_gestantes">
        <br-input
          type="number"
          min="0"
          id="vaga_gestantes_quantidade_ocupadas"
          name="vaga_gestantes_quantidade_ocupadas"
          placeholder="Vagas ocupadas"
          label="Vagas ocupadas:"
          formControlName="vaga_gestantes_quantidade_ocupadas"
          (input)="formContainer.handleInput(['vagas_info', 'vaga_gestantes_quantidade_ocupadas'])"
        >
        </br-input>
        <div *ngIf="form.get(['vagas_info', 'vaga_gestantes_quantidade_ocupadas']).invalid">
          <app-message
            [control]="form.get(['vagas_info', 'vaga_gestantes_quantidade_ocupadas'])"
            error="required"
            message="O campo é obrigatório"
          ></app-message>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-4 d-flex justify-content-start align-items-end switch-col pb-2">
        <div class="br-switch" style="width: 100%" role="presentation">
          <input
            checked="checked"
            type="checkbox"
            role="switch"
            id="vaga_moradores_rua"
            name="vaga_moradores_rua"
            formControlName="vaga_moradores_rua"
            (change)="formContainer.changeSwitch([['vagas_info', 'vaga_moradores_rua']], $event)"
          />
          <label for="vaga_moradores_rua" style="width: 100%">Há vagas exclusivas para moradores de rua?</label>
        </div>
      </div>

      <div class="col-4" *ngIf="form.value.vagas_info.vaga_moradores_rua">
        <br-input
          type="number"
          min="0"
          id="vaga_moradores_rua_quantidade"
          name="vaga_moradores_rua_quantidade"
          label="Vagas disponíveis:"
          placeholder="Vagas disponíveis"
          formControlName="vaga_moradores_rua_quantidade"
          (input)="formContainer.handleInput(['vagas_info', 'vaga_moradores_rua_quantidade'])"
        >
        </br-input>
        <div *ngIf="form.get(['vagas_info', 'vaga_moradores_rua_quantidade']).invalid">
          <app-message
            [control]="form.get(['vagas_info', 'vaga_moradores_rua_quantidade'])"
            error="required"
            message="O campo é obrigatório"
          ></app-message>
        </div>
      </div>

      <div class="col-4" *ngIf="form.value.vagas_info.vaga_moradores_rua">
        <br-input
          type="number"
          min="0"
          id="vaga_moradores_rua_quantidade_ocupadas"
          name="vaga_moradores_rua_quantidade_ocupadas"
          placeholder="Vagas ocupadas"
          label="Vagas ocupadas:"
          formControlName="vaga_moradores_rua_quantidade_ocupadas"
          (input)="formContainer.handleInput(['vagas_info', 'vaga_moradores_rua_quantidade_ocupadas'])"
        >
        </br-input>
        <div *ngIf="form.get(['vagas_info', 'vaga_moradores_rua_quantidade_ocupadas']).invalid">
          <app-message
            [control]="form.get(['vagas_info', 'vaga_moradores_rua_quantidade_ocupadas'])"
            error="required"
            message="O campo é obrigatório"
          ></app-message>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-4 d-flex justify-content-start align-items-end switch-col pb-2">
        <div class="br-switch" style="width: 100%" role="presentation">
          <input
            checked="checked"
            type="checkbox"
            role="switch"
            id="vaga_adultos"
            name="vaga_adultos"
            formControlName="vaga_adultos"
            (change)="formContainer.changeSwitch([['vagas_info', 'vaga_adultos']], $event)"
          />
          <label for="vaga_adultos" style="width: 100%">Há vagas exclusivas para adultos (> 18 anos)?</label>
        </div>
      </div>

      <div class="col-4" *ngIf="form.value.vagas_info.vaga_adultos">
        <br-input
          type="number"
          min="0"
          id="vaga_adultos_quantidade"
          name="vaga_adultos_quantidade"
          label="Vagas disponíveis:"
          placeholder="Vagas disponíveis"
          formControlName="vaga_adultos_quantidade"
          (input)="formContainer.handleInput(['vagas_info', 'vaga_adultos_quantidade'])"
        >
        </br-input>
        <div *ngIf="form.get(['vagas_info', 'vaga_adultos_quantidade']).invalid">
          <app-message
            [control]="form.get(['vagas_info', 'vaga_adultos_quantidade'])"
            error="required"
            message="O campo é obrigatório"
          ></app-message>
        </div>
      </div>

      <div class="col-4" *ngIf="form.value.vagas_info.vaga_adultos">
        <br-input
          type="number"
          min="0"
          id="vaga_adultos_quantidade_ocupadas"
          name="vaga_adultos_quantidade_ocupadas"
          placeholder="Vagas ocupadas"
          label="Vagas ocupadas:"
          formControlName="vaga_adultos_quantidade_ocupadas"
          (input)="formContainer.handleInput(['vagas_info', 'vaga_adultos_quantidade_ocupadas'])"
        >
        </br-input>
        <div *ngIf="form.get(['vagas_info', 'vaga_adultos_quantidade_ocupadas']).invalid">
          <app-message
            [control]="form.get(['vagas_info', 'vaga_adultos_quantidade_ocupadas'])"
            error="required"
            message="O campo é obrigatório"
          ></app-message>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-4 d-flex justify-content-start align-items-end switch-col pb-2">
        <div class="br-switch" style="width: 100%" role="presentation">
          <input
            checked="checked"
            type="checkbox"
            role="switch"
            id="vaga_idosos"
            name="vaga_idosos"
            formControlName="vaga_idosos"
            (change)="formContainer.changeSwitch([['vagas_info', 'vaga_idosos']], $event)"
          />
          <label for="vaga_idosos" style="width: 100%">Há vagas exclusivas para idosos (> 65 anos)?</label>
        </div>
      </div>

      <div class="col-4" *ngIf="form.value.vagas_info.vaga_idosos">
        <br-input
          type="number"
          min="0"
          id="vaga_idosos_quantidade"
          name="vaga_idosos_quantidade"
          label="Vagas disponíveis:"
          placeholder="Vagas disponíveis"
          formControlName="vaga_idosos_quantidade"
          (input)="formContainer.handleInput(['vagas_info', 'vaga_idosos_quantidade'])"
        >
        </br-input>
        <div *ngIf="form.get(['vagas_info', 'vaga_idosos_quantidade']).invalid">
          <app-message
            [control]="form.get(['vagas_info', 'vaga_idosos_quantidade'])"
            error="required"
            message="O campo é obrigatório"
          ></app-message>
        </div>
      </div>

      <div class="col-4" *ngIf="form.value.vagas_info.vaga_idosos">
        <br-input
          type="number"
          min="0"
          id="vaga_idosos_quantidade_ocupadas"
          name="vaga_idosos_quantidade_ocupadas"
          placeholder="Vagas ocupadas"
          label="Vagas ocupadas:"
          formControlName="vaga_idosos_quantidade_ocupadas"
          (input)="formContainer.handleInput(['vagas_info', 'vaga_idosos_quantidade_ocupadas'])"
        >
        </br-input>
        <div *ngIf="form.get(['vagas_info', 'vaga_idosos_quantidade_ocupadas']).invalid">
          <app-message
            [control]="form.get(['vagas_info', 'vaga_idosos_quantidade_ocupadas'])"
            error="required"
            message="O campo é obrigatório"
          ></app-message>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-4 d-flex justify-content-start align-items-end switch-col pb-2">
        <div class="br-switch" style="width: 100%" role="presentation">
          <input
            checked="checked"
            type="checkbox"
            role="switch"
            id="vaga_autistas"
            name="vaga_autistas"
            formControlName="vaga_autistas"
            (change)="formContainer.changeSwitch([['vagas_info', 'vaga_autistas']], $event)"
          />
          <label for="vaga_autistas" style="width: 100%">Há vagas exclusivas para pessoas autistas?</label>
        </div>
      </div>

      <div class="col-4" *ngIf="form.value.vagas_info.vaga_autistas">
        <br-input
          type="number"
          min="0"
          id="vaga_autistas_quantidade"
          name="vaga_autistas_quantidade"
          label="Vagas disponíveis:"
          placeholder="Vagas disponíveis"
          formControlName="vaga_autistas_quantidade"
          (input)="formContainer.handleInput(['vagas_info', 'vaga_autistas_quantidade'])"
        >
        </br-input>
        <div *ngIf="form.get(['vagas_info', 'vaga_autistas_quantidade']).invalid">
          <app-message
            [control]="form.get(['vagas_info', 'vaga_autistas_quantidade'])"
            error="required"
            message="O campo é obrigatório"
          ></app-message>
        </div>
      </div>

      <div class="col-4" *ngIf="form.value.vagas_info.vaga_autistas">
        <br-input
          type="number"
          min="0"
          id="vaga_autistas_quantidade_ocupadas"
          name="vaga_autistas_quantidade_ocupadas"
          placeholder="Vagas ocupadas"
          label="Vagas ocupadas:"
          formControlName="vaga_autistas_quantidade_ocupadas"
          (input)="formContainer.handleInput(['vagas_info', 'vaga_autistas_quantidade_ocupadas'])"
        >
        </br-input>
        <div *ngIf="form.get(['vagas_info', 'vaga_autistas_quantidade_ocupadas']).invalid">
          <app-message
            [control]="form.get(['vagas_info', 'vaga_autistas_quantidade_ocupadas'])"
            error="required"
            message="O campo é obrigatório"
          ></app-message>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-4 d-flex justify-content-start align-items-end switch-col pb-2">
        <div class="br-switch" style="width: 100%" role="presentation">
          <input
            checked="checked"
            type="checkbox"
            role="switch"
            id="vaga_pcd"
            name="vaga_pcd"
            formControlName="vaga_pcd"
            (change)="formContainer.changeSwitch([['vagas_info', 'vaga_pcd']], $event)"
          />
          <label for="vaga_pcd" style="width: 100%">Há vagas exclusivas para PCD?</label>
        </div>
      </div>

      <div class="col-4" *ngIf="form.value.vagas_info.vaga_pcd">
        <br-input
          type="number"
          min="0"
          id="vaga_pcd_quantidade"
          name="vaga_pcd_quantidade"
          label="Vagas disponíveis:"
          placeholder="Vagas disponíveis"
          formControlName="vaga_pcd_quantidade"
          (input)="formContainer.handleInput(['vagas_info', 'vaga_pcd_quantidade'])"
        >
        </br-input>
        <div *ngIf="form.get(['vagas_info', 'vaga_pcd_quantidade']).invalid">
          <app-message
            [control]="form.get(['vagas_info', 'vaga_pcd_quantidade'])"
            error="required"
            message="O campo é obrigatório"
          ></app-message>
        </div>
      </div>

      <div class="col-4" *ngIf="form.value.vagas_info.vaga_pcd">
        <br-input
          type="number"
          min="0"
          id="vaga_pcd_quantidade_ocupadas"
          name="vaga_pcd_quantidade_ocupadas"
          placeholder="Vagas ocupadas"
          label="Vagas ocupadas:"
          formControlName="vaga_pcd_quantidade_ocupadas"
          (input)="formContainer.handleInput(['vagas_info', 'vaga_pcd_quantidade_ocupadas'])"
        >
        </br-input>
        <div *ngIf="form.get(['vagas_info', 'vaga_pcd_quantidade_ocupadas']).invalid">
          <app-message
            [control]="form.get(['vagas_info', 'vaga_pcd_quantidade_ocupadas'])"
            error="required"
            message="O campo é obrigatório"
          ></app-message>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-4 d-flex justify-content-start align-items-end switch-col pb-2">
        <div class="br-switch" style="width: 100%" role="presentation">
          <input
            checked="checked"
            type="checkbox"
            role="switch"
            id="vaga_pets"
            name="vaga_pets"
            formControlName="vaga_pets"
            (change)="formContainer.changeSwitch([['vagas_info', 'vaga_pets']], $event)"
          />
          <label for="vaga_pets" style="width: 100%">Há vagas exclusivas para Pets?</label>
        </div>
      </div>

      <div class="col-4" *ngIf="form.value.vagas_info.vaga_pets">
        <br-input
          type="number"
          min="0"
          id="vaga_pets_quantidade"
          name="vaga_pets_quantidade"
          label="Vagas disponíveis:"
          placeholder="Vagas disponíveis"
          formControlName="vaga_pets_quantidade"
          (input)="formContainer.handleInput(['vagas_info', 'vaga_pets_quantidade'])"
        >
        </br-input>
        <div *ngIf="form.get(['vagas_info', 'vaga_pets_quantidade']).invalid">
          <app-message
            [control]="form.get(['vagas_info', 'vaga_pets_quantidade'])"
            error="required"
            message="O campo é obrigatório"
          ></app-message>
        </div>
      </div>

      <div class="col-4" *ngIf="form.value.vagas_info.vaga_pets">
        <br-input
          type="number"
          min="0"
          id="vaga_pets_quantidade_ocupadas"
          name="vaga_pets_quantidade_ocupadas"
          placeholder="Vagas ocupadas"
          label="Vagas ocupadas:"
          formControlName="vaga_pets_quantidade_ocupadas"
          (input)="formContainer.handleInput(['vagas_info', 'vaga_pets_quantidade_ocupadas'])"
        >
        </br-input>
        <div *ngIf="form.get(['vagas_info', 'vaga_pets_quantidade_ocupadas']).invalid">
          <app-message
            [control]="form.get(['vagas_info', 'vaga_pets_quantidade_ocupadas'])"
            error="required"
            message="O campo é obrigatório"
          ></app-message>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="form.value.vagas_info.vaga_pets">
      <div class="col-4"></div>

      <div class="col-8">
        <br-input
          id="vaga_pets_quais"
          name="vaga_pets_quais"
          label="Quais Pets?"
          placeholder="Quais Pets?"
          formControlName="vaga_pets_quais"
          (input)="formContainer.handleInput(['vagas_info', 'vaga_pets_quais'])"
        >
        </br-input>
        <div *ngIf="form.get(['vagas_info', 'vaga_pets_quais']).invalid">
          <app-message
            [control]="form.get(['vagas_info', 'vaga_pets_quais'])"
            error="required"
            message="O campo é obrigatório"
          ></app-message>
        </div>
      </div>
    </div>
  </div>
</fieldset>
