export const LocalOptionsDTO = [
  {
    value: 'Abrigo',
    label: 'Abrigo',
    selected: false,
  },
  {
    value: 'Triagem de pessoas',
    label: 'Triagem de pessoas',
    selected: false,
  },
  {
    value: 'Triagem de doações',
    label: 'Triagem de doações',
    selected: false,
  },
  {
    value: 'Ponto de coleta de doações',
    label: 'Ponto de coleta de doações',
    selected: false,
  },
  {
    value: 'Ponto de retirada de doações',
    label: 'Ponto de retirada de doações',
    selected: false,
  },
]
