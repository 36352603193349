export const AbrigoOptionsDTO = [
  {
    value: 'Abrigo Terceirizado',
    label: 'Abrigo Terceirizado',
    selected: false,
  },
  {
    value: 'Abrigo Referenciado',
    label: 'Abrigo Referenciado',
    selected: false,
  },
  {
    value: 'Abrigo Parceiro',
    label: 'Abrigo Parceiro',
    selected: false,
  },
]
