<div appModal *ngIf="show">
  <div class="context">
    <div class="body">
      <br-message [state]="messageType" closable *ngIf="this.submitted">{{ message }}</br-message>

      <form style="display: flex; flex-direction: column" [formGroup]="form">
        <h4 style="margin-top: 0">Informe a ação sobre o abrigado:</h4>
        <div class="row">
          <h5>{{ abrigado.nome }}</h5>
          <div class="col" style="display: flex; flex-direction: column">
            <span>Situação atual: {{ abrigado.status || (abrigado.alocado ? "Alocado" : "Não informado") }}</span>
            <span>Situação nova: {{ newStatus }}</span>
          </div>
        </div>
        <div class="row" style="margin-top: 11px">
          <br-button
            primary
            label="Transferir para outro abrigo"
            style="margin-right: 10px"
            (click)="setStatus('Transferido')"
          ></br-button>
          <br-button primary label="Sair do Abrigo" (click)="setStatus('Saiu do abrigo')"></br-button>
        </div>
        <br-select
          style="margin-top: 10px"
          *ngIf="newStatus === 'Transferido'"
          #ddlAbrigos
          label="Abrigo"
          placeholder="Selecione o Abrigo"
          [options]="abrigosMapped"
          (change)="onAbrigoChange($event)"
        ></br-select>
        <div class="row" style="margin-top: 10px">
          <div class="col">
            <h4>Informe a data do evento para registro :</h4>
            <div class="row">
              <div class="col">
                <br-input type="date" label="Data" formControlName="data"></br-input>
                <div *ngIf="form.get('data').invalid && form.get('data').touched">
                  <div *ngIf="form.get('data').errors.required">
                    <app-message
                      [control]="form.get('data')"
                      error="required"
                      message="O campo é obrigatório"
                    ></app-message>
                  </div>
                </div>
              </div>
              <div class="col">
                <br-input type="time" label="Hora" formControlName="hora"></br-input>
                <div *ngIf="form.get('hora').invalid && form.get('hora').touched">
                  <div *ngIf="form.get('hora').errors.required">
                    <app-message
                      [control]="form.get('hora')"
                      error="required"
                      message="O campo é obrigatório"
                    ></app-message>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <br-input label="Observação" formControlName="observacao"></br-input>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="actionsBar">
      <br-button secondary (click)="closeModal(true)" label="Salvar" style="margin-right: 10px"></br-button>
      <br-button secondary (click)="closeModal(false)" label="Fechar"></br-button>
    </div>
  </div>
</div>
