<fieldset [formGroup]="form">
  <legend>ITENS DE NECESSIDADE DO ABRIGO</legend>

  <div>
    <div class="row" style="margin-left: 0">
      <h4 style="margin-top: 0">Itens de necessidade do abrigo</h4>
      <br-button
        circle
        class="small"
        [icon]="itensUtilOpened ? 'chevron-up' : 'chevron-down'"
        (click)="toggleSelectItemUteis()"
        tooltip="Expandir lista"
      ></br-button>
    </div>
    <div *ngIf="itensUtilOpened" style="display: flex; flex-direction: column; align-items: end">
      <div
        style="
          display: grid;
          grid-template-columns: repeat(auto-fill, 300px);
          grid-template-rows: repeat(auto-fill);
          width: 100%;
          flex: 1 1 0;
        "
      >
        <br-checkbox
          *ngFor="let item of itensUteis"
          [label]="item.label"
          [checked]="item.selected"
          (click)="selectItemUteis(item)"
        ></br-checkbox>
      </div>
      <br-button
        secondary
        label="outro"
        icon="plus"
        (click)="addOtherItem()"
        tooltip="Adicionar um item não contido na lista"
      ></br-button>
    </div>

    <div style="padding: 20px 0">
      <table>
        <thead>
          <tr>
            <th>Item</th>
            <th>Quantidade</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of form.get('itensUteis').value; let i = index; trackBy: trackByItemUtil">
            <td *ngIf="item.type === 'custom'" style="width: 100%">
              <br-input
                type="text"
                [id]="'item' + i.toString()"
                name="'item' + i.toString()"
                placeholder="Digite o item"
                [value]="item.item"
                (blur)="handleCustomInputItemUtil(i, $event)"
              ></br-input>
            </td>
            <td *ngIf="item.type !== 'custom'" style="width: 100%">{{ item.item }}</td>
            <td style="width: 150px; display: flex; flex-direction: row">
              <br-input
                type="text"
                [id]="'qtd' + i.toString()"
                [name]="'qtd' + i.toString()"
                placeholder="Quantidade"
                [value]="item.quantidade"
                (input)="handleInputItemUtil(i, $event)"
              ></br-input>
              <br-button
                *ngIf="item.type === 'custom'"
                class="small"
                circle
                icon="minus"
                (click)="deleteItem(i)"
              ></br-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</fieldset>
