<br-message [state]="messageType" closable *ngIf="this.submitted">{{ message }}</br-message>

<div appModal center-content="" *ngIf="modalShow" style="flex: 1 1 0">
  <div
    *ngIf="modalShow"
    title="{{ titleModal }}"
    show
    closable
    style="
      display: flex;
      max-width: none;
      width: 80vw;
      min-height: 80%;
      max-height: 80%;
      background-color: white;
      padding: 10px;
      margin: auto;
    "
  >
    <div class="wrapper" style="display: flex; flex-direction: column; overflow: hidden; flex: 1 1 0">
      <div class="d-flex justify-content-between">
        <div>
          <h4>{{ titleModal }} {{ nomeAbrigo }}</h4>
        </div>
      </div>

      <div
        style="display: flex; flex: 1 1 0; flex-direction: column; padding: 10px; overflow-x: hidden; overflow-y: auto"
      >
        <div [style]="typeEdit == 2 || typeEdit == 0 ? '' : 'display: none'">
          <div style="display: flex; flex-direction: column; align-items: end">
            <!-- TODO: Viabilizar um filtro de pesquisa para filtrar os itens do sistema. -->
            <section *ngIf="itensCategorias" class="itemsCategory">
              <section class="itemsCategory__item">
                <h3>{{ itensCategorias.roupas }}</h3>

                <div class="itemsCategory__list">
                  <br-checkbox
                    *ngFor="let item of itensUteis | itemCategoryFilter: itensCategorias.roupas"
                    [label]="item.label"
                    [checked]="item.selected"
                    (click)="selectItemUteis(item)"
                  ></br-checkbox>
                </div>
              </section>

              <section class="itemsCategory__item">
                <h3>{{ itensCategorias.alimentacao }}</h3>

                <div class="itemsCategory__list">
                  <br-checkbox
                    *ngFor="let item of itensUteis | itemCategoryFilter: itensCategorias.alimentacao"
                    [label]="item.label"
                    [checked]="item.selected"
                    (click)="selectItemUteis(item)"
                  ></br-checkbox>
                </div>
              </section>

              <section class="itemsCategory__item">
                <h3>{{ itensCategorias.produtosLimpeza }}</h3>

                <div class="itemsCategory__list">
                  <br-checkbox
                    *ngFor="let item of itensUteis | itemCategoryFilter: itensCategorias.produtosLimpeza"
                    [label]="item.label"
                    [checked]="item.selected"
                    (click)="selectItemUteis(item)"
                  ></br-checkbox>
                </div>
              </section>

              <section class="itemsCategory__item">
                <h3>{{ itensCategorias.produtosHigienePessoal }}</h3>

                <div class="itemsCategory__list">
                  <br-checkbox
                    *ngFor="let item of itensUteis | itemCategoryFilter: itensCategorias.produtosHigienePessoal"
                    [label]="item.label"
                    [checked]="item.selected"
                    (click)="selectItemUteis(item)"
                  ></br-checkbox>
                </div>
              </section>

              <section class="itemsCategory__item">
                <h3>{{ itensCategorias.itensCriancas }}</h3>

                <div class="itemsCategory__list">
                  <br-checkbox
                    *ngFor="let item of itensUteis | itemCategoryFilter: itensCategorias.itensCriancas"
                    [label]="item.label"
                    [checked]="item.selected"
                    (click)="selectItemUteis(item)"
                  ></br-checkbox>
                </div>
              </section>

              <section class="itemsCategory__item">
                <h3>{{ itensCategorias.medicamentos }}</h3>

                <div class="itemsCategory__list">
                  <br-checkbox
                    *ngFor="let item of itensUteis | itemCategoryFilter: itensCategorias.medicamentos"
                    [label]="item.label"
                    [checked]="item.selected"
                    (click)="selectItemUteis(item)"
                  ></br-checkbox>
                </div>
              </section>

              <section class="itemsCategory__item">
                <h3>{{ itensCategorias.voluntariado }}</h3>

                <div class="itemsCategory__list">
                  <br-checkbox
                    *ngFor="let item of itensUteis | itemCategoryFilter: itensCategorias.voluntariado"
                    [label]="item.label"
                    [checked]="item.selected"
                    (click)="selectItemUteis(item)"
                  ></br-checkbox>
                </div>
              </section>

              <section class="itemsCategory__item">
                <h3>{{ itensCategorias.itensPets }}</h3>

                <div class="itemsCategory__list">
                  <br-checkbox
                    *ngFor="let item of itensUteis | itemCategoryFilter: itensCategorias.itensPets"
                    [label]="item.label"
                    [checked]="item.selected"
                    (click)="selectItemUteis(item)"
                  ></br-checkbox>
                </div>
              </section>

              <section class="itemsCategory__item">
                <h3>{{ itensCategorias.outros }}</h3>

                <div class="itemsCategory__list">
                  <br-checkbox
                    *ngFor="let item of itensUteis | itemCategoryFilter: itensCategorias.outros"
                    [label]="item.label"
                    [checked]="item.selected"
                    (click)="selectItemUteis(item)"
                  ></br-checkbox>
                </div>
              </section>
            </section>
          </div>
        </div>
      </div>

      <div class="mt-3 d-flex justify-content-md-end stick-on-top">
        <br-button id="fechar" label="Fechar Formulário" type="secondary" class="mt-3 mr-1" (click)="closeModal()">
        </br-button>
      </div>
    </div>
  </div>
</div>

<div class="row row-action">
  <div class="col">
    <h1>Gerenciar necessidades</h1>
  </div>
</div>

<div class="row">
  <div class="col">
    <br-select
      label="Escolha um abrigo"
      placeholder="Escolha um abrigo"
      [options]="abrigosOptionsSearch"
      (change)="handleAbrigos($event)"
    >
    </br-select>
  </div>
</div>

<div class="row mt-3">
  <div class="col" *ngIf="listaFiltrada.value.length">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="mb-3 d-flex justify-content-md-end stick-on-top">
        <br-button
          label="Adicionar necessidades"
          type="primary"
          class="mr-1"
          tooltip-text="Usar necessidades do sistema"
          tooltip-place="top"
          tooltip-type="info"
          (click)="openEditModalNeed()"
        ></br-button>

        <br-button
          id="enviar"
          label="Salvar Formulário"
          type="primary"
          (click)="onSubmit()"
          tooltip-text="Salvar informações"
          tooltip-place="top"
          tooltip-type="info"
        >
        </br-button>
      </div>

      <div>
        <div [style]="typeEdit == 2 || typeEdit == 0 ? '' : 'display: none'">
          <table>
            <thead>
              <tr>
                <th>Item</th>
                <th>Quantidade</th>
                <th>Medida</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of form.get('itensUteis').value; let i = index; trackBy: trackByItemUtil">
                <td *ngIf="item.type === 'custom'" style="width: 100%">
                  <br-input
                    type="text"
                    [id]="'item' + i.toString()"
                    name="'item' + i.toString()"
                    placeholder="Digite o item"
                    [value]="item.item"
                    (input)="handleInputItenUtil(i, $event, true)"
                  ></br-input>
                </td>

                <td *ngIf="item.type !== 'custom'" style="width: 100%">{{ item.label }}</td>

                <td style="width: 150px; display: flex; flex-direction: row">
                  <br-input
                    type="text"
                    [id]="'qtd' + i.toString()"
                    [name]="'qtd' + i.toString()"
                    placeholder="Quantidade"
                    [value]="item.quantidade"
                    (input)="handleInputItenUtil(i, $event, false)"
                  ></br-input>
                  <br-button class="small" circle icon="minus" (click)="deleteItem(i)"></br-button>
                </td>

                <td style="width: 100%">{{ item | itemMeasure: itensUteis }}</td>
              </tr>
            </tbody>
          </table>

          <!-- <div class="mt-3 d-flex justify-content-md-end stick-on-top">
            <br-button
              secondary
              label="Adicionar necessidade não cadastrada no sistema"
              icon="plus"
              class="mr-1"
              (click)="addOtherItem()"
              tooltip="Adicionar um item não contido na lista"
            ></br-button>
          </div> -->
        </div>
      </div>

      <div>
        <h6 class="title-necessidades-extras">Necessidades Extras</h6>
        <textarea
          class="textarea-necessidades-extras"
          formControlName="necessidadesExtras"
          rows="5"
          placeholder="Digite aqui as necessidades não encontradas na lista"
        ></textarea>
        <h6 class="aviso-necessidades-extras">
          *As necessidades extras possuem uma exibição diferente das demais necessidades no mapa, de preferência utilize
          as necessidades categorizadas.
        </h6>
      </div>

      <!-- TODO: analisar a possiblidade desse item ser fixo na tela e acompanhar a lista, isso pode/deve ser importante com abrigos que precisem de muitos itens. -->
      <div class="mt-3 d-flex justify-content-md-end stick-on-top">
        <br-button
          label="Adicionar necessidades"
          type="primary"
          class="mr-1"
          tooltip-text="Usar necessidades do sistema"
          tooltip-place="top"
          tooltip-type="info"
          (click)="openEditModalNeed()"
        ></br-button>

        <br-button
          id="enviar"
          label="Salvar Formulário"
          type="primary"
          (click)="onSubmit()"
          tooltip-text="Salvar informações"
          tooltip-place="top"
          tooltip-type="info"
        >
        </br-button>
      </div>
    </form>
  </div>
</div>

<!-- Esse é o bloco de debug -->
<!-- <div class="row">
  <div class="col">
    {{ form.get("itensUteis").value | json }}

      <div>
        <h3>itensUteis | precisa verificar o que vai ficar</h3>
        <br-checkbox
          *ngFor="let item of itensUteis | itemCategoryFilter: ''"
          [label]="item.label"
          [checked]="item.selected"
          (click)="selectItemUteis(item)"
        ></br-checkbox>
      </div>
  </div>
</div> -->

<!-- Categorias -->
<!-- <div class="row">
  <div class="col">
    <div *ngIf="itensCategorias">
      <div>
        <h3>Roupas</h3>
        <br-checkbox
          *ngFor="let item of itensUteis | itemCategoryFilter: itensCategorias.roupas"
          [label]="item.label"
          [checked]="item.selected"
          (click)="selectItemUteis(item)"
        ></br-checkbox>
      </div>

      <div>
        <h3>Alimentacao</h3>
        <br-checkbox
          *ngFor="let item of itensUteis | itemCategoryFilter: itensCategorias.alimentacao"
          [label]="item.label"
          [checked]="item.selected"
          (click)="selectItemUteis(item)"
        ></br-checkbox>
      </div>

      <div>
        <h3>ProdutosLimpeza</h3>
        <br-checkbox
          *ngFor="let item of itensUteis | itemCategoryFilter: itensCategorias.produtosLimpeza"
          [label]="item.label"
          [checked]="item.selected"
          (click)="selectItemUteis(item)"
        ></br-checkbox>
      </div>

      <div>
        <h3>ProdutosHigienePessoal</h3>
        <br-checkbox
          *ngFor="let item of itensUteis | itemCategoryFilter: itensCategorias.produtosHigienePessoal"
          [label]="item.label"
          [checked]="item.selected"
          (click)="selectItemUteis(item)"
        ></br-checkbox>
      </div>

      <div>
        <h3>ItensPets</h3>
        <br-checkbox
          *ngFor="let item of itensUteis | itemCategoryFilter: itensCategorias.itensPets"
          [label]="item.label"
          [checked]="item.selected"
          (click)="selectItemUteis(item)"
        ></br-checkbox>
      </div>

      <div>
        <h3>ItensCriancas</h3>
        <br-checkbox
          *ngFor="let item of itensUteis | itemCategoryFilter: itensCategorias.itensCriancas"
          [label]="item.label"
          [checked]="item.selected"
          (click)="selectItemUteis(item)"
        ></br-checkbox>
      </div>

      <div>
        <h3>Medicamentos</h3>
        <br-checkbox
          *ngFor="let item of itensUteis | itemCategoryFilter: itensCategorias.medicamentos"
          [label]="item.label"
          [checked]="item.selected"
          (click)="selectItemUteis(item)"
        ></br-checkbox>
      </div>

      <div>
        <h3>Voluntariado</h3>
        <br-checkbox
          *ngFor="let item of itensUteis | itemCategoryFilter: itensCategorias.voluntariado"
          [label]="item.label"
          [checked]="item.selected"
          (click)="selectItemUteis(item)"
        ></br-checkbox>
      </div>

      <div>
        <h3>Outros</h3>
        <br-checkbox
          *ngFor="let item of itensUteis | itemCategoryFilter: itensCategorias.outros"
          [label]="item.label"
          [checked]="item.selected"
          (click)="selectItemUteis(item)"
        ></br-checkbox>
      </div>

      <br-button
        secondary
        label="outro"
        icon="plus"
        (click)="addOtherItem()"
        tooltip="Adicionar um item não contido na lista"
      ></br-button>
    </div>
  </div>
</div> -->
