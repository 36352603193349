export const OrderByOptionsDTO = [
  {
    value: 'name',
    label: 'Nome',
    selected: true,
  },
  {
    value: 'city',
    label: 'Cidade',
    selected: false,
  },
  {
    value: 'manager',
    label: 'Gestor da Operação',
    selected: false,
  },
  {
    value: 'available_spots',
    label: 'Vagas Disponíveis',
    selected: false,
  },
  {
    value: 'woman_spots',
    label: 'Vagas Disponíveis para Mulheres',
    selected: false,
  },
  {
    value: 'pet_spots',
    label: 'Vagas Disponíveis para Pets',
    selected: false,
  },
]
