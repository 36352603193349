<br-menu
  [id]="id"
  [attr.show-menu]="sharedService.isOpen === true ? true : null"
  is-push
  [list]="menuItems"
  data-breakpoints="col-sm-4 col-lg-3"
  (navigate)="navigate($event)"
></br-menu>

<div class="qr-code-container">
  <img src="assets/imgs/logo-preto.png" alt="logo" class="qr-code-logo" />
  <img src="assets/imgs/qr-code.png" alt="QR Code" class="qr-code-image" />
  <p class="qr-code-text">Precisa de ajuda? Leia o QR Code e fale com a equipe de suporte.</p>
</div>
