// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appCheck: '6Lf7ENYpAAAAAO0Y9RoaewcgIPTXjyeya-zvUebH',
  firebase: {
    apiKey: 'AIzaSyDaNuZYF4_gUY8FvQ5ppmyXKHuwoEbrYlk',
    authDomain: 'abrigospoa-dev.firebaseapp.com',
    projectId: 'abrigospoa-dev',
    storageBucket: 'abrigospoa-dev.appspot.com',
    messagingSenderId: '474341787974',
    appId: '1:474341787974:web:c4ef65ef861633a462bd47',
  },
  serProEndpoint: 'https://icpf.abrigars.com.br/',
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
