export const GeneroDTO = [
  {
    value: 'Homem',
    label: 'Homem',
    selected: false,
  },
  {
    value: 'Mulher',
    label: 'Mulher',
    selected: false,
  },
  {
    value: 'Prefiro não dizer',
    label: 'Prefiro não dizer',
    selected: false,
  },
]
