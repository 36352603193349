import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, inject } from '@angular/core'
import { FormControl, NonNullableFormBuilder, Validators } from '@angular/forms'
import { ItemUteis } from 'src/app/core/services/repository/abrigo.service'
import AbrigoDTO, { EquipeApoio } from '../DTOs/AbrigoDTO'
import { AbrigosComponent } from '../abrigos.component'
import { CreateOrUpdateAbrigoUseCase } from '../useCases/createOrUpdateAbrigo.useCase'

@Component({
  selector: 'app-form-cadastro-de-abrigo',
  templateUrl: './form-cadastro-de-abrigo.component.html',
  styleUrls: ['./form-cadastro-de-abrigo.component.scss'],
})
export class FormCadastroDeAbrigoComponent implements OnChanges {
  @Input()
  public title: string

  @Input()
  public typeEdit: number = AbrigosComponent.CADASTRO_GERAL

  @Input('edit')
  public forEdit: AbrigoDTO

  @Output()
  public close = new EventEmitter()

  @Output()
  public done = new EventEmitter()

  public nomeAbrigo: string

  public formError = {
    show: false,
    type: 'danger',
    message: '',
  }

  private fb = inject(NonNullableFormBuilder)

  private createOrUpdateAbrigoUseCase = inject(CreateOrUpdateAbrigoUseCase)

  protected formGroup = this.fb.group({
    // INFORMAÇÕES BÁSICAS
    ativo: [true, Validators.required],
    nome: ['', Validators.required],
    endereco: this.fb.group({
      cep: ['', Validators.required],
      rua: ['', Validators.required],
      bairro: [''],
      cidade: ['', Validators.required],
    }),
    ponto_referencia: [''],
    orientacao_acesso: [''],
    //EQUIPE DE APOIO
    nome_gestor: ['', Validators.required],
    telefone_gestor: ['', Validators.required],
    vagas_info: this.fb.group({
      vagas_totais: [0, Validators.required],
      vagas_ocupadas: [0, Validators.required],
      vagas_disponiveis: new FormControl({value: 0, disabled: true}, Validators.required),
      vaga_mulher: [false],
      vaga_mulher_quantidade: [0],
      vaga_mulher_quantidade_ocupadas: [0],
      vaga_mulheres_criancas: [false],
      vaga_mulheres_criancas_quantidade: [0],
      vaga_mulheres_criancas_quantidade_ocupadas: [0],
      vaga_gestantes: [false],
      vaga_gestantes_quantidade: [0],
      vaga_gestantes_quantidade_ocupadas: [0],
      vaga_moradores_rua: [false],
      vaga_moradores_rua_quantidade: [0],
      vaga_moradores_rua_quantidade_ocupadas: [0],
      vaga_adultos: [false],
      vaga_adultos_quantidade: [0],
      vaga_adultos_quantidade_ocupadas: [0],
      vaga_idosos: [false],
      vaga_idosos_quantidade: [0],
      vaga_idosos_quantidade_ocupadas: [0],
      vaga_autistas: [false],
      vaga_autistas_quantidade: [0],
      vaga_autistas_quantidade_ocupadas: [0],
      vaga_pcd: [false],
      vaga_pcd_quantidade: [0],
      vaga_pcd_quantidade_ocupadas: [0],
      vaga_pets: [false],
      vaga_pets_quantidade: [0],
      vaga_pets_quantidade_ocupadas: [0],
      vaga_pets_quais: [''],
    }),
    // CLASSIFICAÇÃO
    local_info: this.fb.group({
      rede_prefeitura: [false],
      tipo_local: new FormControl<string[]>([]),
      classificacao_local: [''],
      classificacao_local_outro: [''],
      tipo_abrigo: new FormControl<string[]>([]),
    }),
    nome_gestor_suprimentos: [''],
    telefone_gestor_suprimentos: [''],
    equipe_apoio: this.fb.array<EquipeApoio>([]),
    infra_info: this.fb.group({
      metragem: [''],
      banheiros: [0],
      banheiros_prefeitura: [''],
      necessidade_servico_limpeza_banheiros: [false],
      chuveiros: [0],
      chuveiros_prefeitura: [''],
      colchoes: [0],
      acessibilidade: [false],
      obs_acessibilidade: [''],
      climatizacao: [false],
      obs_climatizacao: [''],
      conectividade: [false],
      obs_conectividade: [''],
      cozinha: [false],
      fornecimento_agua: [false],
      caminhao_pipa: [false],
      fornecimento_eletrica: [false],
      gerador: [false],
      enfermaria: [false],
    }),
    atendimento_info: this.fb.group({
      qtd_voluntarios: [0],
      servico_limpeza_permanente: [false],
      necessidade_kit_limpeza: [false],
      lavanderia: [false],
      seguranca: [false],
      seguranca_prefeitura: [''],
      atendimento: [false],
      apoio_medico: new FormControl([]),
      medicamentos: [false],
      apoio_medicamentos: [''],
    }),
    informacao_adicional: this.fb.group({
      origem_abrigados: [''],
      chegada_espontanea: [''],
      chegada_por_triagem: [''],
      fornecedor_assistencia_social: new FormControl<string[]>([]),
      fornecedor_psicologia: new FormControl<string[]>([]),
      abrigados_necessidades_especiais: new FormControl<string[]>([]),
      abrigados_interesse_casa_familiar: this.fb.array<{ nome: string }>([]),
      ocorrencia_violacao_direitos_humanos: new FormControl<string[]>([]),
      visita_orgao_oficial: new FormControl<string[]>([]),
    }),
    itensUteis: this.fb.array<ItemUteis>([]),
    observations: [''],
    update_in: [''],
  })

  get form() {
    return this.formGroup
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.forEdit.currentValue) {
      this.handleEdit(changes.forEdit.currentValue)
    }
  }

  private async handleEdit(abrigo: AbrigoDTO) {
    this.nomeAbrigo = abrigo.nome
    // tratamento para atender dados legados
    if (typeof abrigo.local_info.tipo_local === 'string') {
      abrigo.local_info.tipo_local = (abrigo.local_info.tipo_local as string).split(',')
    }

    if (!abrigo.vagas_info.vagas_disponiveis) {
      abrigo.vagas_info.vagas_disponiveis = +abrigo.vagas_info.vagas_totais - +abrigo.vagas_info.vagas_ocupadas
    }

    if (!abrigo.itensUteis || !(abrigo.itensUteis?.length == 0)) {
      abrigo.itensUteis = []
    }

    this.form.patchValue({
      ...abrigo,
      infra_info: {
        ...abrigo.infra_info,
        acessibilidade: abrigo.infra_info['acessibilidade'] === 'true',
        climatizacao: abrigo.infra_info['climatizacao'] === 'true',
      },
    })
  }

  hasError(field: string | string[], error: string) {
    if (this.form.get(field).invalid && this.form.get(field).touched) {
      return this.form.get(field).errors[error] ?? false
    }

    return false
  }

  handleInput(fieldName: string[]) {
    const fieldControl = this.form.get(fieldName)

    if (fieldControl?.value?.toString()?.trim() === '') {
      fieldControl?.setValue('')
      fieldControl?.markAsUntouched()
      fieldControl?.markAsPristine()
      fieldControl?.updateValueAndValidity()
    }
  }

  changeSwitch(elements: string[][], event: Event) {
    const target = event.target as HTMLInputElement

    elements.forEach((element) => {
      const value = this.form.get(element).getRawValue()
      if ([null, '0', ''].includes(value)) {
        this.form.get(element).setValue(target.checked ? '0' : null)
      }
    })
  }

  changeSelect(name: string[], event: Event): void {
    const { detail } = event as MouseEvent | CustomEvent

    const value = Array.isArray(detail[0])
      ? detail[0]?.reduce((acc, cur) => [...acc, cur.value], [] as string[])
      : [detail[0]?.value]

    this.form.get(name)?.setValue(value)
  }

  clearForm() {
    this.form.reset()
    this.form.get('nome').reset()

    const uteisArray = this.form.controls.itensUteis
    uteisArray.clear()
  }

  async onSubmit(onlyCloseForm: boolean) {
    try {
      if (onlyCloseForm) {
        this.close.emit()
        this.clearForm()
        return
      }

      if (this.form.valid) {
        const formValues = this.form.getRawValue()
        
        await this.createOrUpdateAbrigoUseCase.run({
          abrigoId: this.forEdit?.id,
          formValues: {
            ...formValues,
            vagas_info: {
              ...formValues.vagas_info,
              vagas_totais: formValues.vagas_info.vagas_totais,
              // vagas_disponiveis: formValues.vagas_info.vagas_totais - formValues.vagas_info.vagas_ocupadas
            },
            infra_info: {
              ...formValues.infra_info,
              acessibilidade: `${formValues.infra_info.acessibilidade}`,
              climatizacao: `${formValues.infra_info.climatizacao}`,
              obs_agua: '',
              obs_eletrica: '',
            },
          },
          update_in: new Date(),
        })

        this.clearForm()

        this.done.emit()
      } else {
        this.form.markAllAsTouched()

        this.formError.show = true
        this.formError.message = 'Formulário inválido!'
      }
    } catch (err) {
      console.error('error:', err)
    }
  }
}
