import { Component, Input, SimpleChanges, inject } from '@angular/core'
import { FormArray, FormGroup, NonNullableFormBuilder } from '@angular/forms'
import AbrigoDTO from '../../../DTOs/AbrigoDTO'
import { AssistenciaSocialOptionsDTO } from '../../../DTOs/AssistenciaSocialOptionsDTO'
import { NecessidadesEspeciaisOptionsDTO } from '../../../DTOs/NecessidadesEspeciaisOptionsDTO'
import { OrgaoOficialOptionsDTO } from '../../../DTOs/OrgaoOficialOptionsDTO'
import { ServicoPsicologiaOptionsDTO } from '../../../DTOs/ServicoPsicologiaOptionsDTO'
import { ViolacoesDireitosHumanosOptionsDTO } from '../../../DTOs/ViolacoesDireitosHumanosOptionsDTO'
import { FormCadastroDeAbrigoComponent } from '../../form-cadastro-de-abrigo.component'

@Component({
  selector: 'app-additional-info-fieldset',
  templateUrl: './additional-info-fieldset.component.html',
  styleUrls: ['./additional-info-fieldset.component.scss'],
})
export class AdditionalInfoFieldsetComponent {
  @Input('edit')
  public forEdit: AbrigoDTO

  private container = inject(FormCadastroDeAbrigoComponent)
  private fb = inject(NonNullableFormBuilder)

  public assistenciaSocialOptions = AssistenciaSocialOptionsDTO
  public servicoPsicologiaOptions = ServicoPsicologiaOptionsDTO
  public necessidadesEspeciaisOptions = NecessidadesEspeciaisOptionsDTO
  public violacoesDireitosHumanosOptions = ViolacoesDireitosHumanosOptionsDTO
  public orgaoOficialOptions = OrgaoOficialOptionsDTO

  get formContainer() {
    return this.container
  }

  get form() {
    return this.container.form
  }

  handleSelectOption(
    field:
      | 'fornecedor_assistencia_social'
      | 'fornecedor_psicologia'
      | 'abrigados_necessidades_especiais'
      | 'ocorrencia_violacao_direitos_humanos'
      | 'visita_orgao_oficial',
    option: { value: string; label: string; selected: boolean }
  ) {
    const control = this.form.controls.informacao_adicional.controls[field]

    if (!control.value.includes(option.value)) {
      return control.value.push(option.value)
    }

    this.form.get('informacao_adicional').patchValue({
      ...this.form.get('informacao_adicional').value,
      [field]: control.value.filter((value) => value != option.value),
    })
    console.log(control)
    return control
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.handleEdit(changes.forEdit.currentValue)
  }

  handleInputAbrigado(i: number, fieldName: string, event: Event) {
    const { detail } = event as MouseEvent | CustomEvent
    const itemValue = detail[0]

    this.updateAbrigado(i, fieldName, itemValue)
  }

  private handleEdit(abrigo: AbrigoDTO) {
    let abrigadosInteresseCasaFamiliar = this.form.get([
      'informacao_adicional',
      'abrigados_interesse_casa_familiar',
    ]) as FormArray

    if (!abrigadosInteresseCasaFamiliar) {
      abrigadosInteresseCasaFamiliar = this.fb.array<{ nome: string }>([])
      ;(this.form.get('informacao_adicional') as FormGroup).setControl(
        'abrigados_interesse_casa_familiar',
        abrigadosInteresseCasaFamiliar
      )
    } else {
      abrigadosInteresseCasaFamiliar.clear()
    }

    if (abrigo.informacao_adicional.abrigados_interesse_casa_familiar) {
      abrigo.informacao_adicional.abrigados_interesse_casa_familiar.forEach((item: any) => {
        if (item) {
          abrigadosInteresseCasaFamiliar.push(
            this.fb.group({
              nome: item?.nome,
            })
          )
        }
      })
    }

    this.assistenciaSocialOptions = this.assistenciaSocialOptions.map((option) => {
      option.selected = abrigo?.informacao_adicional.fornecedor_assistencia_social?.includes(option.value)
      return option
    })

    this.servicoPsicologiaOptions = this.servicoPsicologiaOptions.map((option) => {
      option.selected = abrigo?.informacao_adicional.fornecedor_psicologia?.includes(option.value)
      return option
    })

    this.necessidadesEspeciaisOptions = this.necessidadesEspeciaisOptions.map((option) => {
      option.selected = abrigo?.informacao_adicional.abrigados_necessidades_especiais?.includes(option.value)
      return option
    })

    this.violacoesDireitosHumanosOptions = this.violacoesDireitosHumanosOptions.map((option) => {
      option.selected = abrigo?.informacao_adicional.ocorrencia_violacao_direitos_humanos?.includes(option.value)
      return option
    })

    this.orgaoOficialOptions = this.orgaoOficialOptions.map((option) => {
      option.selected = abrigo?.informacao_adicional.visita_orgao_oficial?.includes(option.value)
      return option
    })
  }

  abrigadoTrack(index: number) {
    return index
  }

  updateAbrigado(index: number, fieldName: string, itemValue: string) {
    const uteis = this.form.controls.informacao_adicional.controls.abrigados_interesse_casa_familiar

    const item = uteis.at(index)
    const itemToChange = {}

    itemToChange[fieldName] = itemValue

    const newItem = {
      ...item.value,
      ...itemToChange,
    }

    item.setValue(newItem)
  }

  addicionarAbrigadoInteresseCasaFamiliar() {
    const abrigados = this.form.get(['informacao_adicional', 'abrigados_interesse_casa_familiar']) as FormArray

    abrigados.push(
      this.fb.group({
        nome: null,
      })
    )
  }

  removerAbrigadoInteresseCasaFamiliar(index: number) {
    const abrigados = this.form.get(['informacao_adicional', 'abrigados_interesse_casa_familiar']) as FormArray
    abrigados.removeAt(index)
  }
}
