<fieldset [formGroup]="form">
  <legend>INFORMAÇÕES BÁSICAS</legend>

  <div class="mb-3">
    <div class="row">
      <div class="col">
        <br-input
          id="nome"
          name="nome"
          type="text"
          label="Nome do local:"
          placeholder="Nome"
          formControlName="nome"
          (input)="formContainer.handleInput(['nome'])"
        ></br-input>

        <app-message
          *ngIf="formContainer.hasError('nome', 'required')"
          [control]="form.controls.nome"
          error="required"
          message="O campo é obrigatório"
        ></app-message>
      </div>

      <div formGroupName="endereco" class="col">
        <br-input
          id="cep"
          name="cep"
          type="text"
          label="CEP:"
          placeholder="CEP"
          formControlName="cep"
          autocomplete="off"
          mask="##.###-###"
          (blur)="searchCep(form.get(['endereco', 'cep']).value)"
        ></br-input>

        <app-message
          *ngIf="formContainer.hasError(['endereco', 'cep'], 'required')"
          [control]="form.get(['endereco', 'cep'])"
          error="required"
          message="O campo é obrigatório"
        ></app-message>

        <br-message feedback state="danger" show-icon="true" *ngIf="erroCEP">CEP inválido ou inexistente</br-message>
      </div>
    </div>

    <div class="row">
      <div formGroupName="endereco" class="col">
        <br-input
          id="city"
          name="city"
          label="Cidade"
          placeholder="Cidade"
          formControlName="cidade"
          (input)="formContainer.handleInput(['endereco', 'cidade'])"
        >
        </br-input>

        <app-message
          *ngIf="formContainer.hasError(['endereco', 'cidade'], 'required')"
          [control]="form.get(['endereco', 'cidade'])"
          error="required"
          message="O campo é obrigatório"
        ></app-message>
      </div>

      <div formGroupName="endereco" class="col">
        <br-input
          type="text"
          id="address"
          name="address"
          placeholder="Endereço"
          label="Endereço:"
          formControlName="rua"
          (input)="formContainer.handleInput(['endereco', 'rua'])"
        ></br-input>

        <app-message
          *ngIf="formContainer.hasError(['endereco', 'rua'], 'required')"
          [control]="form.get(['endereco', 'rua'])"
          error="required"
          message="O campo é obrigatório"
        ></app-message>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <br-input
          type="text"
          id="ponto_referencia"
          name="ponto_referencia"
          placeholder="Ponto de referência do local"
          label="Ponto de referência do local:"
          formControlName="ponto_referencia"
          (input)="formContainer.handleInput(['ponto_referencia'])"
        ></br-input>
      </div>

      <div class="col">
        <br-input
          type="text"
          id="orientacao_acesso"
          name="orientacao_acesso"
          placeholder="Acesso por..."
          label="Há orientação para acesso?"
          formControlName="orientacao_acesso"
          (input)="formContainer.handleInput(['orientacao_acesso'])"
        ></br-input>
      </div>
    </div>
  </div>
</fieldset>
