<fieldset [formGroup]="form">
  <legend>SEGURANÇA, SAÚDE E BEM-ESTAR</legend>

  <div formGroupName="infra_info">
    <div class="row">
      <div class="col-6">
        <br-input
          type="number"
          min="0"
          id="colchoes"
          name="colchoes"
          placeholder="Quantidade de colchões e kits de roupa de cama e toalhas de banho existente no abrigo"
          label="Quantidade de colchões e kits de roupa de cama e toalhas de banho existente no abrigo"
          formControlName="colchoes"
          (input)="formContainer.handleInput(['infra_info', 'colchoes'])"
        >
        </br-input>
      </div>
    </div>

    <div class="divider"></div>
  </div>

  <!-- infra de atendimento -->
  <div formGroupName="atendimento_info">
    <div class="row">
      <div class="col d-flex justify-content-start align-items-center switch-col">
        <div class="br-switch switch-full" role="presentation">
          <input
            checked="checked"
            type="checkbox"
            role="switch"
            id="servico_limpeza_permanente"
            name="servico_limpeza_permanente"
            formControlName="servico_limpeza_permanente"
          />
          <label for="servico_limpeza_permanente">Há serviço de limpeza permanente do local?</label>
        </div>
      </div>

      <div class="col d-flex justify-content-start align-items-center switch-col">
        <div class="br-switch" role="presentation">
          <input
            checked="checked"
            type="checkbox"
            role="switch"
            id="necessidade_kit_limpeza"
            name="necessidade_kit_limpeza"
            formControlName="necessidade_kit_limpeza"
          />
          <label for="necessidade_kit_limpeza">Há necessidade de recebimento de kit de limpeza para o abrigo?</label>
        </div>
      </div>

      <div class="col d-flex justify-content-start align-items-center switch-col">
        <div class="br-switch switch-full" role="presentation">
          <input
            checked="checked"
            type="checkbox"
            role="switch"
            id="lavanderia"
            name="lavanderia"
            formControlName="lavanderia"
          />
          <label for="lavanderia">Há serviço de lavanderia?</label>
        </div>
      </div>
    </div>

    <div class="divider"></div>

    <div class="row">
      <div class="col-4 d-flex justify-content-start align-items-center switch-col">
        <div class="br-switch switch-full" role="presentation">
          <input
            checked="checked"
            type="checkbox"
            role="switch"
            id="seguranca"
            name="seguranca"
            formControlName="seguranca"
          />
          <label for="seguranca">Há serviço de segurança no local?</label>
        </div>
      </div>

      <div class="col-4 d-flex justify-content-start align-items-center switch-col">
        <div class="br-switch switch-full" role="presentation">
          <input
            checked="checked"
            type="checkbox"
            role="switch"
            id="seguranca_prefeitura"
            name="seguranca_prefeitura"
            formControlName="seguranca_prefeitura"
          />
          <label for="seguranca_prefeitura"
            >Serviço de segurança é próprio ou fornecido pela prefeitura de Porto Alegre?</label
          >
        </div>
      </div>
    </div>

    <div class="divider"></div>
  </div>

  <div formGroupName="infra_info">
    <div class="row">
      <div class="col-4 d-flex justify-content-start align-items-center switch-col">
        <div class="br-switch switch-full" role="presentation">
          <input
            checked="checked"
            type="checkbox"
            role="switch"
            id="enfermaria"
            name="enfermaria"
            formControlName="enfermaria"
          />
          <label for="enfermaria">Há enfermaria?</label>
        </div>
      </div>
    </div>
  </div>

  <div formGroupName="atendimento_info">
    <div class="row">
      <div class="col-4 d-flex justify-content-start align-items-center switch-col">
        <div class="br-switch switch-full" role="presentation">
          <input
            checked="checked"
            type="checkbox"
            role="switch"
            id="atendimento"
            name="atendimento"
            formControlName="atendimento"
          />
          <label for="atendimento">Há atendimento de saúde?</label>
        </div>
      </div>

      <div class="col">
        <br-select
          label="Quais atendimentos de saúde"
          placeholder="Quais atendimentos de saúde?"
          [options]="atendimentoOptions"
          multiple
          (change)="formContainer.changeSelect(['atendimento_info', 'apoio_medico'], $event)"
        >
        </br-select>
      </div>
    </div>

    <div class="row">
      <div class="col-4 d-flex justify-content-start align-items-center switch-col">
        <div class="br-switch switch-full" role="presentation">
          <input
            checked="checked"
            type="checkbox"
            role="switch"
            id="medicamentos"
            name="medicamentos"
            formControlName="medicamentos"
          />
          <label for="medicamentos">Há fornecimento de medicamentos?</label>
        </div>
      </div>

      <div class="col">
        <br-input
          [type]="'text'"
          id="apoio_medicamentos"
          name="apoio_medicamentos"
          placeholder="Quais medicamentos"
          label="Quais medicamentos?"
          formControlName="apoio_medicamentos"
          (input)="formContainer.handleInput(['atendimento_info', 'apoio_medicamentos'])"
        >
        </br-input>
      </div>
    </div>
  </div>
</fieldset>
