import { Component, Input, OnChanges, SimpleChanges, inject } from '@angular/core'
import { FormArray, NonNullableFormBuilder } from '@angular/forms'
import { ItemUteis } from 'src/app/core/services/repository/abrigo.service'
import AbrigoDTO from '../../../DTOs/AbrigoDTO'
import { FormCadastroDeAbrigoComponent } from '../../form-cadastro-de-abrigo.component'

type ItemUtilOption = { value: string; label: string; selected: boolean }

const itensUteis: ItemUtilOption[] = [
  { value: 'agua', label: 'Água', selected: false },
  { value: 'comida pronta', label: 'Comida pronta', selected: false },
  { value: 'leite', label: 'Leite', selected: false },
  {
    value: 'Comida não perecivel(Arroz, Feijão, Massas, Conservas)',
    label: 'Comida não perecivel(Arroz, Feijão, Massas, Conservas)',
    selected: false,
  },
  { value: 'Formulas infantis', label: 'Formulas infantis', selected: false },
  { value: 'Papinha', label: 'Papinha', selected: false },
  { value: 'andador para adulto', label: 'Andador para adulto', selected: false },
  { value: 'cadeira de rodas', label: 'Cadeira de rodas', selected: false },
  { value: 'cadeira higiência e de banho', label: 'Cadeira higiência e de banho', selected: false },
  { value: 'cobertores', label: 'Cobertores', selected: false },
  { value: 'lençois', label: 'Lençois', selected: false },
  { value: 'muletas', label: 'Muletas', selected: false },
  { value: 'toalhas', label: 'Toalhas', selected: false },
  { value: 'travesseiro', label: 'Travesseiro', selected: false },
  { value: 'carregador de celular', label: 'Carregador de celular', selected: false },
  { value: 'absorvente', label: 'Absorvente', selected: false },
  { value: 'fralda geriatrica', label: 'Fralda geriatrica', selected: false },
  { value: 'fralda infantil', label: 'Fralda infantil', selected: false },
  {
    value: 'kit de higiene pessoal - papel higiênico, pasta de dente, shampoo, escova dental, desodorante',
    label: 'Kit de higiene pessoal - papel higienico, pasta de dente, shampoo, escova dental, desodorante',
    selected: false,
  },
  { value: 'kit de primeiros socorros', label: 'Kit de primeiros socorros', selected: false },
  { value: 'lenço humedecido', label: 'Lenço humedecido', selected: false },
  { value: 'Desodorante', label: 'Desodorante', selected: false },
  { value: 'Shampoo e condicionador', label: 'Shampoo e condicionador', selected: false },
  { value: 'Escova de dente e pasta de dente', label: 'Escova e pasta de dente', selected: false },
  { value: 'Papel Higienico', label: 'Papel Higiênico', selected: false },
  { value: 'Sabonete', label: 'Sabonete', selected: false },
  { value: 'baldes, varroura e rodo', label: 'Baldes, vassoura e rodo', selected: false },
  { value: 'panos para limpeza', label: 'Panos para limpeza', selected: false },
  { value: 'potes para alimentação', label: 'Potes para alimentação', selected: false },
  { value: 'produtos de limpeza do abrigo', label: 'Produtos de limpeza do abrigo', selected: false },
  { value: 'sacos plasticos para lixo', label: 'Sacos plasticos para lixo', selected: false },
  { value: 'coleira', label: 'coleira', selected: false },
  { value: 'ração para cachorro', label: 'Ração para cachorro', selected: false },
  { value: 'ração para gato', label: 'Ração para gato', selected: false },
  { value: 'Areia Higienica / Saco para fezes', label: 'Areia Higiênica / Saco para fezes', selected: false },
  { value: 'Medicamentos veterinarios', label: 'Medicamentos veterinários', selected: false },
  { value: 'creme para assaduras', label: 'Creme para assaduras', selected: false },
  { value: 'remedios', label: 'Remédios', selected: false },
  { value: 'Medicamentos infantis', label: 'Medicamentos infantis', selected: false },
  { value: 'roupas feminino', label: 'Roupas femininas', selected: false },
  { value: 'roupas masculino', label: 'Roupas masculinas', selected: false },
  { value: 'Roupas infantis', label: 'Roupas infantis', selected: false },
  { value: 'Sapatos femininos', label: 'Sapatos femininos', selected: false },
  { value: 'Sapatos masculino', label: 'Sapatos masculino', selected: false },
  { value: 'Sapatos Infantis', label: 'Sapatos infantis', selected: false },
]

@Component({
  selector: 'app-util-items-fieldset',
  templateUrl: './util-items-fieldset.component.html',
  styleUrls: ['./util-items-fieldset.component.scss'],
})
export class UtilItemsFieldsetComponent implements OnChanges {
  @Input('edit')
  public forEdit: AbrigoDTO

  private container = inject(FormCadastroDeAbrigoComponent)
  private fb = inject(NonNullableFormBuilder)

  get formContainer() {
    return this.container
  }

  get form() {
    return this.container.form
  }

  public itensUtilOpened: boolean = true

  public itensUteis: Array<ItemUtilOption> = JSON.parse(JSON.stringify(itensUteis))

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.forEdit.currentValue) {
      this.handleEdit(changes.forEdit.currentValue)
    }
  }

  private handleEdit(abrigo: AbrigoDTO) {
    this.itensUteis = JSON.parse(JSON.stringify(itensUteis))

    this.itensUtilOpened = false

    let uteis = this.form.get('itensUteis') as FormArray

    if (!uteis) {
      uteis = this.fb.array<ItemUteis>([])

      this.form.setControl('itensUteis', uteis)
    } else {
      uteis.clear()
    }

    if (abrigo.itensUteis) {
      this.itensUteis = this.itensUteis.map((itemUtil) => {
        itemUtil.selected = abrigo.itensUteis.some((item) => item.item === itemUtil.value)

        return itemUtil
      })

      abrigo.itensUteis.forEach((item: any) => {
        uteis.push(
          this.fb.group({
            item: item.item,
            quantidade: item.quantidade,
            type: item.type,
          })
        )
      })
    }
  }

  trackByItemUtil(_: number, value: ItemUteis) {
    return value.item
  }

  toggleSelectItemUteis() {
    this.itensUtilOpened = !this.itensUtilOpened

    const uteis = this.form.controls.itensUteis

    this.itensUteis = this.itensUteis.map((itemUtil: ItemUtilOption) => {
      itemUtil.selected = uteis.value.some(({ item }) => item === itemUtil.value)

      return itemUtil
    })
  }

  selectItemUteis(item: ItemUtilOption) {
    const uteis = this.form.controls.itensUteis

    let foundItemIndex = -1

    for (let i = 0; i < uteis.value.length; i++) {
      const itemForm = uteis.at(i)

      if (itemForm.value.item === item.value) foundItemIndex = i
    }

    if (foundItemIndex >= 0) this.deleteItem(foundItemIndex)
    else this.addItem(item.value)
  }

  addItem(itemValue: string) {
    const uteis = this.form.get('itensUteis') as FormArray

    uteis.insert(
      0,
      this.fb.group({
        item: itemValue,
        quantidade: 0,
        type: null,
      })
    )
  }

  updateItem(index: number, itemValue: string, isCustom = false) {
    const uteis = this.form.controls.itensUteis
    const item = uteis.at(index)

    let itemToChange = {}

    if (isCustom) itemToChange['item'] = itemValue
    else itemToChange['quantidade'] = +itemValue

    const itemToUpdate = {
      ...item.value,
      ...itemToChange,
    }

    item.patchValue(itemToUpdate)
  }

  addOtherItem() {
    const uteis = this.form.get('itensUteis') as FormArray

    uteis.push(
      this.fb.group({
        item: null,
        quantidade: 0,
        type: 'custom',
      })
    )
  }

  handleInputItemUtil(index: number, event: Event) {
    const { detail } = event as MouseEvent | CustomEvent

    let itemValue = detail[0]
    if (typeof itemValue === 'string') {
      itemValue = itemValue.replace(',', '.')
    }

    this.updateItem(index, `${parseFloat(itemValue || 0)}`)
  }

  handleCustomInputItemUtil(i, ev) {
    const itemValue = ev.target.value instanceof Array ? ev.target.value[0] : ev.target.value

    this.updateItem(i, itemValue, true)
  }

  deleteItem(i: number) {
    const uteis = this.form.controls.itensUteis

    uteis.removeAt(i)
  }
}
