export const OrgaoOficialOptionsDTO = [
  {
    value: 'Representante da Prefeitura',
    label: 'Representante da Prefeitura',
    selected: false,
  },
  {
    value: 'Secretaria Municipal da Saúde',
    label: 'Secretaria Municipal da Saúde',
    selected: false,
  },
  {
    value: 'Fundação de Assistência Social e Cidadania',
    label: 'Fundação de Assistência Social e Cidadania',
    selected: false,
  },
  {
    value: 'Secretaria Municipal de Desenvolvimento Social',
    label: 'Secretaria Municipal de Desenvolvimento Social',
    selected: false,
  },
  {
    value: 'Departamento Municipal de Habitação',
    label: 'Departamento Municipal de Habitação',
    selected: false,
  },
  {
    value: 'Representante do Governo do Estado',
    label: 'Representante do Governo do Estado',
    selected: false,
  },
  {
    value: 'Representante do Governo Federal',
    label: 'Representante do Governo Federal',
    selected: false,
  },
  {
    value: 'Representante do Ministério Público',
    label: 'Representante do Ministério Público',
    selected: false,
  },
  {
    value: 'Representante do Judiciário',
    label: 'Representante do Judiciário',
    selected: false,
  },
  {
    value: 'Representante do Defensoria Pública',
    label: 'Representante do Defensoria Pública',
    selected: false,
  },
  {
    value: 'Representante do Conselho Tutelar',
    label: 'Representante do Conselho Tutelar',
    selected: false,
  },
]
