import { Component, Input, OnChanges, SimpleChanges, inject } from '@angular/core'
import { FormArray, NonNullableFormBuilder } from '@angular/forms'
import AbrigoDTO, { EquipeApoio } from '../../../DTOs/AbrigoDTO'
import { FormCadastroDeAbrigoComponent } from '../../form-cadastro-de-abrigo.component'

@Component({
  selector: 'app-support-team-fieldset',
  templateUrl: './support-team-fieldset.component.html',
  styleUrls: ['./support-team-fieldset.component.scss'],
})
export class SupportTeamFieldsetComponent implements OnChanges {
  @Input('edit')
  public forEdit: AbrigoDTO

  private container = inject(FormCadastroDeAbrigoComponent)
  private fb = inject(NonNullableFormBuilder)

  get formContainer() {
    return this.container
  }

  get form() {
    return this.container.form
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.forEdit.currentValue) {
      this.handleEdit(changes.forEdit.currentValue)
    }
  }

  private handleEdit(abrigo: AbrigoDTO) {
    let equipeApoio = this.form.get('equipe_apoio') as FormArray

    if (!equipeApoio) {
      equipeApoio = this.fb.array<EquipeApoio>([])

      this.form.setControl('equipe_apoio', equipeApoio)
    } else {
      equipeApoio.clear()
    }

    if (abrigo.equipe_apoio) {
      abrigo.equipe_apoio.forEach((item: any) => {
        if (item) {
          equipeApoio.push(
            this.fb.group({
              apoio_responsavel: item?.apoio_responsavel,
              apoio_telefone: item?.apoio_telefone,
            })
          )
        }
      })
    }
  }

  handleInputVolunteer(i: number, fieldName: string, event: Event) {
    const { detail } = event as MouseEvent | CustomEvent
    const itemValue = detail[0]

    this.updateVolunteer(i, fieldName, itemValue)
  }

  updateVolunteer(index: number, fieldName: string, itemValue: string) {
    const uteis = this.form.controls.equipe_apoio

    const item = uteis.at(index)
    const itemToChange = {}

    itemToChange[fieldName] = itemValue

    const newItem = {
      ...item.value,
      ...itemToChange,
    }

    item.setValue(newItem)
  }

  volunteerTrack(index: number) {
    return index
  }

  addVolunteer() {
    const volunteers = this.form.get('equipe_apoio') as FormArray

    volunteers.push(
      this.fb.group({
        apoio_responsavel: null,
        apoio_telefone: null,
      })
    )
  }

  removeVolunteer(index: number) {
    const volunteers = this.form.get('equipe_apoio') as FormArray

    volunteers.removeAt(index)
  }
}
