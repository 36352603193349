import { Injectable } from '@angular/core'
import { Abrigo, AbrigoService } from 'src/app/core/services/repository/abrigo.service'
import AbrigoDTO from '../DTOs/AbrigoDTO'

@Injectable({
  providedIn: 'root',
})
export class GetAbrigoUseCase {
  constructor(private readonly abrigoService: AbrigoService) {}

  async run(): Promise<AbrigoDTO[]> {
    const abrigos = await this.abrigoService.getAbrigos()
    const abrigosFormValues: AbrigoDTO[] = abrigos?.map<AbrigoDTO>((abrigo) => ({
      id: abrigo.id,
      nome: abrigo.nome,
      endereco: {
        cep: abrigo.cep,
        rua: abrigo.address,
        cidade: abrigo.city,
      },
      ponto_referencia: abrigo.ponto_referencia ?? '',
      orientacao_acesso: abrigo.orientacao_acesso ?? '',
      nome_gestor: abrigo.nome_contato,
      telefone_gestor: abrigo.telefone,
      nome_gestor_suprimentos: abrigo.nome_gestor_suprimentos,
      telefone_gestor_suprimentos: abrigo.telefone_gestor_suprimentos,
      equipe_apoio: abrigo.equipe_apoio ?? [],
      vagas_info: {
        vaga_mulher: !!abrigo.vagas_mulher,
        vaga_mulher_quantidade: abrigo.vagas_mulher,
        vaga_mulher_quantidade_ocupadas: abrigo.vagas_mulher_ocupadas,
        vaga_pets: !!abrigo.vagas_pet,
        vaga_pets_quantidade: abrigo.vagas_pet,
        vaga_pets_quantidade_ocupadas: abrigo.vagas_pet_ocupadas,
        vagas_totais: abrigo.vagas,
        vagas_ocupadas: abrigo.vagas_ocupadas,
        vagas_disponiveis: abrigo.vagas_disponiveis,
        vaga_pets_quais: abrigo.vagas_pet_quais,
        vaga_mulheres_criancas: !!abrigo.vagas_mulheres_criancas,
        vaga_mulheres_criancas_quantidade: abrigo.vagas_mulheres_criancas,
        vaga_mulheres_criancas_quantidade_ocupadas: abrigo.vagas_mulheres_criancas_ocupadas,
        vaga_gestantes: !!abrigo.vagas_gestantes,
        vaga_gestantes_quantidade: abrigo.vagas_gestantes,
        vaga_gestantes_quantidade_ocupadas: abrigo.vagas_gestantes_ocupadas,
        vaga_moradores_rua: !!abrigo.vagas_moradores_rua,
        vaga_moradores_rua_quantidade: abrigo.vagas_moradores_rua,
        vaga_moradores_rua_quantidade_ocupadas: abrigo.vagas_moradores_rua_ocupadas,
        vaga_adultos: !!abrigo.vagas_adultos,
        vaga_adultos_quantidade: abrigo.vagas_adultos,
        vaga_adultos_quantidade_ocupadas: abrigo.vagas_adultos_ocupadas,
        vaga_idosos: !!abrigo.vagas_idosos,
        vaga_idosos_quantidade: abrigo.vagas_idosos,
        vaga_idosos_quantidade_ocupadas: abrigo.vagas_idosos_ocupadas,
        vaga_autistas: !!abrigo.vagas_autistas,
        vaga_autistas_quantidade: abrigo.vagas_autistas,
        vaga_autistas_quantidade_ocupadas: abrigo.vagas_autistas_ocupadas,
        vaga_pcd: !!abrigo.vagas_pcd,
        vaga_pcd_quantidade: abrigo.vagas_pcd,
        vaga_pcd_quantidade_ocupadas: abrigo.vagas_pcd_ocupadas,
      },
      atendimento_info: abrigo.atendimento_info,
      infra_info: {
        ...abrigo.infra_info,
        banheiros: abrigo.banheiros,
      },
      local_info: {
        rede_prefeitura: abrigo.rede_prefeitura ?? typeof abrigo.abrigopm === 'boolean' ?? false,
        tipo_local: abrigo.tipo,
        classificacao_local: abrigo.classificacao_local,
        classificacao_local_outro: abrigo.classificacao_local_outro,
        tipo_abrigo: typeof abrigo.abrigopm === 'boolean' ? ['Abrigo'] : abrigo.abrigopm,
      },
      itensUteis: abrigo.itensUteis,
      observations: abrigo.observations,
      update_in: abrigo.update_in,
      ativo: abrigo.ativo !== undefined ? abrigo.ativo : true,
      informacao_adicional: {
        origem_abrigados: abrigo.informacao_adicional?.origem_abrigados ?? '',
        chegada_espontanea: abrigo.informacao_adicional?.chegada_espontanea ?? '',
        chegada_por_triagem: abrigo.informacao_adicional?.chegada_por_triagem ?? '',
        fornecedor_assistencia_social: abrigo.informacao_adicional?.fornecedor_assistencia_social ?? [],
        fornecedor_psicologia: abrigo.informacao_adicional?.fornecedor_psicologia ?? [],
        abrigados_necessidades_especiais: abrigo.informacao_adicional?.abrigados_necessidades_especiais ?? [],
        abrigados_interesse_casa_familiar: abrigo.informacao_adicional?.abrigados_interesse_casa_familiar ?? [],
        ocorrencia_violacao_direitos_humanos: abrigo.informacao_adicional?.ocorrencia_violacao_direitos_humanos ?? [],
        visita_orgao_oficial: abrigo.informacao_adicional?.visita_orgao_oficial ?? [],
      },
    }))

    return abrigosFormValues
  }

  async run2(): Promise<Abrigo[]> {
    return this.abrigoService.getAbrigos()
  }
}
