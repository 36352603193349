<fieldset [formGroup]="form">
  <legend>EQUIPE DE APOIO</legend>

  <div class="row">
    <div class="col-8">
      <br-input
        type="text"
        id="nome_gestor"
        name="nome_gestor"
        placeholder="Nome do gestor do abrigo"
        label="Nome do gestor/responsável pela operação do abrigo ou ponto de acolhimento"
        formControlName="nome_gestor"
        (input)="formContainer.handleInput(['nome_gestor'])"
      >
      </br-input>

      <div *ngIf="form.get('nome_gestor').invalid && form.get('nome_gestor').touched">
        <div *ngIf="form.get('nome_gestor').errors.required">
          <app-message
            [control]="form.get('nome_gestor')"
            error="required"
            message="O campo é obrigatório"
          ></app-message>
        </div>
      </div>
    </div>

    <div class="col-3">
      <br-input
        type="text"
        id="telefone_gestor"
        name="telefone_gestor"
        placeholder="Telefone"
        label="Telefone"
        formControlName="telefone_gestor"
        (input)="formContainer.handleInput(['telefone_gestor'])"
        mask="(##) #####-####"
        placeholder="(##) #####-####"
      ></br-input>
      <div *ngIf="form.get('telefone_gestor').invalid && form.get('telefone_gestor').touched">
        <div *ngIf="form.get('telefone_gestor').errors.required">
          <app-message
            [control]="form.get('telefone_gestor')"
            error="required"
            message="O campo é obrigatório"
          ></app-message>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-8">
      <br-input
        type="text"
        id="nome_gestor_suprimentos"
        name="nome_gestor_suprimentos"
        placeholder="Nome do gestor de suprimentos"
        label="Nome do responsável pela gestão de suprimentos do abrigo ou ponto de acolhimento"
        formControlName="nome_gestor_suprimentos"
        (input)="formContainer.handleInput(['nome_gestor_suprimentos'])"
      >
      </br-input>
    </div>

    <div class="col-3">
      <br-input
        type="text"
        id="telefone_gestor_suprimentos"
        name="telefone_gestor_suprimentos"
        placeholder="Telefone"
        label="Telefone"
        formControlName="telefone_gestor_suprimentos"
        (input)="formContainer.handleInput(['telefone_gestor_suprimentos'])"
        mask="(##) #####-####"
        placeholder="(##) #####-####"
      ></br-input>
    </div>
  </div>

  <div class="mb-3" formArrayName="equipe_apoio">
    <div
      class="row align-items-end"
      *ngFor="let volunteer of form.get('equipe_apoio').value; let vi = index; trackBy: volunteerTrack"
    >
      <div class="col-8">
        <br-input
          type="text"
          [id]="'apoio_responsavel' + vi.toString()"
          placeholder="Nome do integrante da equipe de apoio"
          label="Nome do integrante da equipe de apoio:"
          [value]="volunteer.apoio_responsavel"
          (input)="handleInputVolunteer(vi, 'apoio_responsavel', $event)"
        ></br-input>
      </div>

      <div class="col-3">
        <br-input
          type="text"
          [id]="'apoio_telefone' + vi.toString()"
          label="Telefone:"
          [value]="volunteer.apoio_telefone"
          mask="(##) #####-####"
          placeholder="(##) #####-####"
          (input)="handleInputVolunteer(vi, 'apoio_telefone', $event)"
        ></br-input>
      </div>

      <div class="col-1 pb-1">
        <br-button
          circle
          id="deleteVolunteer"
          primary
          icon="minus"
          type="primary"
          class="small mr-4 mb-1"
          (click)="removeVolunteer(vi)"
        >
        </br-button>
      </div>
    </div>

    <div class="row align-items-center">
      <div class="col-11 text-right">
        <span class="pl-baseh">Adicionar integrante</span>
      </div>

      <div class="col-1">
        <br-button
          circle
          id="addVolunteer"
          primary
          icon="plus"
          type="primary"
          class="small mr-4 mb-1"
          (click)="addVolunteer()"
        >
        </br-button>
      </div>
    </div>
  </div>
</fieldset>
