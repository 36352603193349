export const StatusOptionsDTO = [
  {
    value: null,
    label: 'Todos',
    selected: true,
  },
  {
    value: true,
    label: 'Ativos',
    selected: false,
  },
  {
    value: false,
    label: 'Inativos',
    selected: false,
  },
]
