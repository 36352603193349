import { Component, inject } from '@angular/core'
import { AtendimentoOptiosnDTO } from '../../../DTOs/AtendimentoOptionsDTO'
import { FormCadastroDeAbrigoComponent } from '../../form-cadastro-de-abrigo.component'

@Component({
  selector: 'app-infrastructure-fieldset',
  templateUrl: './infrastructure-fieldset.component.html',
  styleUrls: ['./infrastructure-fieldset.component.scss'],
})
export class InfrastructureFieldsetComponent {
  private container = inject(FormCadastroDeAbrigoComponent)

  public atendimentoOptions = AtendimentoOptiosnDTO

  get formContainer() {
    return this.container
  }

  get form() {
    return this.container.form
  }
}
