export const ViolacoesDireitosHumanosOptionsDTO = [
  {
    value: 'Mulher',
    label: 'Mulher',
    selected: false,
  },
  {
    value: 'Migrantes',
    label: 'Migrantes',
    selected: false,
  },
  {
    value: 'Racial',
    label: 'Racial',
    selected: false,
  },
  {
    value: 'Indígena',
    label: 'Indígena',
    selected: false,
  },
  {
    value: 'Diversidade Sexual e de gênero',
    label: 'Diversidade Sexual e de gênero',
    selected: false,
  },
  {
    value: 'Etária',
    label: 'Etária',
    selected: false,
  },
  {
    value: 'Criança e Adolescente',
    label: 'Criança e Adolescente',
    selected: false,
  },
]
