import { Component, inject } from '@angular/core'
import { FormCadastroDeAbrigoComponent } from '../../form-cadastro-de-abrigo.component'

@Component({
  selector: 'app-capacity-fieldset',
  templateUrl: './capacity-fieldset.component.html',
  styleUrls: ['./capacity-fieldset.component.scss'],
})
export class CapacityFieldsetComponent {
  private container = inject(FormCadastroDeAbrigoComponent)

  get formContainer() {
    return this.container
  }

  get form() {
    return this.container.form
  }

  ngOnInit() {
    const { vagas_totais, vagas_ocupadas, vagas_disponiveis } = this.form.controls.vagas_info.controls

    vagas_totais.valueChanges.subscribe(valor => {
      const valorComoNumero = Number(valor)
      vagas_disponiveis.setValue(
        valorComoNumero - vagas_ocupadas.value
      )
    })

    vagas_ocupadas.valueChanges.subscribe(valor => {
      const valorComoNumero = Number(valor)

      if (valorComoNumero < 0 || valorComoNumero > vagas_totais.value) {
        
        vagas_ocupadas.setValue(vagas_totais.value)
        vagas_disponiveis.setValue(0)
      }
      else {
        vagas_disponiveis.setValue(
          vagas_totais.value - valorComoNumero
        )
      }
    })
  }
}
