<form [formGroup]="formGroup">
  <header class="d-flex justify-content-between">
    <div>
      <h4>{{ title ?? "Cadastrar Abrigo" }} {{ nomeAbrigo }}</h4>
    </div>
    <div class="d-flex align-items-center pr-5">
      <div class="br-switch" role="presentation">
        <input checked="checked" type="checkbox" role="switch" id="ativo" formControlName="ativo" />
        <label for="ativo">Ativo</label>
      </div>
    </div>
  </header>

  <br-message [state]="formError.type" closable *ngIf="formError.show">{{ formError.message }}</br-message>

  <div class="wrapper">
    <div [style]="typeEdit == 1 || typeEdit == 0 ? '' : 'display: none'">
      <app-basic-info-fieldset />

      <app-classification-fieldset [edit]="forEdit" />

      <app-support-team-fieldset [edit]="forEdit" />

      <app-capacity-fieldset />

      <app-infrastructure-fieldset />

      <app-attendance-fieldset [edit]="forEdit" />

      <app-additional-info-fieldset [edit]="forEdit" />

      <fieldset>
        <div class="row">
          <div class="col">
            <br-input
              [type]="'text'"
              id="observations"
              name="observations"
              placeholder="Observações"
              label="Observações:"
              formControlName="observations"
              (input)="handleInput(['observations'])"
            >
            </br-input>
          </div>
        </div>
      </fieldset>
    </div>
  </div>

  <div class="mt-3 d-flex justify-content-md-end stick-on-top">
    <br-button
      id="enviar"
      label="Enviar Formulário"
      type="primary"
      class="mt-3 mr-1"
      (click)="onSubmit(false)"
      tooltip-text="Salvar informações"
      tooltip-place="top"
      tooltip-type="info"
    >
    </br-button>
    <br-button
      id="fechar"
      label="Fechar Formulário"
      type="secondary"
      class="mt-3 mr-1"
      (click)="onSubmit(true)"
      tooltip-text="Fechar o formulário"
      tooltip-place="top"
      tooltip-type="info"
    >
    </br-button>
  </div>
</form>
