export const OrderByOptionsDTO = [
  {
    value: 'name',
    label: 'Nome',
    selected: true,
  },
  {
    value: 'city',
    label: 'Cidade',
    selected: false,
  },
  {
    value: 'manager',
    label: 'Gestor da Operação',
    selected: false,
  },
  {
    value: 'available_spots',
    label: 'Vagas Disponíveis',
    selected: false,
  },
  {
    value: 'woman_spots',
    label: 'Vagas Disponíveis para Mulheres',
    selected: false,
  },
  {
    value: 'woman_kid_spots',
    label: 'Vagas Disponíveis para Mulheres e Crianças',
    selected: false,
  },
  {
    value: 'pregnant_spots',
    label: 'Vagas Disponíveis para Gestantes',
    selected: false,
  },
  {
    value: 'homeless_spots',
    label: 'Vagas Disponíveis para Moradores de Rua',
    selected: false,
  },
  {
    value: 'adult_spots',
    label: 'Vagas Disponíveis para Adultos',
    selected: false,
  },
  {
    value: 'elderly_spots',
    label: 'Vagas Disponíveis para Idosos',
    selected: false,
  },
  {
    value: 'autistic_spots',
    label: 'Vagas Disponíveis para Autistas',
    selected: false,
  },
  {
    value: 'pcd_spots',
    label: 'Vagas Disponíveis para PCD',
    selected: false,
  },
  {
    value: 'pet_spots',
    label: 'Vagas Disponíveis para Pets',
    selected: false,
  },
]
