import { Component, inject, OnInit } from '@angular/core'
import { Analytics } from '@angular/fire/analytics'
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { BehaviorSubject } from 'rxjs'
import { AuthorizationService } from 'src/app/core/services/authorization.service'
import { Abrigo, AbrigoService, ItemUteis } from 'src/app/core/services/repository/abrigo.service'
import { CreateOrUpdateAbrigoUseCase } from './useCases/createOrUpdateAbrigo.useCase'
import { GetAbrigoUseCase } from './useCases/getAbrigos.useCase'

import { HttpClient } from '@angular/common/http'

import { ExcelService } from 'src/app/core/services/excel.service'
import { AuthService } from 'src/app/core/services/repository/firebase/auth.service'
import { LoaderService } from 'src/app/shared/components/loader/loader.service'
import AbrigoDTO, { EquipeApoio } from './DTOs/AbrigoDTO'
import { AbrigoOptionsDTO } from './DTOs/AbrigoOptionsDTO'
import { AtendimentoOptiosnDTO } from './DTOs/AtendimentoOptionsDTO'
import { ClassificacaoOptionsDTO } from './DTOs/ClassificacaoOptionsDTO'
import { LocalOptionsDTO } from './DTOs/LocalOptionsDTO'

// TODO: verificar de faz sentido as medidas serem no plural, ou então ser algo como par(es), kilo(s), pois não temos um tratamento pra isso
type Measure =
  | ''
  | 'peças'
  | 'pares'
  | 'unidades'
  | 'pacotes'
  | 'litros'
  | 'kilos'
  | 'latas'
  | 'metros'
  | 'cartelas'
  | 'voluntários'

type CategoryType =
  | ''
  | 'Roupas'
  | 'Alimentação'
  | 'Produtos de Limpeza'
  | 'Produtos de Higiene Pessoal'
  | 'Itens para Pets'
  | 'Itens para Crianças'
  | 'Medicamentos'
  | 'Voluntários'
  | 'Outros'

type Version = '1' | '2'

export interface Items {
  value: string
  label: string
  category: CategoryType
  measure: Measure
  selected: boolean
  version: Version
}

const itensCategorias = {
  roupas: 'Roupas',
  alimentacao: 'Alimentação',
  produtosLimpeza: 'Produtos de Limpeza',
  produtosHigienePessoal: 'Produtos de Higiene Pessoal',
  itensPets: 'Itens para Pets',
  itensCriancas: 'Itens para Crianças',
  medicamentos: 'Medicamentos',
  voluntariado: 'Voluntários',
  outros: 'Outros',
}

/* TODO: fazer uma aba na planilha de itens considerando os arrays e os values, pois na minha visão tem muita coisa com padrão estranha.
  considerando que pode ser adicionado um VALOR/quantiadde para item, não me parece fazer sentido os nomes serem no plural, ou ainda no diminutivo.

  Exp:
  "Pilhas AA", pq não pilha AA?
  "Caminha para gato", pq não cama para gato?
  "Mantinha para cachorro", pq não manta para cachorro, ou ainda para pet? de forma geral? Ou ainda especificar tamanho, manta G para cão, ou algo do tipo. Existem animais de vários tamanhos.

  Existem casos que são especificos e vários outros que não são, exp:
  "Massa parafuso", pq não só massa?
  "Fralda intanfil G", mas não tem RC, não existe recem nascido?
  "Cueca infantil", aqui não existe o tamanho como G, M, P, mas na fralda sim, pq?
  "Fralda geriatrica", tbm não possui tamanho, ela é tamanho unico? não me parece ser o caso.
*/
const itemsMeasures = {
  pecas: 'Peças',
  pares: 'Pares',
  unidades: 'Unidades',
  pacotes: 'Pacotes',
  litros: 'Litros',
  kilos: 'Kilos',
  latas: 'Latas',
  metros: 'Metros',
  cartelas: 'Cartelas',
  voluntarios: 'Voluntários',
}

const itemsRoupas: Items[] = [
  {
    value: 'cueca adulto',
    label: 'Cueca adulto',
    category: itensCategorias.roupas as CategoryType,
    measure: itemsMeasures.pecas as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'calcinha adulto',
    label: 'Calcinha adulto',
    category: itensCategorias.roupas as CategoryType,
    measure: itemsMeasures.pecas as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'meia adulto',
    label: 'Meia adulto',
    category: itensCategorias.roupas as CategoryType,
    measure: itemsMeasures.pares as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'agasalhos adultos',
    label: 'Agasalhos adultos',
    category: itensCategorias.roupas as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'sapato adultos',
    label: 'Sapato adultos',
    category: itensCategorias.roupas as CategoryType,
    measure: itemsMeasures.pares as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'roupas masculinas',
    label: 'Roupas masculinas',
    category: itensCategorias.roupas as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'roupas femininas',
    label: 'Roupas femininas',
    category: itensCategorias.roupas as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'roupas plus size',
    label: 'Roupas plus size',
    category: itensCategorias.roupas as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'fralda adultos',
    label: 'Fralda adultos',
    category: itensCategorias.roupas as CategoryType,
    measure: itemsMeasures.pacotes as Measure,
    selected: false,
    version: '2',
  },
]

const itemsAlimentacao: Items[] = [
  {
    value: 'agua potavel',
    label: 'Água potável',
    category: itensCategorias.alimentacao as CategoryType,
    measure: itemsMeasures.litros as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'carne',
    label: 'Carne',
    category: itensCategorias.alimentacao as CategoryType,
    measure: itemsMeasures.kilos as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'proteina',
    label: 'Proteína',
    category: itensCategorias.alimentacao as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'arroz',
    label: 'Arroz',
    category: itensCategorias.alimentacao as CategoryType,
    measure: itemsMeasures.pacotes as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'massa parafuso',
    label: 'Massa parafuso',
    category: itensCategorias.alimentacao as CategoryType,
    measure: itemsMeasures.pacotes as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'molho de tomate',
    label: 'Molho de tomate',
    category: itensCategorias.alimentacao as CategoryType,
    measure: itemsMeasures.pacotes as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'calabresa',
    label: 'Calabresa',
    category: itensCategorias.alimentacao as CategoryType,
    measure: itemsMeasures.kilos as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'salsicha',
    label: 'Salsicha',
    category: itensCategorias.alimentacao as CategoryType,
    measure: itemsMeasures.kilos as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'frutas',
    label: 'Frutas',
    category: itensCategorias.alimentacao as CategoryType,
    measure: itemsMeasures.kilos as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'hortifruti',
    label: 'Hortifruti',
    category: itensCategorias.alimentacao as CategoryType,
    measure: itemsMeasures.kilos as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'legumes',
    label: 'Legumes',
    category: itensCategorias.alimentacao as CategoryType,
    measure: itemsMeasures.kilos as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'bolachas',
    label: 'Bolachas',
    category: itensCategorias.alimentacao as CategoryType,
    measure: itemsMeasures.pacotes as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'pao',
    label: 'Pão',
    category: itensCategorias.alimentacao as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'manteiga',
    label: 'Manteiga',
    category: itensCategorias.alimentacao as CategoryType,
    measure: itemsMeasures.kilos as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'cafe',
    label: 'Café',
    category: itensCategorias.alimentacao as CategoryType,
    measure: itemsMeasures.kilos as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'leite',
    label: 'Leite',
    category: itensCategorias.alimentacao as CategoryType,
    measure: itemsMeasures.litros as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'leite sem lactose',
    label: 'Leite sem lactose',
    category: itensCategorias.alimentacao as CategoryType,
    measure: itemsMeasures.latas as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'feijao',
    label: 'Feijão',
    category: itensCategorias.alimentacao as CategoryType,
    measure: itemsMeasures.pacotes as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'marmita pronta',
    label: 'Marmita pronta',
    category: itensCategorias.alimentacao as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
]

const itemsProdutosLimpeza: Items[] = [
  {
    value: 'detergente',
    label: 'Detergente',
    category: itensCategorias.produtosLimpeza as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'clorofila',
    label: 'Clorofila',
    category: itensCategorias.produtosLimpeza as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'luvas amarelas (limpeza)',
    label: 'Luvas amarelas (limpeza)',
    category: itensCategorias.produtosLimpeza as CategoryType,
    measure: itemsMeasures.pares as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'panos de limpeza',
    label: 'Panos de limpeza',
    category: itensCategorias.produtosLimpeza as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'sacos plasticos de lixo',
    label: 'Sacos plásticos de lixo',
    category: itensCategorias.produtosLimpeza as CategoryType,
    measure: itemsMeasures.pacotes as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'rodo',
    label: 'Rodo',
    category: itensCategorias.produtosLimpeza as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'vassoura',
    label: 'Vassoura',
    category: itensCategorias.produtosLimpeza as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'balde',
    label: 'Balde',
    category: itensCategorias.produtosLimpeza as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'pa de lixo',
    label: 'Pá de lixo',
    category: itensCategorias.produtosLimpeza as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'lixeira',
    label: 'Lixeira',
    category: itensCategorias.produtosLimpeza as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
]

const itemsProdutosHigienePessoal: Items[] = [
  {
    value: 'desodorante',
    label: 'Desodorante',
    category: itensCategorias.produtosHigienePessoal as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'creme dental',
    label: 'Creme Dental',
    category: itensCategorias.produtosHigienePessoal as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'escova de dentes',
    label: 'Escova de Dentes',
    category: itensCategorias.produtosHigienePessoal as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'sabonete',
    label: 'Sabonete',
    category: itensCategorias.produtosHigienePessoal as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'shampoo',
    label: 'Shampoo',
    category: itensCategorias.produtosHigienePessoal as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'absorvente',
    label: 'Absorvente',
    category: itensCategorias.produtosHigienePessoal as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'papel higienico',
    label: 'Papel higiênico',
    category: itensCategorias.produtosHigienePessoal as CategoryType,
    measure: itemsMeasures.pacotes as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'lenço humedecido',
    label: 'Lenço humidecido',
    category: itensCategorias.produtosHigienePessoal as CategoryType,
    measure: itemsMeasures.pacotes as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'fralda geriatrica',
    label: 'Fralda geriátrica',
    category: itensCategorias.produtosHigienePessoal as CategoryType,
    measure: itemsMeasures.pacotes as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'condicionador',
    label: 'Condicionador',
    category: itensCategorias.produtosHigienePessoal as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'algodao',
    label: 'Algodão',
    category: itensCategorias.produtosHigienePessoal as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
]

const itemsItensPets: Items[] = [
  {
    value: 'raçao para gato',
    label: 'Ração para gatos',
    category: itensCategorias.itensPets as CategoryType,
    measure: itemsMeasures.kilos as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'raçao para gatos filhotes',
    label: 'Ração para gatos filhotes',
    category: itensCategorias.itensPets as CategoryType,
    measure: itemsMeasures.kilos as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'raçao para cachorro',
    label: 'Ração para cachorros',
    category: itensCategorias.itensPets as CategoryType,
    measure: itemsMeasures.kilos as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'raçao para cachorros filhotes',
    label: 'Ração para cachorros filhotes',
    category: itensCategorias.itensPets as CategoryType,
    measure: itemsMeasures.kilos as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'raçao para cavalo',
    label: 'Ração para cavalo',
    category: itensCategorias.itensPets as CategoryType,
    measure: itemsMeasures.kilos as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'mantas para cachorro/gato',
    label: 'Mantas para cachorro/gato',
    category: itensCategorias.itensPets as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'caminhas para cachorro/gato',
    label: 'Caminhas para cachorro/gato',
    category: itensCategorias.itensPets as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'telas de arame galvanizado',
    label: 'Telas de arame galvanizado',
    category: itensCategorias.itensPets as CategoryType,
    measure: itemsMeasures.metros as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'caixas de transporte para animais',
    label: 'Caixas de transporte para animais',
    category: itensCategorias.itensPets as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'teste Fiv + Felv',
    label: 'Teste Fiv + Felv',
    category: itensCategorias.itensPets as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'antipulgas',
    label: 'Antipulgas',
    category: itensCategorias.itensPets as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'areia para gatos',
    label: 'Areia para gatos',
    category: itensCategorias.itensPets as CategoryType,
    measure: itemsMeasures.pacotes as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'caixa de areia para gatos',
    label: 'Caixa de areia para gatos',
    category: itensCategorias.itensPets as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'potes para comida/agua',
    label: 'Potes para comida/água',
    category: itensCategorias.itensPets as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'pallets',
    label: 'Pallets',
    category: itensCategorias.itensPets as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'predisolona',
    label: 'Predisolona',
    category: itensCategorias.itensPets as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'vermifugo',
    label: 'Vermífugo',
    category: itensCategorias.itensPets as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'simparc',
    label: 'Simparc',
    category: itensCategorias.itensPets as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'ibatrin',
    label: 'Ibatrin',
    category: itensCategorias.itensPets as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'probiotico',
    label: 'Probiótico',
    category: itensCategorias.itensPets as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'fita larga (crepe ou durex)',
    label: 'Fita larga (crepe ou durex)',
    category: itensCategorias.itensPets as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'roupinhas G/GG',
    label: 'Roupinhas G/GG',
    category: itensCategorias.itensPets as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'coleiras G/GG',
    label: 'Coleiras G/GG',
    category: itensCategorias.itensPets as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'abraçadeiras',
    label: 'Abraçadeiras',
    category: itensCategorias.itensPets as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'tela de galinheiro',
    label: 'Tela de galinheiro',
    category: itensCategorias.itensPets as CategoryType,
    measure: itemsMeasures.metros as Measure,
    selected: false,
    version: '2',
  },
]

const itemsItensCriancas: Items[] = [
  {
    value: 'fralda infantil P',
    label: 'Fralda infantil P',
    category: itensCategorias.itensCriancas as CategoryType,
    measure: itemsMeasures.pacotes as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'fralda infantil M',
    label: 'Fralda infantil M',
    category: itensCategorias.itensCriancas as CategoryType,
    measure: itemsMeasures.pacotes as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'fralda infantil G',
    label: 'Fralda infantil G',
    category: itensCategorias.itensCriancas as CategoryType,
    measure: itemsMeasures.pacotes as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'fralda infantil GG',
    label: 'Fralda infantil GG',
    category: itensCategorias.itensCriancas as CategoryType,
    measure: itemsMeasures.pacotes as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'nrinquedos para criancas',
    label: 'Brinquedos para crianças',
    category: itensCategorias.itensCriancas as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'cadeira/cadeirinha de bebe',
    label: 'Cadeira/cadeirinha de bebê',
    category: itensCategorias.itensCriancas as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'calcinha infantil',
    label: 'Calcinha infantil',
    category: itensCategorias.itensCriancas as CategoryType,
    measure: itemsMeasures.pecas as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'cueca infantil',
    label: 'Cueca infantil',
    category: itensCategorias.itensCriancas as CategoryType,
    measure: itemsMeasures.pecas as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'meia infantil',
    label: 'Meia infantil',
    category: itensCategorias.itensCriancas as CategoryType,
    measure: itemsMeasures.pares as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'agasalhos infantil',
    label: 'Agasalhos infantil',
    category: itensCategorias.itensCriancas as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'sapato infantil',
    label: 'Sapato infantil',
    category: itensCategorias.itensCriancas as CategoryType,
    measure: itemsMeasures.pares as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'roupas infantis',
    label: 'Roupas infantis',
    category: itensCategorias.itensCriancas as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
]

const itemsOutros: Items[] = [
  {
    value: 'colchao',
    label: 'Colchão',
    category: itensCategorias.outros as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'copo de isopor para cafe',
    label: 'Copo de Isopor para café',
    category: itensCategorias.outros as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'talheres descartaveis',
    label: 'Talheres descartáveis',
    category: itensCategorias.outros as CategoryType,
    measure: itemsMeasures.pacotes as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'copo',
    label: 'Copo',
    category: itensCategorias.outros as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'Guardanapo de papel',
    label: 'Guardanapo de papel',
    category: itensCategorias.outros as CategoryType,
    measure: itemsMeasures.pacotes as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'papelao',
    label: 'Papelão',
    category: itensCategorias.outros as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'jornal',
    label: 'Jornal',
    category: itensCategorias.outros as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'lona',
    label: 'Lona',
    category: itensCategorias.outros as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'lanterna',
    label: 'Lanterna',
    category: itensCategorias.outros as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'luz de emergencia',
    label: 'Luz de emergência',
    category: itensCategorias.outros as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'lona grossa',
    label: 'Lona grossa',
    category: itensCategorias.outros as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'travesseiro',
    label: 'Travesseiro',
    category: itensCategorias.outros as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'cadeira de rodas',
    label: 'Cadeira de rodas',
    category: itensCategorias.outros as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'carregador de celular',
    label: 'Carregador celular',
    category: itensCategorias.outros as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'muletas',
    label: 'Muletas',
    category: itensCategorias.outros as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'cadeira higiência e de banho',
    label: 'Cadeira higiênica e de banho',
    category: itensCategorias.outros as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'cobertores',
    label: 'Cobertores',
    category: itensCategorias.outros as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'lencois',
    label: 'Lençois',
    category: itensCategorias.outros as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'potes para alimentacao',
    label: 'Potes para alimentação',
    category: itensCategorias.outros as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'pratos',
    label: 'Pratos',
    category: itensCategorias.outros as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'toalha banho',
    label: 'Toalha banho',
    category: itensCategorias.outros as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'pilhas aa',
    label: 'Pilhas AA',
    category: itensCategorias.outros as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'pilhas aaa',
    label: 'Pilhas AAA',
    category: itensCategorias.outros as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'tesoura',
    label: 'Tesoura',
    category: itensCategorias.outros as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'corda',
    label: 'Corda',
    category: itensCategorias.outros as CategoryType,
    measure: itemsMeasures.metros as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'lixeiras grandes',
    label: 'Lixeiras grandes',
    category: itensCategorias.outros as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
]

const itemsMedicamentos: Items[] = [
  {
    value: 'kit primeiros socorros',
    label: 'Kit primeiros socorros',
    category: itensCategorias.medicamentos as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'pomada para assadura',
    label: 'Pomada para assadura',
    category: itensCategorias.medicamentos as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'dipirona',
    label: 'Dipirona',
    category: itensCategorias.medicamentos as CategoryType,
    measure: itemsMeasures.cartelas as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'esparadrapo',
    label: 'Esparadrapo',
    category: itensCategorias.medicamentos as CategoryType,
    measure: itemsMeasures.unidades as Measure,
    selected: false,
    version: '2',
  },
]

const itemsVoluntariado: Items[] = [
  {
    value: 'veterinario voluntario',
    label: 'Veterinário voluntário',
    category: itensCategorias.voluntariado as CategoryType,
    measure: itemsMeasures.voluntarios as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'voluntarios noite',
    label: 'Voluntários noite',
    category: itensCategorias.voluntariado as CategoryType,
    measure: itemsMeasures.voluntarios as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'voluntarios madrugada',
    label: 'Voluntários madrugada',
    category: itensCategorias.voluntariado as CategoryType,
    measure: itemsMeasures.voluntarios as Measure,
    selected: false,
    version: '2',
  },
  {
    value: 'mao de obra',
    label: 'Mão de obra',
    category: itensCategorias.voluntariado as CategoryType,
    measure: itemsMeasures.voluntarios as Measure,
    selected: false,
    version: '2',
  },
]

// Esses são os itens "antigos", optei por não apagar pois podem ser utilizados como referencia/consulta no futuro.
// Caso não vejam necessidade nisso, pode ser removido.
const itemsV1: Items[] = [
  {
    value: 'comida pronta',
    label: 'Comida pronta',
    selected: false,
    category: '',
    measure: '',
    version: '1',
  },
  {
    value: 'leite',
    label: 'Leite',
    selected: false,
    category: '',
    measure: '',
    version: '1',
  },
  {
    value: 'Comida não perecivel(Arroz, Feijão, Massas, Conservas)',
    label: 'Comida não perecivel(Arroz, Feijão, Massas, Conservas)',
    selected: false,
    category: '',
    measure: '',
    version: '1',
  },
  {
    value: 'Formulas infantis',
    label: 'Formulas infantis',
    selected: false,
    category: '',
    measure: '',
    version: '1',
  },
  {
    value: 'Papinha',
    label: 'Papinha',
    selected: false,
    category: '',
    measure: '',
    version: '1',
  },
  {
    value: 'andador para adulto',
    label: 'Andador para adulto',
    selected: false,
    category: '',
    measure: '',
    version: '1',
  },
  {
    value: 'cadeira de rodas',
    label: 'Cadeira de rodas',
    selected: false,
    category: '',
    measure: '',
    version: '1',
  },
  {
    value: 'cadeira higiência e de banho',
    label: 'Cadeira higiência e de banho',
    selected: false,
    category: '',
    measure: '',
    version: '1',
  },
  {
    value: 'cobertores',
    label: 'Cobertores',
    selected: false,
    category: '',
    measure: '',
    version: '1',
  },
  {
    value: 'lençois',
    label: 'Lençois',
    selected: false,
    category: '',
    measure: '',
    version: '1',
  },
  {
    value: 'muletas',
    label: 'Muletas',
    selected: false,
    category: '',
    measure: '',
    version: '1',
  },
  {
    value: 'travesseiro',
    label: 'Travesseiro',
    selected: false,
    category: '',
    measure: '',
    version: '1',
  },
  {
    value: 'carregador de celular',
    label: 'Carregador de celular',
    selected: false,
    category: '',
    measure: '',
    version: '1',
  },
  {
    value: 'fralda geriatrica',
    label: 'Fralda geriatrica',
    selected: false,
    category: '',
    measure: '',
    version: '1',
  },
  {
    value: 'fralda infantil',
    label: 'Fralda infantil',
    selected: false,
    category: '',
    measure: '',
    version: '1',
  },
  {
    value: 'kit de higiene pessoal - papel higiênico, pasta de dente, shampoo, escova dental, desodorante',
    label: 'Kit de higiene pessoal - papel higienico, pasta de dente, shampoo, escova dental, desodorante',
    selected: false,
    category: '',
    measure: '',
    version: '1',
  },
  {
    value: 'kit de primeiros socorros',
    label: 'Kit de primeiros socorros',
    selected: false,
    category: '',
    measure: '',
    version: '1',
  },
  {
    value: 'lenço humedecido',
    label: 'Lenço humedecido',
    selected: false,
    category: '',
    measure: '',
    version: '1',
  },
  {
    value: 'Desodorante',
    label: 'Desodorante',
    selected: false,
    category: '',
    measure: '',
    version: '1',
  },
  {
    value: 'Shampoo e condicionador',
    label: 'Shampoo e condicionador',
    selected: false,
    category: '',
    measure: '',
    version: '1',
  },
  {
    value: 'Escova de dente e pasta de dente',
    label: 'Escova e pasta de dente',
    selected: false,
    category: '',
    measure: '',
    version: '1',
  },
  {
    value: 'Papel Higienico',
    label: 'Papel Higiênico',
    selected: false,
    category: '',
    measure: '',
    version: '1',
  },
  {
    value: 'Sabonete',
    label: 'Sabonete',
    selected: false,
    category: '',
    measure: '',
    version: '1',
  },
  {
    value: 'baldes, varroura e rodo',
    label: 'Baldes, vassoura e rodo',
    selected: false,
    category: '',
    measure: '',
    version: '1',
  },
  {
    value: 'panos para limpeza',
    label: 'Panos para limpeza',
    selected: false,
    category: '',
    measure: '',
    version: '1',
  },
  {
    value: 'potes para alimentação',
    label: 'Potes para alimentação',
    selected: false,
    category: '',
    measure: '',
    version: '1',
  },
  {
    value: 'produtos de limpeza do abrigo',
    label: 'Produtos de limpeza do abrigo',
    selected: false,
    category: '',
    measure: '',
    version: '1',
  },
  {
    value: 'sacos plasticos para lixo',
    label: 'Sacos plasticos para lixo',
    selected: false,
    category: '',
    measure: '',
    version: '1',
  },
  {
    value: 'coleira',
    label: 'coleira',
    selected: false,
    category: '',
    measure: '',
    version: '1',
  },
  {
    value: 'raçao para cachorro',
    label: 'Ração para cachorro',
    selected: false,
    category: '',
    measure: '',
    version: '1',
  },
  {
    value: 'raçao para gato',
    label: 'Ração para gato',
    selected: false,
    category: '',
    measure: '',
    version: '1',
  },
  {
    value: 'Areia Higienica / Saco para fezes',
    label: 'Areia Higiênica / Saco para fezes',
    selected: false,
    category: '',
    measure: '',
    version: '1',
  },
  {
    value: 'Medicamentos veterinarios',
    label: 'Medicamentos veterinários',
    selected: false,
    category: '',
    measure: '',
    version: '1',
  },
  {
    value: 'creme para assaduras',
    label: 'Creme para assaduras',
    selected: false,
    category: '',
    measure: '',
    version: '1',
  },
  {
    value: 'remedios',
    label: 'Remédios',
    selected: false,
    category: '',
    measure: '',
    version: '1',
  },
  {
    value: 'Medicamentos infantis',
    label: 'Medicamentos infantis',
    selected: false,
    category: '',
    measure: '',
    version: '1',
  },
  {
    value: 'roupas feminino',
    label: 'Roupas femininas',
    selected: false,
    category: '',
    measure: '',
    version: '1',
  },
  {
    value: 'roupas masculino',
    label: 'Roupas masculinas',
    selected: false,
    category: '',
    measure: '',
    version: '1',
  },
  {
    value: 'roupas infantis',
    label: 'Roupas infantis',
    selected: false,
    category: '',
    measure: '',
    version: '1',
  },
  {
    value: 'sapatos femininos',
    label: 'Sapatos femininos',
    selected: false,
    category: '',
    measure: '',
    version: '1',
  },
  {
    value: 'sapatos masculino',
    label: 'Sapatos masculino',
    selected: false,
    category: '',
    measure: '',
    version: '1',
  },
  {
    value: 'sapatos Infantis',
    label: 'Sapatos infantis',
    selected: false,
    category: '',
    measure: '',
    version: '1',
  },
]

const itensUteis: Items[] = [
  // ...itemsV1,
  ...itemsRoupas,
  ...itemsAlimentacao,
  ...itemsProdutosLimpeza,
  ...itemsProdutosHigienePessoal,
  ...itemsItensPets,
  ...itemsItensCriancas,
  ...itemsOutros,
  ...itemsMedicamentos,
  ...itemsVoluntariado,
].sort((a, b) => {
  try {
    return a.value?.localeCompare(b.value)
  } catch (ex) {
    return 0
  }
})

@Component({
  selector: 'app-necessidades-form',
  templateUrl: './new-necessidades-form.component.html',
  styleUrls: ['./necessidades-form.component.scss'],
})
export class NecessidadesFormComponent implements OnInit {
  static readonly CADASTRO_GERAL = 0
  static readonly CADASTRO_DADOS_ABRIGO = 1
  static readonly CADASTRO_NECESSIDADE_ABRIGO = 2

  private analytics: Analytics = inject(Analytics)

  public message: string
  public categories: any[]
  public messageType: string
  public submitted: boolean = false
  public selectedCategorie: string
  public form: FormGroup
  public formUteis: FormGroup
  public abrigos: AbrigoDTO[] = []
  public abrigos2: Abrigo[] = []
  public editIndex: number = null
  private idAbrigo: string = ''
  public nomeAbrigo: string = ''
  public modalShow: boolean = false
  public titleModal: string = ''
  public typeEdit: number = NecessidadesFormComponent.CADASTRO_GERAL
  public searchText: string = ''
  public listaFiltrada: BehaviorSubject<AbrigoDTO[]> = new BehaviorSubject([])

  public itensCategorias
  public itemsRoupas: Items[]
  public itemsAlimentacao: Items[]
  public itemsProdutosLimpeza: Items[]
  public itemsProdutosHigienePessoal: Items[]
  public itemsItensPets: Items[]
  public itemsItensCriancas: Items[]
  public itemsOutros: Items[]
  public itemsMedicamentos: Items[]
  public itemsVoluntariado: Items[]

  public itensUteis = JSON.parse(JSON.stringify(itensUteis))

  public usuario = null
  public itensUtilOpened: boolean = false
  public erroCEP: boolean = false

  public permission: boolean = false
  public localOptions = LocalOptionsDTO
  public classificacaoOptions = ClassificacaoOptionsDTO
  public abrigoOptions = AbrigoOptionsDTO
  public atendimentoOptions = AtendimentoOptiosnDTO
  public volunteersArray: FormArray

  public classificacaoOptionsSearch = ClassificacaoOptionsDTO
  public citiesOptionsSearch: any

  public abrigosOptionsSearch: any

  private timeout: any

  public currentPage: number = 1
  public itemsPerPage: number = 9
  public totalPages: number = 0
  public totalItems: number = 0

  constructor(
    private fb: FormBuilder,
    private _http: HttpClient,
    public authorizationService: AuthorizationService,
    private excelService: ExcelService,
    private _authService: AuthService,
    private loaderService: LoaderService,
    private abrigoService: AbrigoService,
    private getAbrigoUseCase: GetAbrigoUseCase,
    private createOrUpdateAbrigoUseCase: CreateOrUpdateAbrigoUseCase
  ) {
    this.usuario = this._authService.currentUser

    this.listaFiltrada.subscribe((data) => this._onListUpdated(data))
  }

  async ngOnInit() {
    this.buildFormGroup()
    this.loaderService.show()
    this.categories = [
      { value: 'alimentos', viewValue: 'Alimentos' },
      { value: 'alojamento', viewValue: 'Alojamento' },
      { value: 'higiene', viewValue: 'Higiene' },
      { value: 'limpeza', viewValue: 'Limpeza' },
      { value: 'pet', viewValue: 'Pet' },
      { value: 'socorros', viewValue: 'Primeiros Socorros' },
      { value: 'vestuario', viewValue: 'Vestuario' },
    ]
    await this.fetchAbrigos()

    // TODO: Implementar regras, faze provisória
    this.permission = this.usuario.email === 'liana.rigon@procempa.com.br'

    this.itensCategorias = JSON.parse(JSON.stringify(itensCategorias))

    this.itemsRoupas = JSON.parse(JSON.stringify(itemsRoupas))
    this.itemsAlimentacao = JSON.parse(JSON.stringify(itemsAlimentacao))
    this.itemsProdutosLimpeza = JSON.parse(JSON.stringify(itemsProdutosLimpeza))
    this.itemsProdutosHigienePessoal = JSON.parse(JSON.stringify(itemsProdutosHigienePessoal))
    this.itemsItensPets = JSON.parse(JSON.stringify(itemsItensPets))
    this.itemsItensCriancas = JSON.parse(JSON.stringify(itemsItensCriancas))
    this.itemsOutros = JSON.parse(JSON.stringify(itemsOutros))
    this.itemsMedicamentos = JSON.parse(JSON.stringify(itemsMedicamentos))
    this.itemsVoluntariado = JSON.parse(JSON.stringify(itemsVoluntariado))

    this.loaderService.hide()
  }

  scrollToTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }

  loadTableData(abrigos) {
    for (let abrigo of abrigos) {
      abrigo.local_info['tipo_local_grid'] = Array.isArray(abrigo.local_info.tipo_local)
        ? abrigo.local_info.tipo_local.join(', ')
        : abrigo.local_info.tipo_local
      abrigo.local_info['tipo_abrigo_grid'] = Array.isArray(abrigo.local_info.tipo_abrigo)
        ? abrigo.local_info.tipo_abrigo.join(', ')
        : abrigo.local_info.tipo_abrigo
    }
  }

  async fetchAbrigos() {
    this.submitted = true
    this.abrigos = await this.getAbrigoUseCase.run()
    this.loadTableData(this.abrigos)
    this.abrigos2 = await this.getAbrigoUseCase.run2()
    this.citiesOptionsSearch = this.abrigoService.getCityToFilter(this.abrigos2, false)
    this.abrigosOptionsSearch = this.getAbrigosToFilter(this.abrigos2)
    this.search()
    this.submitted = false
    // this.listaFiltrada.next(this.abrigos)
  }

  getAbrigosToFilter(abrigos: Abrigo[]) {
    let abrigoOptions: {
      value: string
      label: string
      selected: boolean
    }[] = []

    function addAbrigoToFilter(name: string) {
      if (!name) return
      if (abrigoOptions.filter((c) => c.value === name).length === 0) {
        abrigoOptions = [...abrigoOptions, { value: name, label: name, selected: false }]
        abrigoOptions = abrigoOptions.sort((a, b) => {
          try {
            return a.label?.localeCompare(b.label)
          } catch (ex) {
            return 0
          }
        })
      }
    }

    abrigos.forEach((abrigo) => addAbrigoToFilter(abrigo.nome))

    abrigoOptions.unshift({
      value: '',
      label: 'Escolha um abrigo',
      selected: true,
    })
    return abrigoOptions
  }

  converterParaNumero(valor: any): number {
    const numero = parseFloat(valor)
    return isNaN(numero) ? 0 : numero
  }

  calcularTotalVagas(abrigos: any[]): number {
    return abrigos.reduce((total, abrigo) => total + this.converterParaNumero(abrigo.vagas_info.vagas_totais), 0)
  }

  calcularTotalVagasOcupadas(abrigos: any[]): number {
    return abrigos.reduce((total, abrigo) => total + this.converterParaNumero(abrigo.vagas_info.vagas_ocupadas), 0)
  }

  calcularVagasDisponiveis(abrigos: any[]): number {
    return abrigos.reduce(
      (total, abrigo) =>
        total +
        (this.converterParaNumero(abrigo.vagas_info.vagas_totais) -
          this.converterParaNumero(abrigo.vagas_info.vagas_ocupadas)),
      0
    )
  }

  calcularPorcentagemVagasOcupadas(abrigos: any[]): string {
    const totalVagas = this.calcularTotalVagas(abrigos)
    const totalVagasOcupadas = this.calcularTotalVagasOcupadas(abrigos)
    if (totalVagas === 0) {
      return '0'
    }
    return ((totalVagasOcupadas / totalVagas) * 100).toFixed(2)
  }

  async buscarEnderecoPorCep(cep: string) {
    if (!cep) return
    const cepNumerico = cep.replace(/\D/g, '')

    const endereco = await this._http
      .get<any>(`https://viacep.com.br/ws/${cepNumerico}/json/`)
      .toPromise()
      .catch((error) => {
        console.error('Erro ao buscar endereço:', error)
      })

    if (endereco.erro) {
      this.erroCEP = true
      this.form.get(['endereco', 'cep'])?.setErrors({ invalidCEP: true, opts: { emitEvent: true } })
      this.form.get(['endereco', 'cep'])?.markAsUntouched()
      this.form.patchValue({
        endereco: {
          rua: '',
          cidade: '',
        },
      })
    } else {
      this.erroCEP = false
      this.form.get(['endereco', 'cep'])?.setErrors(null)
      this.form.patchValue({
        endereco: {
          rua: this.formatarEndereco(endereco),
          cidade: endereco.localidade,
        },
      })
    }
  }

  formatarEndereco(data: any): string {
    if (data.erro) return ''
    return `${data.logradouro}, ${data.complemento ? data.complemento + ', ' : ''}${data.bairro}, ${data.localidade} - ${data.uf}, CEP: ${data.cep}`
  }

  changeSelect(event: any, name: string[]): void {
    const value = Array.isArray(event.detail[0])
      ? event.detail[0]?.reduce((acc, cur) => [...acc, cur.value], [])
      : event.detail[0]?.value
    this.form.get(name)?.setValue(value)
  }

  showFormSubmitted(message: string, type: string) {
    this.submitted = true
    this.message = message
    this.messageType = type

    setTimeout(() => {
      this.submitted = false
    }, 10000)
  }

  validBoolValue(value: any): boolean {
    if (value === undefined) return false
    return value
  }

  validStringValue(value: any): string {
    if (value === undefined) return null
    return value
  }

  async onSubmit() {
    try {
      if (this.form.valid) {
        const abrigoSaved = await this.createOrUpdateAbrigoUseCase.run({
          abrigoId: this.idAbrigo,
          formValues: this.form.getRawValue(),
          update_in: new Date(),
        })
        this.scrollToTop()
        this.modalShow = false
        this.form.reset()
        const uteisArray = this.form.get('itensUteis') as FormArray
        uteisArray.clear()
        this.idAbrigo = ''
        this.nomeAbrigo = ''
        this.itensUteis = JSON.parse(JSON.stringify(itensUteis))
        await this.fetchAbrigos()
        this.showFormSubmitted('Formulário enviado com sucesso!', 'success')
      } else {
        this.scrollToTop()
        this.form.markAllAsTouched()
        this.showFormSubmitted('Formulário inválido!', 'danger')
      }
    } catch (err) {
      console.error('error:', err)
    }
  }

  updateAbrigo(idAbrigo, abrigoSaved) {
    this.abrigos.find((abrigo) => abrigo.id == idAbrigo).itensUteis = abrigoSaved.itensUteis
  }

  handleEditAbrigo(abrigo: AbrigoDTO) {
    this.localOptions = this.localOptions.map((local) => {
      local.selected = abrigo.local_info.tipo_local?.includes(local.value)
      return local
    })

    this.classificacaoOptions = this.classificacaoOptions.map((classification) => {
      classification.selected = abrigo.local_info.classificacao_local === classification.value
      return classification
    })

    this.abrigoOptions = this.abrigoOptions.map((abrigoOpt) => {
      abrigoOpt.selected = abrigo.local_info.tipo_abrigo?.includes(abrigoOpt.value)
      return abrigoOpt
    })

    this.atendimentoOptions = this.atendimentoOptions.map((atendimentoOpt) => {
      atendimentoOpt.selected = abrigo.atendimento_info?.apoio_medico?.includes(atendimentoOpt.value)
      return atendimentoOpt
    })

    abrigo.local_info['tipo_local_grid'] = Array.isArray(abrigo.local_info.tipo_local)
      ? abrigo.local_info.tipo_local.join(', ')
      : abrigo.local_info.tipo_local
    abrigo.local_info['tipo_abrigo_grid'] = Array.isArray(abrigo.local_info.tipo_abrigo)
      ? abrigo.local_info.tipo_abrigo.join(', ')
      : abrigo.local_info.tipo_abrigo

    this.itensUteis = JSON.parse(JSON.stringify(itensUteis))

    this.idAbrigo = abrigo.id
    this.itensUtilOpened = false
    this.nomeAbrigo = abrigo.nome

    const { cep } = abrigo.endereco

    this.form.patchValue(abrigo)
    this.form.get('endereco').patchValue({
      ...abrigo.endereco,
      cep,
    })

    if (cep) {
      this.form.get(['endereco', 'cep']).updateValueAndValidity()
    }

    let uteis = this.form.get('itensUteis') as FormArray
    if (!uteis) {
      uteis = this.fb.array([]) // Cria um FormArray vazio se não existir
      this.form.setControl('itensUteis', uteis)
    } else {
      uteis.clear() // Limpa os itens antes de adicionar os novos
    }

    if (abrigo.itensUteis) {
      this.itensUteis = this.itensUteis.map((itemUtil) => {
        itemUtil.selected = abrigo.itensUteis.some((item) => {
          // .toString() foi adicionado pois existem casos ondem o item.item vira NaN e isso quebra a comparação Boolean.
          return item.item.toString().toLocaleLowerCase() === itemUtil.value.toLocaleLowerCase()
        })

        return itemUtil
      })

      // TODO: adicionar label nesses objetos, para poder mostrar o label e não o item.item, com isso podemos mudar os values tudo para minusculo ou qualquer valor que quisermos.
      abrigo.itensUteis.forEach((item: ItemUteis) => {
        uteis.push(
          this.fb.group({
            item: item.item,
            label: item.label || item.item,
            quantidade: item.quantidade,
            type: item.type,
          })
        )
      })
    }

    let equipeApoio = this.form.get('equipe_apoio') as FormArray
    if (!equipeApoio) {
      equipeApoio = this.fb.array([]) // Cria um FormArray vazio se não existir
      this.form.setControl('equipe_apoio', equipeApoio)
    } else {
      equipeApoio.clear() // Limpa os itens antes de adicionar os novos
    }

    if (abrigo.equipe_apoio) {
      abrigo.equipe_apoio.forEach((item: any) => {
        equipeApoio.push(
          this.fb.group({
            apoio_responsavel: item.apoio_responsavel,
            apoio_telefone: item.apoio_telefone,
          })
        )
      })
    }
  }

  handleInput(fieldName: string[]) {
    const fieldControl = this.form.get(fieldName)

    if (fieldControl?.value?.toString()?.trim() === '') {
      fieldControl?.setValue('')
      fieldControl?.markAsUntouched()
      fieldControl?.markAsPristine()
      fieldControl?.updateValueAndValidity()
    }
  }

  editItem(i: number) {
    this.editIndex = i
    const uteis = this.form.get('itensUteis') as FormArray
    const item = uteis.at(i)
    this.formUteis.patchValue({
      item: item.get('item').value,
      quantidade: item.get('quantidade').value,
    })
  }

  updateItem(index, itemValue, isCustom) {
    const uteis = this.form.get('itensUteis') as FormArray
    const item = uteis.at(index)
    let itemToChange = {}
    if (isCustom) itemToChange['item'] = itemValue
    else itemToChange['quantidade'] = +itemValue

    const itemToUpdate = {
      ...item.value.item,
      ...itemToChange,
    }
    item.patchValue(itemToUpdate)
  }

  deleteItem(i: number) {
    const uteis = this.form.get('itensUteis') as FormArray
    uteis.removeAt(i)
  }

  addItem(itemValue, itemLabel, itemCategory) {
    const uteis = this.form.get('itensUteis') as FormArray
    uteis.insert(
      0,
      this.fb.group({
        item: itemValue,
        label: itemLabel,
        quantidade: 0,
        type: itemCategory,
      })
    )
  }

  handleInputItenUtil(i, ev, isCustom) {
    let itemValue = ev.detail[0]
    if (typeof itemValue === 'string') {
      itemValue = itemValue.replace(',', '.')
    }
    this.updateItem(i, parseFloat(itemValue), isCustom)
  }

  ///Voluntários
  handleInputVolunteer(i, fieldName, ev) {
    const itemValue = ev.detail[0]
    this.updateVolunteer(i, fieldName, itemValue)
  }

  updateVolunteer(index, fieldName, itemValue) {
    const uteis = this.form.get('equipe_apoio') as FormArray
    const item = uteis.at(index)
    const itemToChange = {}
    itemToChange[fieldName] = itemValue
    const newItem = {
      ...item.value,
      ...itemToChange,
    }
    item.setValue(newItem)
  }

  volunteerTrack(index, volunteer) {
    return index
  }

  addVolunteer(): void {
    const volunteers = this.form.get('equipe_apoio') as FormArray
    volunteers.push(
      this.fb.group({
        apoio_responsavel: null,
        apoio_telefone: null,
      })
    )
  }

  removeVolunteer(index): void {
    const volunteers = this.form.get('equipe_apoio') as FormArray
    volunteers.removeAt(index)
  }
  ///

  trackByItemUtil(index, item) {
    return item.item
  }

  toggleSelectItemUteis() {
    this.itensUtilOpened = !this.itensUtilOpened

    const uteis = this.form.get('itensUteis') as { value: Array<{ item: string }> }

    this.itensUteis = this.itensUteis.map((itemUtil) => {
      itemUtil.selected = uteis.value.some(({ item }) => item === itemUtil.value)

      return itemUtil
    })
  }

  selectItemUteis(item: Items) {
    const uteis = this.form.get('itensUteis') as FormArray
    let foundItemIndex = -1
    for (let i = 0; i < uteis.value.length; i++) {
      const itemForm = uteis.at(i)
      if (itemForm.value.item.toString().toLocaleLowerCase() === item.value.toLocaleLowerCase()) foundItemIndex = i
    }
    if (foundItemIndex >= 0) this.deleteItem(foundItemIndex)
    else this.addItem(item.value, item.label, item.category)
  }

  addOtherItem() {
    const uteis = this.form.get('itensUteis') as FormArray
    uteis.push(
      this.fb.group({
        item: null,
        quantidade: 0,
        type: 'custom',
      })
    )
  }

  updateIn(value: any): any {
    if (typeof value === 'string') {
      const date = new Date(value)
      if (!isNaN(date.getTime())) {
        return date.toLocaleString('pt-BR')
      }
      return 'Data inválida'
    }
    return value
  }

  closeModal() {
    this.modalShow = false
  }

  openModalAdd() {
    this.form.reset()
    this.form.get('ativo').setValue(true)
    const uteis = this.form.get('itensUteis') as FormArray
    uteis.clear()
    this.itensUteis = this.itensUteis.map((itemUtil) => {
      itemUtil.selected = false
      return itemUtil
    })

    this.localOptions = this.localOptions.map((local) => {
      local.selected = false
      return local
    })

    this.classificacaoOptions = this.classificacaoOptions.map((classification) => {
      classification.selected = false
      return classification
    })

    this.abrigoOptions = this.abrigoOptions.map((abrigoOpt) => {
      abrigoOpt.selected = false
      return abrigoOpt
    })

    this.atendimentoOptions = this.atendimentoOptions.map((atendimentoOpt) => {
      atendimentoOpt.selected = false
      return atendimentoOpt
    })

    this.modalShow = true
    this.typeEdit = NecessidadesFormComponent.CADASTRO_GERAL
    this.titleModal = 'Cadastrar abrigo'
    this.itensUtilOpened = true
  }

  openEditModalData(abrigo) {
    this.handleEditAbrigo(abrigo)
    this.modalShow = true
    this.typeEdit = NecessidadesFormComponent.CADASTRO_DADOS_ABRIGO
    this.titleModal = 'Editar dados do abrigo'
  }

  openEditModalNeed() {
    this.itensUteis = this.itensUteis.map((itemUtil) => {
      itemUtil.selected = this.form.get('itensUteis').value.some((item) => {
        return item.item.toString().toLocaleLowerCase() === itemUtil.value.toLocaleLowerCase()
      })

      return itemUtil
    })
    this.modalShow = true
    this.typeEdit = NecessidadesFormComponent.CADASTRO_NECESSIDADE_ABRIGO
    this.titleModal = 'Editar necessidades do abrigo'
  }

  searchAbrigos: string[]
  handleAbrigos(ev: any) {
    this.searchAbrigos = Array.isArray(ev.detail[0])
      ? ev.detail[0]?.map((abrigo) => abrigo.value)
      : [ev.detail[0].value]
    this.search()
  }

  timeoutSearch: any
  search() {
    if (this.timeoutSearch) clearTimeout(this.timeoutSearch)

    this.timeoutSearch = setTimeout(() => {
      if (this.searchAbrigos?.length) {
        const achados = this.abrigos.filter((abrigo) => {
          return abrigo.nome === this.searchAbrigos[0]
        })
        this.listaFiltrada.next(achados)
      } else {
        this.listaFiltrada.next([] as AbrigoDTO[])
      }

      this.listaFiltrada.value.length > 0 ? this.handleEditAbrigo(this.listaFiltrada.value[0]) : null
    }, 500)
  }

  exportToExcel() {
    let abrigos = []
    for (const abrig of this.abrigos) {
      abrigos.push({
        nome: abrig.nome_gestor,
        cidade: abrig.endereco.cidade,
        endereco: abrig.endereco.rua,
        telefone: abrig.telefone_gestor,
        vagas: abrig.vagas_info.vagas_totais,
        vagas_ocupadas: abrig.vagas_info.vagas_ocupadas,
        vagas_totais: abrig.vagas_info.vagas_totais - abrig.vagas_info.vagas_ocupadas,
      })
    }

    let dateAtual = new Date()
    let day = String(dateAtual.getDate()).padStart(2, '0')
    let month = String(dateAtual.getMonth() + 1).padStart(2, '0') // Adicionamos +1 ao mês porque o método getMonth retorna de 0 a 11
    let year = dateAtual.getFullYear()
    let concateDate = day + '_' + month + '_' + year
    this.excelService.exportToExcel(abrigos, `lista_abrigos_${concateDate}.xlsx`)
  }

  private buildFormGroup() {
    this.form = this.fb.group({
      ativo: new FormControl(true, Validators.required),
      nome: new FormControl(null, Validators.required),
      endereco: this.fb.group({
        cep: new FormControl(null, Validators.required),
        rua: new FormControl(null, Validators.required),
        bairro: new FormControl(null),
        cidade: new FormControl(null, Validators.required),
      }),
      ponto_referencia: new FormControl(null),
      orientacao_acesso: new FormControl(null),
      nome_gestor: new FormControl(null, Validators.required),
      telefone_gestor: new FormControl(null, Validators.required),
      vagas_info: this.fb.group({
        vagas_totais: new FormControl(0, Validators.required),
        vagas_ocupadas: new FormControl(0, Validators.required),
        vaga_mulher: new FormControl(false),
        vaga_mulher_quantidade: new FormControl(0),
        vaga_mulher_quantidade_ocupadas: new FormControl(0),
        vaga_pets: new FormControl(false),
        vaga_pets_quantidade: new FormControl(0),
        vaga_pets_quantidade_ocupadas: new FormControl(0),
      }),
      local_info: this.fb.group({
        tipo_local: new FormControl(null),
        classificacao_local: new FormControl(null),
        classificacao_local_outro: new FormControl(null),
        tipo_abrigo: new FormControl(null),
      }),
      infra_info: this.fb.group({
        cozinha: new FormControl(false),
        metragem: new FormControl(null),
        banheiros: new FormControl(null),
        colchoes: new FormControl(null),
        chuveiros: new FormControl(null),
        acessibilidade: new FormControl(false),
        obs_acessibilidade: new FormControl(null),
        fornecimento_agua: new FormControl(false),
        fornecimento_eletrica: new FormControl(false),
        gerador: new FormControl(false),
        climatizacao: new FormControl(null),
        obs_climatizacao: new FormControl(null),
        enfermaria: new FormControl(false),
      }),
      atendimento_info: this.fb.group({
        qtd_voluntarios: new FormControl(0),
        atendimento: new FormControl(false),
        apoio_medico: new FormControl([]),
        medicamentos: new FormControl(false),
        apoio_medicamentos: new FormControl(null),
      }),
      equipe_apoio: this.fb.array<EquipeApoio>([]),
      itensUteis: this.fb.array<ItemUteis>([]),
      necessidadesExtras: new FormControl(null),
      observations: new FormControl(null),
      update_in: new FormControl(null),
    })
  }

  private _onListUpdated(items: AbrigoDTO[]) {
    this.totalItems = items.length
    this.handleResetPagination()
  }

  handlePageChange(newPage: number) {
    this.currentPage = newPage
  }

  handleItemsPerPageChange(newItemsPerPage: number) {
    this.itemsPerPage = newItemsPerPage
    this.handleResetPagination()
  }

  handleResetPagination() {
    this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage)
    this.currentPage = 1
  }

  changeSwitch($event, elements) {
    const { checked } = $event.target

    elements.forEach((element) => {
      const value = this.form.get(element).getRawValue()

      if ([null, '0', ''].includes(value)) {
        this.form.get(element).setValue(checked ? '0' : null)
      }
    })
  }

  get currentPageItems(): AbrigoDTO[] {
    const start = (this.currentPage - 1) * this.itemsPerPage
    const end = start + this.itemsPerPage
    return this.listaFiltrada.value.slice(start, end)
  }

  isValidDate(dateString: string): boolean {
    const date = new Date(dateString)
    return !isNaN(date.getTime())
  }
}
