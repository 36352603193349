import { Component, Input, OnChanges, SimpleChanges, inject } from '@angular/core'
import AbrigoDTO from '../../../DTOs/AbrigoDTO'
import { AtendimentoOptiosnDTO } from '../../../DTOs/AtendimentoOptionsDTO'
import { FormCadastroDeAbrigoComponent } from '../../form-cadastro-de-abrigo.component'

@Component({
  selector: 'app-attendance-fieldset',
  templateUrl: './attendance-fieldset.component.html',
  styleUrls: ['./attendance-fieldset.component.scss'],
})
export class AttendanceFieldsetComponent implements OnChanges {
  @Input('edit')
  public forEdit: AbrigoDTO

  private container = inject(FormCadastroDeAbrigoComponent)

  public atendimentoOptions = AtendimentoOptiosnDTO

  get formContainer() {
    return this.container
  }

  get form() {
    return this.container.form
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.forEdit.currentValue) {
      this.handleEdit(changes.forEdit.currentValue)
    }
  }

  private handleEdit(abrigo: AbrigoDTO) {
    this.atendimentoOptions = this.atendimentoOptions.map((atendimentoOpt) => {
      atendimentoOpt.selected = abrigo.atendimento_info?.apoio_medico?.includes(atendimentoOpt.value)

      return atendimentoOpt
    })
  }
}
