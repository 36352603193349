<fieldset [formGroup]="form">
  <legend>CLASSIFICAÇÃO DO ABRIGO</legend>

  <div class="mb-3" formGroupName="local_info">
    <div class="row">
      <div class="col d-flex justify-content-start align-items-center switch-col">
        <div class="br-switch" role="presentation">
          <input
            id="rede_prefeitura"
            name="rede_prefeitura"
            type="checkbox"
            role="switch"
            formControlName="rede_prefeitura"
            (change)="formContainer.changeSwitch([['local_info', 'rede_prefeitura']], $event)"
          />
          <label for="rede_prefeitura">Rede da prefeitura</label>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <label for="tipo_local">Tipo de Local</label>

        <div class="mt-1">
          <br-checkbox
            *ngFor="let option of localOptions"
            [label]="option.label"
            [value]="option.value"
            [checked]="option.selected"
            (click)="handleSelectOption(option)"
          />
        </div>
      </div>

      <div class="col">
        <br-select
          label="Classificação do local"
          placeholder="Classificação do local"
          [options]="classificacaoOptions"
          (change)="formContainer.changeSelect(['local_info', 'classificacao_local'], $event)"
        />

        <div *ngIf="form.get(['local_info', 'classificacao_local']).value?.indexOf('Outro') >= 0" class="mt-3">
          <br-input
            type="text"
            id="classificacao_local_outro"
            name="classificacao_local_outro"
            placeholder="Outra classificação do local"
            label="Outra classificação do local:"
            formControlName="classificacao_local_outro"
            (input)="formContainer.handleInput(['local_info', 'classificacao_local_outro'])"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div *ngIf="form.get(['local_info', 'tipo_local']).value?.indexOf('Abrigo') >= 0" class="mt-3">
          <br-select
            multiple
            label="Tipo de abrigo"
            placeholder="Tipo de abrigo"
            [options]="abrigoOptions"
            (change)="formContainer.changeSelect(['local_info', 'tipo_abrigo'], $event)"
          >
          </br-select>
        </div>
      </div>
    </div>
  </div>
</fieldset>
