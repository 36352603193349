import { HttpClient } from '@angular/common/http'
import { Component, Input, OnChanges, SimpleChanges, inject } from '@angular/core'
import AbrigoDTO from '../../../DTOs/AbrigoDTO'
import { FormCadastroDeAbrigoComponent } from '../../form-cadastro-de-abrigo.component'

type Address = {
  erro: boolean
  logradouro: string
  complemento: string
  bairro: string
  localidade: string
  uf: string
  cep: string
}

@Component({
  selector: 'app-basic-info-fieldset',
  templateUrl: './basic-info-fieldset.component.html',
  styleUrls: ['./basic-info-fieldset.component.scss'],
})
export class BasicInfoFieldsetComponent implements OnChanges {
  @Input('edit')
  public forEdit: AbrigoDTO

  private container = inject(FormCadastroDeAbrigoComponent)
  private _http = inject(HttpClient)

  public erroCEP: boolean = false

  get formContainer() {
    return this.container
  }

  get form() {
    return this.container.form
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.forEdit.currentValue) {
      this.handleEdit(changes.forEdit.currentValue)
    }
  }

  private handleEdit(abrigo: AbrigoDTO) {
    const { cep } = abrigo.endereco

    this.form.get('endereco').patchValue({
      ...abrigo.endereco,
      bairro: '',
      cep,
    })

    if (cep) {
      this.form.get(['endereco', 'cep']).updateValueAndValidity()
    }
  }

  async searchCep(data: string) {
    if (!data) return

    const cep = data.replace(/[^0-9]/g, '')

    const address = await this._http
      .get<Address>(`https://viacep.com.br/ws/${cep}/json/`)
      .toPromise()
      .catch((error) => {
        console.error('Erro ao buscar endereço:', error)
      })

    if (!address || address.erro) {
      this.erroCEP = true
      this.form.get(['endereco', 'cep'])?.setErrors({ invalidCEP: true, opts: { emitEvent: true } })
      this.form.get(['endereco', 'cep'])?.markAsUntouched()
      this.form.patchValue({
        endereco: {
          rua: '',
          cidade: '',
        },
      })
    } else {
      this.erroCEP = false
      this.form.get(['endereco', 'cep'])?.setErrors(null)
      this.form.patchValue({
        endereco: {
          rua: this.formatAddress(address),
          cidade: `${address.localidade} - ${address.uf}`,
        },
      })
    }
  }

  formatAddress(data: Address): string {
    return `${data.logradouro}, ${data.complemento ? `${data.complemento}, ` : ''}${data.bairro}`
  }
}
