import { Component, inject, OnInit } from '@angular/core'
import { Analytics } from '@angular/fire/analytics'
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { BehaviorSubject } from 'rxjs'
import { AuthorizationService } from 'src/app/core/services/authorization.service'
import { Abrigo, AbrigoService, ItemUteis } from 'src/app/core/services/repository/abrigo.service'
import { removeAccents } from 'src/app/shared/utils/removeAccents'
import { CreateOrUpdateAbrigoUseCase } from './useCases/createOrUpdateAbrigo.useCase'
import { GetAbrigoUseCase } from './useCases/getAbrigos.useCase'

import { HttpClient } from '@angular/common/http'

import { ExcelService } from 'src/app/core/services/excel.service'
import { AuthService } from 'src/app/core/services/repository/firebase/auth.service'
import { LoaderService } from 'src/app/shared/components/loader/loader.service'
import AbrigoDTO, { EquipeApoio } from './DTOs/AbrigoDTO'
import { AbrigoOptionsDTO } from './DTOs/AbrigoOptionsDTO'
import { AtendimentoOptiosnDTO } from './DTOs/AtendimentoOptionsDTO'
import { ClassificacaoOptionsDTO } from './DTOs/ClassificacaoOptionsDTO'
import { LocalOptionsDTO } from './DTOs/LocalOptionsDTO'
import { OrderByOptionsDTO } from './DTOs/OrderByOptionsDTO'

@Component({
  selector: 'app-vagas-form',
  templateUrl: './new-vagas-form.component.html',
  styleUrls: ['./vagas-form.component.scss'],
})
export class VagasFormComponent implements OnInit {
  static readonly CADASTRO_GERAL = 0
  static readonly CADASTRO_DADOS_ABRIGO = 1
  static readonly CADASTRO_NECESSIDADE_ABRIGO = 2

  private analytics: Analytics = inject(Analytics)

  public message: string
  public categories: any[]
  public messageType: string
  public submitted: boolean = false
  public selectedCategorie: string
  public form: FormGroup
  public formUteis: FormGroup
  public abrigos: AbrigoDTO[] = []
  public abrigos2: Abrigo[] = []
  public editIndex: number = null
  private idAbrigo: string = ''
  public nomeAbrigo: string = ''
  public modalShow: boolean = false
  public titleModal: string = ''
  public typeEdit: number = VagasFormComponent.CADASTRO_GERAL
  public searchText: string = ''
  public sortOrder: 'asc' | 'desc' = 'asc'
  public listaFiltrada: BehaviorSubject<AbrigoDTO[]> = new BehaviorSubject([])

  public itensUteis: any
  public necessidadesExtras: string

  public usuario = null
  public itensUtilOpened: boolean = false
  public erroCEP: boolean = false

  public permission: boolean = false
  public localOptions = LocalOptionsDTO
  public classificacaoOptions = ClassificacaoOptionsDTO
  public abrigoOptions = AbrigoOptionsDTO
  public atendimentoOptions = AtendimentoOptiosnDTO
  public volunteersArray: FormArray

  public orderByOptions = OrderByOptionsDTO
  public tipoAbrigoOptionsSearch = AbrigoOptionsDTO
  public citiesOptionsSearch: any

  private timeout: any

  public currentPage: number = 1
  public itemsPerPage: number = 9
  public totalPages: number = 0
  public totalItems: number = 0

  constructor(
    private fb: FormBuilder,
    private _http: HttpClient,
    public authorizationService: AuthorizationService,
    private excelService: ExcelService,
    private _authService: AuthService,
    private loaderService: LoaderService,
    private abrigoService: AbrigoService,
    private getAbrigoUseCase: GetAbrigoUseCase,
    private createOrUpdateAbrigoUseCase: CreateOrUpdateAbrigoUseCase
  ) {
    this.usuario = this._authService.currentUser

    this.listaFiltrada.subscribe((data) => this._onListUpdated(data))
  }

  async ngOnInit() {
    this.buildFormGroup()
    this.loaderService.show()
    this.categories = [
      { value: 'alimentos', viewValue: 'Alimentos' },
      { value: 'alojamento', viewValue: 'Alojamento' },
      { value: 'higiene', viewValue: 'Higiene' },
      { value: 'limpeza', viewValue: 'Limpeza' },
      { value: 'pet', viewValue: 'Pet' },
      { value: 'socorros', viewValue: 'Primeiros Socorros' },
      { value: 'vestuario', viewValue: 'Vestuario' },
    ]
    await this.fetchAbrigos()

    // TODO: Implementar regras, faze provisória
    this.permission =
      this.usuario.email === 'liana.rigon@procempa.com.br' || !!this.authorizationService.AUTHORIZATION_LEVEL_SUPORTE

    this.loaderService.hide()
  }

  async orderAbrigosBy(text: string, sort: 'asc' | 'desc') {}

  async handleSortOrder(sort: 'asc' | 'desc') {
    this.sortOrder = sort
    this.search()
  }

  selectedOrderBy: string
  handleOrderBy(ev: any) {
    this.sortOrder = 'asc'
    this.selectedOrderBy = ev.detail[0].value
    this.search()
  }

  loadTableData(abrigos) {
    for (let abrigo of abrigos) {
      abrigo.local_info['tipo_local_grid'] = Array.isArray(abrigo.local_info.tipo_local)
        ? abrigo.local_info.tipo_local.join(', ')
        : abrigo.local_info.tipo_local
      abrigo.local_info['tipo_abrigo_grid'] = Array.isArray(abrigo.local_info.tipo_abrigo)
        ? abrigo.local_info.tipo_abrigo.join(', ')
        : abrigo.local_info.tipo_abrigo
    }
  }

  async fetchAbrigos() {
    this.submitted = true
    this.abrigos = await this.getAbrigoUseCase.run()
    this.loadTableData(this.abrigos)
    this.abrigos2 = await this.getAbrigoUseCase.run2()
    this.citiesOptionsSearch = this.abrigoService.getCityToFilter(this.abrigos2, false)
    this.search()
    this.submitted = false
    // this.listaFiltrada.next(this.abrigos)
  }

  converterParaNumero(valor: any): number {
    const numero = parseFloat(valor)
    return isNaN(numero) ? 0 : numero
  }

  calcularTotalVagas(abrigos: any[]): number {
    return abrigos.reduce((total, abrigo) => total + this.converterParaNumero(abrigo.vagas_info.vagas_totais), 0)
  }

  calcularTotalVagasOcupadas(abrigos: any[]): number {
    return abrigos.reduce((total, abrigo) => total + this.converterParaNumero(abrigo.vagas_info.vagas_ocupadas), 0)
  }

  calcularVagasDisponiveis(abrigos: any[]): number {
    return abrigos.reduce(
      (total, abrigo) =>
        total +
        (this.converterParaNumero(abrigo.vagas_info.vagas_totais) -
          this.converterParaNumero(abrigo.vagas_info.vagas_ocupadas)),
      0
    )
  }

  calcularPorcentagemVagasOcupadas(abrigos: any[]): string {
    const totalVagas = this.calcularTotalVagas(abrigos)
    const totalVagasOcupadas = this.calcularTotalVagasOcupadas(abrigos)
    if (totalVagas === 0) {
      return '0'
    }
    return ((totalVagasOcupadas / totalVagas) * 100).toFixed(2)
  }

  async buscarEnderecoPorCep(cep: string) {
    if (!cep) return
    const cepNumerico = cep.replace(/\D/g, '')

    const endereco = await this._http
      .get<any>(`https://viacep.com.br/ws/${cepNumerico}/json/`)
      .toPromise()
      .catch((error) => {
        console.error('Erro ao buscar endereço:', error)
      })

    if (endereco.erro) {
      this.erroCEP = true
      this.form.get(['endereco', 'cep'])?.setErrors({ invalidCEP: true, opts: { emitEvent: true } })
      this.form.get(['endereco', 'cep'])?.markAsUntouched()
      this.form.patchValue({
        endereco: {
          rua: '',
          cidade: '',
        },
      })
    } else {
      this.erroCEP = false
      this.form.get(['endereco', 'cep'])?.setErrors(null)
      this.form.patchValue({
        endereco: {
          rua: this.formatarEndereco(endereco),
          cidade: endereco.localidade,
        },
      })
    }
  }

  formatarEndereco(data: any): string {
    if (data.erro) return ''
    return `${data.logradouro}, ${data.complemento ? data.complemento + ', ' : ''}${data.bairro}, ${data.localidade} - ${data.uf}, CEP: ${data.cep}`
  }

  changeSelect(event: any, name: string[]): void {
    const value = Array.isArray(event.detail[0])
      ? event.detail[0]?.reduce((acc, cur) => [...acc, cur.value], [])
      : event.detail[0]?.value
    this.form.get(name)?.setValue(value)
  }

  showFormSubmitted(message: string, type: string) {
    this.submitted = true
    this.message = message
    this.messageType = type

    setTimeout(() => {
      this.submitted = false
    }, 10000)
  }

  validBoolValue(value: any): boolean {
    if (value === undefined) return false
    return value
  }

  validStringValue(value: any): string {
    if (value === undefined) return null
    return value
  }

  async onSubmit() {
    try {
      if (this.form.valid) {
        const abrigoSaved = await this.createOrUpdateAbrigoUseCase.run({
          abrigoId: this.idAbrigo,
          formValues: this.form.getRawValue(),
          itensUteis: this.itensUteis,
          necessidadesExtras: this.necessidadesExtras,
          update_in: new Date(),
        })
        this.modalShow = false
        this.form.reset()
        await this.fetchAbrigos()
        const uteisArray = this.form.get('itensUteis') as FormArray
        uteisArray.clear()
        this.idAbrigo = ''
        this.nomeAbrigo = ''
        this.showFormSubmitted('Formulário enviado com sucesso!', 'success')
        // this.updateAbrigo(this.idAbrigo, abrigoSaved)
      } else {
        this.form.markAllAsTouched()
        this.showFormSubmitted('Formulário inválido!', 'danger')
      }
    } catch (err) {
      console.error('error:', err)
    }
  }

  updateAbrigo(idAbrigo, abrigoSaved) {
    this.abrigos.find((abrigo) => abrigo.id == idAbrigo).itensUteis = abrigoSaved.itensUteis
  }

  handleEditAbrigo(abrigo: AbrigoDTO) {
    this.localOptions = this.localOptions.map((local) => {
      local.selected = abrigo.local_info.tipo_local?.includes(local.value)
      return local
    })

    this.classificacaoOptions = this.classificacaoOptions.map((classification) => {
      classification.selected = abrigo.local_info.classificacao_local === classification.value
      return classification
    })

    this.abrigoOptions = this.abrigoOptions.map((abrigoOpt) => {
      abrigoOpt.selected = abrigo.local_info.tipo_abrigo?.includes(abrigoOpt.value)
      return abrigoOpt
    })

    this.atendimentoOptions = this.atendimentoOptions.map((atendimentoOpt) => {
      atendimentoOpt.selected = abrigo.atendimento_info?.apoio_medico?.includes(atendimentoOpt.value)
      return atendimentoOpt
    })

    abrigo.local_info['tipo_local_grid'] = Array.isArray(abrigo.local_info.tipo_local)
      ? abrigo.local_info.tipo_local.join(', ')
      : abrigo.local_info.tipo_local
    abrigo.local_info['tipo_abrigo_grid'] = Array.isArray(abrigo.local_info.tipo_abrigo)
      ? abrigo.local_info.tipo_abrigo.join(', ')
      : abrigo.local_info.tipo_abrigo

    this.idAbrigo = abrigo.id
    this.itensUtilOpened = false
    this.nomeAbrigo = abrigo.nome

    const { cep } = abrigo.endereco

    this.form.patchValue(abrigo)
    this.form.get('endereco').patchValue({
      ...abrigo.endereco,
      cep,
    })

    if (cep) {
      this.form.get(['endereco', 'cep']).updateValueAndValidity()
    }

    this.itensUteis = abrigo.itensUteis.length > 0 ? abrigo.itensUteis : null
    this.necessidadesExtras = abrigo.necessidadesExtras ?? null

    let equipeApoio = this.form.get('equipe_apoio') as FormArray
    if (!equipeApoio) {
      equipeApoio = this.fb.array([]) // Cria um FormArray vazio se não existir
      this.form.setControl('equipe_apoio', equipeApoio)
    } else {
      equipeApoio.clear() // Limpa os itens antes de adicionar os novos
    }

    if (abrigo.equipe_apoio) {
      abrigo.equipe_apoio.forEach((item: any) => {
        equipeApoio.push(
          this.fb.group({
            apoio_responsavel: item.apoio_responsavel,
            apoio_telefone: item.apoio_telefone,
          })
        )
      })
    }
  }

  handleInput(fieldName: string[]) {
    const fieldControl = this.form.get(fieldName)

    if (fieldControl?.value?.toString()?.trim() === '') {
      fieldControl?.setValue('')
      fieldControl?.markAsUntouched()
      fieldControl?.markAsPristine()
      fieldControl?.updateValueAndValidity()
    }
  }

  updateItem(index, itemValue, isCustom = false) {
    const uteis = this.form.get('itensUteis') as FormArray
    const item = uteis.at(index)
    let itemToChange = {}
    if (isCustom) itemToChange['item'] = itemValue
    else itemToChange['quantidade'] = +itemValue

    const itemToUpdate = {
      ...item.value.item,
      ...itemToChange,
    }
    item.patchValue(itemToUpdate)
  }

  addItem(itemValue) {
    const uteis = this.form.get('itensUteis') as FormArray
    uteis.insert(
      0,
      this.fb.group({
        item: itemValue,
        quantidade: 0,
        type: null,
      })
    )
  }

  ///Voluntários
  handleInputVolunteer(i, fieldName, ev) {
    const itemValue = ev.detail[0]
    this.updateVolunteer(i, fieldName, itemValue)
  }

  updateVolunteer(index, fieldName, itemValue) {
    const uteis = this.form.get('equipe_apoio') as FormArray
    const item = uteis.at(index)
    const itemToChange = {}
    itemToChange[fieldName] = itemValue
    const newItem = {
      ...item.value,
      ...itemToChange,
    }
    item.setValue(newItem)
  }

  volunteerTrack(index, volunteer) {
    return index
  }

  addVolunteer(): void {
    const volunteers = this.form.get('equipe_apoio') as FormArray
    volunteers.push(
      this.fb.group({
        apoio_responsavel: null,
        apoio_telefone: null,
      })
    )
  }

  removeVolunteer(index): void {
    const volunteers = this.form.get('equipe_apoio') as FormArray
    volunteers.removeAt(index)
  }
  ///

  trackByItemUtil(index, item) {
    0
    return item.item
  }

  updateIn(value: any): any {
    if (typeof value === 'string') {
      const date = new Date(value)
      if (!isNaN(date.getTime())) {
        return date.toLocaleString('pt-BR')
      }
      return 'Data inválida'
    }
    return value
  }

  closeModal() {
    this.modalShow = false
  }

  openModalAdd() {
    this.form.reset()
    this.form.get('ativo').setValue(true)
    const uteis = this.form.get('itensUteis') as FormArray
    uteis.clear()

    this.localOptions = this.localOptions.map((local) => {
      local.selected = false
      return local
    })

    this.classificacaoOptions = this.classificacaoOptions.map((classification) => {
      classification.selected = false
      return classification
    })

    this.abrigoOptions = this.abrigoOptions.map((abrigoOpt) => {
      abrigoOpt.selected = false
      return abrigoOpt
    })

    this.atendimentoOptions = this.atendimentoOptions.map((atendimentoOpt) => {
      atendimentoOpt.selected = false
      return atendimentoOpt
    })

    this.modalShow = true
    this.typeEdit = VagasFormComponent.CADASTRO_GERAL
    this.titleModal = 'Cadastrar abrigo'
    this.itensUtilOpened = true
  }

  openEditModalData(abrigo) {
    this.handleEditAbrigo(abrigo)
    this.modalShow = true
    this.typeEdit = VagasFormComponent.CADASTRO_DADOS_ABRIGO
    this.titleModal = 'Editar dados do abrigo'
  }

  searchCities: string[]
  handleChangeCity(ev: any) {
    this.searchCities = Array.isArray(ev.detail[0]) ? ev.detail[0]?.map((city) => city.value) : [ev.detail[0].value]
    this.search()
  }

  searchShelterType: string[]
  handleChangeTipoAbrigo(ev: any) {
    this.searchShelterType = Array.isArray(ev.detail[0])
      ? ev.detail[0]?.map((shelterType) => shelterType.value)
      : [ev.detail[0].value]
    this.search()
  }

  getNestedValue(obj, field) {
    return field.split('.').reduce((acc, part) => acc && acc[part], obj)
  }

  sortByField(data, field) {
    return data.sort((a, b) => {
      const aValue = this.getNestedValue(a, field)
      const bValue = this.getNestedValue(b, field)

      if (this.sortOrder === 'asc') {
        if (aValue < bValue) return -1
        if (aValue > bValue) return 1
      } else if (this.sortOrder === 'desc') {
        if (aValue > bValue) return -1
        if (aValue < bValue) return 1
      }
      return 0
    })
  }

  timeoutSearch: any
  search() {
    if (this.timeoutSearch) clearTimeout(this.timeoutSearch)

    this.timeoutSearch = setTimeout(() => {
      let searchTerm = Array.isArray(this.searchText) ? this.searchText[0] : this.searchText
      searchTerm = removeAccents(searchTerm?.toLowerCase())

      if (!searchTerm || searchTerm?.trim() === '') searchTerm = null

      if (this.searchText || this.searchCities?.length || this.searchShelterType?.length || this.selectedOrderBy) {
        const achados = this.abrigos.filter((abrigo) => {
          const nome = typeof abrigo.nome === 'string' ? removeAccents(abrigo.nome.toLowerCase()) : ''
          const rua = removeAccents(abrigo.endereco.rua?.toLowerCase())
          const cidade = abrigo.endereco.cidade
          const tipoAbrigo = abrigo.local_info.tipo_abrigo

          const bNome = nome.includes(searchTerm) || rua.includes(searchTerm) || searchTerm === null
          const bCidade = this.searchCities.includes(cidade) || this.searchCities.length === 0
          const bClassi =
            this.searchShelterType.some((e) => tipoAbrigo?.includes(e)) || this.searchShelterType.length === 0

          return bNome && bCidade && bClassi
        })

        if (this.selectedOrderBy !== '') {
          switch (this.selectedOrderBy) {
            case 'name':
              this.sortByField(achados, 'nome')
              break
            case 'city':
              this.sortByField(achados, 'endereco.cidade')
              break
            case 'manager':
              this.sortByField(achados, 'nome_gestor')
              break
            case 'available_spots':
              achados.sort((a, b) => {
                const a_available_spots = a.vagas_info.vagas_totais - a.vagas_info.vagas_ocupadas
                const b_available_spots = b.vagas_info.vagas_totais - b.vagas_info.vagas_ocupadas
                if (this.sortOrder === 'asc') {
                  if (a_available_spots < b_available_spots) return -1
                  if (a_available_spots > b_available_spots) return 1
                } else if (this.sortOrder === 'desc') {
                  if (a_available_spots > b_available_spots) return -1
                  if (a_available_spots < b_available_spots) return 1
                }
                return 0
              })
              break
            case 'woman_spots':
              achados.sort((a, b) => {
                const a_woman_spots = a.vagas_info.vaga_mulher_quantidade - a.vagas_info.vaga_mulher_quantidade_ocupadas
                const b_woman_spots = b.vagas_info.vaga_mulher_quantidade - b.vagas_info.vaga_mulher_quantidade_ocupadas
                if (this.sortOrder === 'asc') {
                  if (a_woman_spots < b_woman_spots) return -1
                  if (a_woman_spots > b_woman_spots) return 1
                } else if (this.sortOrder === 'desc') {
                  if (a_woman_spots > b_woman_spots) return -1
                  if (a_woman_spots < b_woman_spots) return 1
                }
                return 0
              })
              break
            case 'pet_spots':
              achados.sort((a, b) => {
                const a_pet_spots = a.vagas_info.vaga_pets_quantidade - a.vagas_info.vaga_pets_quantidade_ocupadas
                const b_pet_spots = b.vagas_info.vaga_pets_quantidade - b.vagas_info.vaga_pets_quantidade_ocupadas
                if (this.sortOrder === 'asc') {
                  if (a_pet_spots < b_pet_spots) return -1
                  if (a_pet_spots > b_pet_spots) return 1
                } else if (this.sortOrder === 'desc') {
                  if (a_pet_spots > b_pet_spots) return -1
                  if (a_pet_spots < b_pet_spots) return 1
                }
                return 0
              })
              break
            default:
              break
          }
        }

        this.listaFiltrada.next(achados)
      } else {
        this.listaFiltrada.next(this.abrigos)
      }
    }, 500)
  }

  exportToExcel() {
    let abrigos = []

    for (const abrigo of this.abrigos) {
      const {
        id,
        endereco,
        vagas_info,
        equipe_apoio: equipe,
        local_info,
        infra_info,
        atendimento_info,
        itensUteis,
        observations,
        ...rest
      } = abrigo

      const equipe_apoio = equipe
        .map((pessoa) => {
          if (!pessoa || !pessoa.apoio_responsavel) {
            return null
          }

          return `${pessoa.apoio_responsavel} - ${pessoa.apoio_telefone} `
        })
        .join('\n')

      const itens_uteis = itensUteis
        .map((item) => {
          if (!item.item && !item.label && !item.type) {
            return null
          }

          return `${item.quantidade}x ${item.item || item.label || item.type} `
        })
        .join('\n')

      abrigos.push({
        ...rest,
        ...endereco,
        ...vagas_info,
        observations,
        equipe_apoio,
        itens_uteis,
        ...local_info,
        ...infra_info,
        ...atendimento_info,
      })
    }

    let dateAtual = new Date()
    let day = String(dateAtual.getDate()).padStart(2, '0')
    let month = String(dateAtual.getMonth() + 1).padStart(2, '0') // Adicionamos +1 ao mês porque o método getMonth retorna de 0 a 11
    let year = dateAtual.getFullYear()
    let concateDate = day + '_' + month + '_' + year

    const colunas = Object.keys(abrigos[0])

    const abrigosPorColunas = colunas.map((coluna) => abrigos.map((item) => item[coluna]))

    let largurasDasColunas = []

    for (const coluna of abrigosPorColunas) {
      const larguraMaxima = coluna.reduce((acumulador, atual) => {
        return Math.max(acumulador, `${atual}`.length)
      }, 15)

      largurasDasColunas.push({
        wch: larguraMaxima,
      })
    }

    this.excelService.exportToExcel(abrigos, `lista_abrigos_${concateDate}.xlsx`, largurasDasColunas)
  }

  private buildFormGroup() {
    this.form = this.fb.group({
      ativo: new FormControl(true, Validators.required),
      nome: new FormControl(null, Validators.required),
      endereco: this.fb.group({
        cep: new FormControl(null, Validators.required),
        rua: new FormControl(null, Validators.required),
        bairro: new FormControl(null),
        cidade: new FormControl(null, Validators.required),
      }),
      ponto_referencia: new FormControl(null),
      orientacao_acesso: new FormControl(null),
      nome_gestor: new FormControl(null, Validators.required),
      telefone_gestor: new FormControl(null, Validators.required),
      vagas_info: this.fb.group({
        vagas_totais: new FormControl(0, Validators.required),
        vagas_ocupadas: new FormControl(0, Validators.required),
        vaga_mulher: new FormControl(false),
        vaga_mulher_quantidade: new FormControl(0),
        vaga_mulher_quantidade_ocupadas: new FormControl(0),
        vaga_pets: new FormControl(false),
        vaga_pets_quantidade: new FormControl(0),
        vaga_pets_quantidade_ocupadas: new FormControl(0),
      }),
      local_info: this.fb.group({
        tipo_local: new FormControl(null),
        classificacao_local: new FormControl(null),
        classificacao_local_outro: new FormControl(null),
        tipo_abrigo: new FormControl(null),
      }),
      infra_info: this.fb.group({
        cozinha: new FormControl(false),
        metragem: new FormControl(null),
        banheiros: new FormControl(null),
        colchoes: new FormControl(null),
        chuveiros: new FormControl(null),
        acessibilidade: new FormControl(false),
        obs_acessibilidade: new FormControl(null),
        fornecimento_agua: new FormControl(false),
        fornecimento_eletrica: new FormControl(false),
        gerador: new FormControl(false),
        climatizacao: new FormControl(null),
        obs_climatizacao: new FormControl(null),
        enfermaria: new FormControl(false),
      }),
      atendimento_info: this.fb.group({
        qtd_voluntarios: new FormControl(0),
        atendimento: new FormControl(false),
        apoio_medico: new FormControl<string[]>([]),
        medicamentos: new FormControl(false),
        apoio_medicamentos: new FormControl(null),
      }),
      equipe_apoio: this.fb.array<EquipeApoio>([]),
      itensUteis: this.fb.array<ItemUteis>([]),
      observations: new FormControl(null),
      update_in: new FormControl(null),
    })
  }

  private _onListUpdated(items: AbrigoDTO[]) {
    this.totalItems = items.length
    this.handleResetPagination()
  }

  handlePageChange(newPage: number) {
    this.currentPage = newPage
  }

  handleItemsPerPageChange(newItemsPerPage: number) {
    this.itemsPerPage = newItemsPerPage
    this.handleResetPagination()
  }

  handleResetPagination() {
    this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage)
    this.currentPage = 1
  }

  changeSwitch($event, elements) {
    const { checked } = $event.target

    elements.forEach((element) => {
      const value = this.form.get(element).getRawValue()

      if ([null, '0', ''].includes(value)) {
        this.form.get(element).setValue(checked ? '0' : null)
      }
    })
  }

  get currentPageItems(): AbrigoDTO[] {
    const start = (this.currentPage - 1) * this.itemsPerPage
    const end = start + this.itemsPerPage
    return this.listaFiltrada.value.slice(start, end)
  }

  isValidDate(dateString: string): boolean {
    const date = new Date(dateString)
    return !isNaN(date.getTime())
  }
}
