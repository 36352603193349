<fieldset [formGroup]="form">
  <legend>INFORMAÇÕES ADICIONAIS</legend>

  <div class="mb-3" formGroupName="informacao_adicional">
    <div class="row">
      <div class="col">
        <br-input
          type="text"
          id="origem_abrigados"
          name="origem_abrigados"
          formControlName="origem_abrigados"
          label="Qual a de origem dos abrigados?"
          placeholder="Qual a de origem dos abrigados?"
          (input)="formContainer.handleInput(['origem_abrigados'])"
        ></br-input>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <br-input
          type="text"
          id="chegada_espontanea"
          name="chegada_espontanea"
          formControlName="chegada_espontanea"
          label="Quantos chegaram por demanda espontânea?"
          placeholder="Quantos chegaram por demanda espontânea?"
          (input)="formContainer.handleInput(['chegada_espontanea'])"
        ></br-input>
      </div>

      <div class="col">
        <br-input
          type="text"
          id="chegada_por_triagem"
          name="chegada_por_triagem"
          formControlName="chegada_por_triagem"
          label="Quantos chegaram pela triagem?"
          placeholder="Quantos chegaram pela triagem?"
          (input)="formContainer.handleInput(['chegada_por_triagem'])"
        ></br-input>
      </div>
    </div>

    <div class="divider"></div>

    <div class="row">
      <div class="col">
        <label for="fornecedor_assistencia_social">Há serviço de Assistência Social?</label>

        <div class="mt-1">
          <br-checkbox
            *ngFor="let option of assistenciaSocialOptions"
            [label]="option.label"
            [value]="option.value"
            [checked]="option.selected"
            (click)="handleSelectOption('fornecedor_assistencia_social', option)"
          />
        </div>
      </div>

      <div class="col">
        <label for="fornecedor_psicologia">Há serviço de psicologia?</label>

        <div class="mt-1">
          <br-checkbox
            *ngFor="let option of servicoPsicologiaOptions"
            [label]="option.label"
            [value]="option.value"
            [checked]="option.selected"
            (click)="handleSelectOption('fornecedor_psicologia', option)"
          />
        </div>
      </div>
    </div>

    <div class="divider"></div>

    <div class="row">
      <div class="col">
        <label for="abrigados_necessidades_especiais">Há abrigado com necessidades especiais?</label>

        <div class="mt-1">
          <br-checkbox
            *ngFor="let option of necessidadesEspeciaisOptions"
            [label]="option.label"
            [value]="option.value"
            [checked]="option.selected"
            (click)="handleSelectOption('abrigados_necessidades_especiais', option)"
          />
        </div>
      </div>
    </div>

    <div class="divider"></div>

    <div>
      <div class="row">
        <div class="col">
          <label for="abrigados_interesse_casa_familiar"
            >Há abrigados que demonstram interesse em ir para casa de familiar/amigo?</label
          >
        </div>
      </div>

      <div
        class="row align-items-end"
        *ngFor="
          let abrigados of form.get(['informacao_adicional', 'abrigados_interesse_casa_familiar']).value;
          let vi = index;
          trackBy: abrigadoTrack
        "
      >
        <div class="col-8">
          <br-input
            type="text"
            [id]="'abrigado_interesse_casa_familiar' + vi.toString()"
            placeholder="Nome do Abrigado"
            label="Nome do Abrigado"
            [value]="abrigados.nome"
            (input)="handleInputAbrigado(vi, 'nome', $event)"
          ></br-input>
        </div>

        <div class="col-1 pb-1">
          <br-button
            circle
            id="deleteVolunteer"
            primary
            icon="minus"
            type="primary"
            class="small mr-4 mb-1"
            (click)="removerAbrigadoInteresseCasaFamiliar(vi)"
          >
          </br-button>
        </div>
      </div>

      <div class="row align-items-center">
        <div class="col text-left">
          <br-button
            circle
            id="addicionarAbrigadoInteresseCasaFamiliar"
            primary
            icon="plus"
            type="primary"
            class="small mr-4 mb-1"
            (click)="addicionarAbrigadoInteresseCasaFamiliar()"
          >
          </br-button>

          <span class="pl-baseh">Adicionar abrigado</span>
        </div>
      </div>
    </div>

    <div class="divider"></div>

    <div class="row">
      <div class="col">
        <label for="ocorrencia_violacao_direitos_humanos">Já houve situação de violações de direitos humanos?</label>

        <div class="mt-1">
          <br-checkbox
            *ngFor="let option of violacoesDireitosHumanosOptions"
            [label]="option.label"
            [value]="option.value"
            [checked]="option.selected"
            (click)="handleSelectOption('ocorrencia_violacao_direitos_humanos', option)"
          />
        </div>
      </div>

      <div class="col">
        <label for="visita_orgao_oficial">Algum órgão ofcial já esteve no local?</label>

        <div class="mt-1">
          <br-checkbox
            *ngFor="let option of orgaoOficialOptions"
            [label]="option.label"
            [value]="option.value"
            [checked]="option.selected"
            (click)="handleSelectOption('visita_orgao_oficial', option)"
          />
        </div>
      </div>
    </div>
  </div>
</fieldset>
