<br-message [state]="messageType" closable *ngIf="this.submitted">{{ message }}</br-message>

<div appModal center-content="" *ngIf="modalShow" style="flex: 1 1 0">
  <div
    *ngIf="modalShow"
    title="{{ titleModal }}"
    show
    closable
    style="
      display: flex;
      max-width: none;
      width: 80vw;
      min-height: 80%;
      max-height: 80%;
      background-color: white;
      padding: 10px;
      margin: auto;
    "
  >
    <form
      [formGroup]="form"
      (ngSubmit)="onSubmit()"
      style="display: flex; flex-direction: column; overflow: hidden; flex: 1 1 0"
    >
      <div class="d-flex justify-content-between">
        <div>
          <h4>{{ titleModal }} {{ nomeAbrigo }}</h4>
        </div>
        <div class="d-flex align-items-center pr-5">
          <div class="br-switch" role="presentation">
            <input checked="checked" type="checkbox" role="switch" id="ativo" formControlName="ativo" />
            <label for="ativo">Ativo</label>
          </div>
        </div>
      </div>
      <div
        style="display: flex; flex: 1 1 0; flex-direction: column; padding: 10px; overflow-x: hidden; overflow-y: auto"
      >
        <div [style]="typeEdit == 1 || typeEdit == 0 ? '' : 'display: none'">
          <fieldset style="padding: 20px; border: 1px solid lightgray">
            <legend>INFORMAÇÕES BÁSICAS</legend>
            <div class="mb-3">
              <div class="row">
                <div class="col">
                  <!-- nome -->
                  <br-input
                    type="text"
                    id="nome"
                    name="nome"
                    placeholder="Nome"
                    label="Nome do local:"
                    formControlName="nome"
                    (input)="handleInput(['nome'])"
                  ></br-input>
                  <div *ngIf="form.get('nome').invalid && form.get('nome').touched">
                    <div *ngIf="form.get('nome').errors.required">
                      <app-message
                        [control]="form.get('nome')"
                        error="required"
                        message="O campo é obrigatório"
                      ></app-message>
                    </div>
                  </div>
                </div>
                <div formGroupName="endereco" class="col">
                  <br-input
                    type="text"
                    id="cep"
                    name="cep"
                    placeholder="CEP"
                    label="CEP:"
                    formControlName="cep"
                    autocomplete="off"
                    mask="##.###-###"
                    (blur)="buscarEnderecoPorCep(form.get(['endereco', 'cep']).value)"
                  ></br-input>
                  <div *ngIf="form.get(['endereco', 'cep']).invalid && form.get(['endereco', 'cep']).touched">
                    <div *ngIf="form.get(['endereco', 'cep']).errors.required">
                      <app-message
                        [control]="form.get(['endereco', 'cep'])"
                        error="required"
                        message="O campo é obrigatório"
                      ></app-message>
                    </div>
                  </div>
                  <br-message feedback state="danger" show-icon="true" *ngIf="erroCEP"
                    >CEP inválido ou inexistente</br-message
                  >
                </div>
              </div>

              <div class="row">
                <div formGroupName="endereco" class="col">
                  <br-input
                    id="city"
                    name="city"
                    label="Cidade"
                    placeholder="Cidade"
                    formControlName="cidade"
                    (input)="handleInput(['endereco', 'cidade'])"
                  >
                  </br-input>
                  <div *ngIf="form.get(['endereco', 'cidade']).invalid && form.get(['endereco', 'cidade']).touched">
                    <div *ngIf="form.get(['endereco', 'cidade']).errors.required">
                      <app-message
                        [control]="form.get(['endereco', 'cidade'])"
                        error="required"
                        message="O campo é obrigatório"
                      ></app-message>
                    </div>
                  </div>
                </div>

                <div formGroupName="endereco" class="col">
                  <br-input
                    type="text"
                    id="address"
                    name="address"
                    placeholder="Endereço"
                    label="Endereço:"
                    formControlName="rua"
                    (input)="handleInput(['endereco', 'rua'])"
                  ></br-input>
                  <div *ngIf="form.get(['endereco', 'rua']).invalid && form.get(['endereco', 'rua']).touched">
                    <div *ngIf="form.get(['endereco', 'rua']).errors.required">
                      <app-message
                        [control]="form.get(['endereco', 'rua'])"
                        error="required"
                        message="O campo é obrigatório"
                      ></app-message>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <br-input
                    type="text"
                    id="ponto_referencia"
                    name="ponto_referencia"
                    placeholder="Ponto de referência do local"
                    label="Ponto de referência do local:"
                    formControlName="ponto_referencia"
                    (input)="handleInput(['ponto_referencia'])"
                  ></br-input>
                </div>
                <div class="col">
                  <br-input
                    type="text"
                    id="orientacao_acesso"
                    name="orientacao_acesso"
                    placeholder="Acesso por..."
                    label="Há orientação para acesso?"
                    formControlName="orientacao_acesso"
                    (input)="handleInput(['orientacao_acesso'])"
                  ></br-input>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <br-input
                    type="text"
                    id="nome_gestor"
                    name="nome_gestor"
                    placeholder="Nome do gestor do abrigo"
                    label="Nome do gestor do abrigo:"
                    formControlName="nome_gestor"
                    (input)="handleInput(['nome_gestor'])"
                  >
                  </br-input>
                  <div *ngIf="form.get('nome_gestor').invalid && form.get('nome_gestor').touched">
                    <div *ngIf="form.get('nome_gestor').errors.required">
                      <app-message
                        [control]="form.get('nome_gestor')"
                        error="required"
                        message="O campo é obrigatório"
                      ></app-message>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <br-input
                    type="text"
                    id="telefone_gestor"
                    name="telefone_gestor"
                    placeholder="Telefone do gestor do abrigo"
                    label="Telefone do gestor do abrigo:"
                    formControlName="telefone_gestor"
                    (input)="handleInput(['telefone_gestor'])"
                    mask="(##) #####-####"
                  ></br-input>
                  <div *ngIf="form.get('telefone_gestor').invalid && form.get('telefone_gestor').touched">
                    <div *ngIf="form.get('telefone_gestor').errors.required">
                      <app-message
                        [control]="form.get('telefone_gestor')"
                        error="required"
                        message="O campo é obrigatório"
                      ></app-message>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>

          <fieldset style="padding: 20px; border: 1px solid lightgray">
            <legend>EQUIPE DE APOIO</legend>
            <!-- equipe de apoio -->
            <div class="mb-3" formArrayName="equipe_apoio">
              <div class="d-flex justify-content-end align-items-center gap-2">
                <span class="pl-baseh">Adicionar integrante</span>
                <br-button
                  circle
                  id="addVolunteer"
                  primary
                  icon="plus"
                  type="primary"
                  class="small"
                  (click)="addVolunteer()"
                >
                </br-button>
              </div>
              <div
                class="row align-items-end"
                *ngFor="let volunteer of form.get('equipe_apoio').value; let vi = index; trackBy: volunteerTrack"
              >
                <div class="col">
                  <br-input
                    type="text"
                    [id]="'apoio_responsavel' + vi.toString()"
                    placeholder="Nome do integrante da equipe de apoio"
                    label="Nome do integrante da equipe de apoio:"
                    [value]="volunteer.apoio_responsavel"
                    (input)="handleInputVolunteer(vi, 'apoio_responsavel', $event)"
                  ></br-input>
                </div>

                <div class="col">
                  <br-input
                    type="text"
                    [id]="'apoio_telefone' + vi.toString()"
                    label="Telefone do integrante da equipe de apoio:"
                    [value]="volunteer.apoio_telefone"
                    mask="(##) #####-####"
                    placeholder="(##) #####-####"
                    (input)="handleInputVolunteer(vi, 'apoio_telefone', $event)"
                  ></br-input>
                </div>
                <br-button
                  circle
                  id="deleteVolunteer"
                  primary
                  icon="minus"
                  type="primary"
                  class="small mr-4 mb-1"
                  (click)="removeVolunteer(vi)"
                >
                </br-button>
              </div>
            </div>
          </fieldset>

          <fieldset style="padding: 20px; border: 1px solid lightgray">
            <legend>CADACIDADE DO ABRIGO</legend>

            <!-- vagas -->
            <div class="mb-3">
              <div class="row">
                <div formGroupName="vagas_info" class="col">
                  <br-input
                    type="number"
                    min="0"
                    id="vagas_totais"
                    name="vagas_totais"
                    placeholder="Número de vagas totais"
                    label="Número de vagas totais:"
                    formControlName="vagas_totais"
                    (input)="handleInput(['vagas_info', 'vagas_totais'])"
                  ></br-input>
                  <div
                    *ngIf="
                      form.get(['vagas_info', 'vagas_totais']).invalid &&
                      form.get(['vagas_info', 'vagas_totais']).touched
                    "
                  >
                    <div *ngIf="form.get(['vagas_info', 'vagas_totais']).errors.required">
                      <app-message
                        [control]="form.get(['vagas_info', 'vagas_totais'])"
                        error="required"
                        message="O campo é obrigatório"
                      ></app-message>
                    </div>
                  </div>
                </div>
                <div formGroupName="vagas_info" class="col">
                  <br-input
                    type="number"
                    min="0"
                    id="vagas_ocupadas"
                    name="vagas_ocupadas"
                    placeholder="Número de vagas ocupadas"
                    label="Número de vagas ocupadas:"
                    formControlName="vagas_ocupadas"
                    (input)="handleInput(['vagas_info', 'vagas_ocupadas'])"
                  >
                  </br-input>
                  <div
                    *ngIf="
                      form.get(['vagas_info', 'vagas_ocupadas']).invalid &&
                      form.get(['vagas_info', 'vagas_ocupadas']).touched
                    "
                  >
                    <div *ngIf="form.get(['vagas_info', 'vagas_ocupadas']).errors.required">
                      <app-message
                        [control]="form.get(['vagas_info', 'vagas_ocupadas'])"
                        error="required"
                        message="O campo é obrigatório"
                      ></app-message>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- vagas exclusivas -->
            <div formGroupName="vagas_info" class="mb-3">
              <div class="row">
                <div class="col d-flex justify-content-start align-items-center switch-col">
                  <div class="br-switch" role="presentation">
                    <input
                      checked="checked"
                      type="checkbox"
                      role="switch"
                      id="vaga_mulher"
                      name="vaga_mulher"
                      formControlName="vaga_mulher"
                      (change)="
                        changeSwitch($event, [
                          ['vagas_info', 'vaga_mulher_quantidade'],
                          ['vagas_info', 'vaga_mulher_quantidade_ocupadas']
                        ])
                      "
                    />
                    <label for="vaga_mulher">Tem vagas exclusivas mulheres e crianças?</label>
                  </div>
                </div>
                <div class="col">
                  <br-input
                    type="number"
                    min="0"
                    id="vaga_mulher_quantidade"
                    name="vaga_mulher_quantidade"
                    placeholder="Número de vagas mulheres/crianças"
                    label="Número de vagas mulheres/crianças:"
                    formControlName="vaga_mulher_quantidade"
                    (input)="handleInput(['vagas_info', 'vaga_mulher_quantidade'])"
                  >
                  </br-input>
                  <div *ngIf="form.get(['vagas_info', 'vaga_mulher_quantidade']).invalid">
                    <app-message
                      [control]="form.get(['vagas_info', 'vaga_mulher_quantidade'])"
                      error="required"
                      message="O campo é obrigatório"
                    ></app-message>
                  </div>
                </div>
                <div class="col">
                  <br-input
                    type="number"
                    min="0"
                    id="vaga_mulher_quantidade_ocupadas"
                    name="vaga_mulher_quantidade_ocupadas"
                    placeholder="Número de vagas mulheres/crianças ocupadas"
                    label="Número de vagas mulheres/crianças ocupadas:"
                    formControlName="vaga_mulher_quantidade_ocupadas"
                    (input)="handleInput(['vagas_info', 'vaga_mulher_quantidade_ocupadas'])"
                  >
                  </br-input>
                  <div *ngIf="form.get(['vagas_info', 'vaga_mulher_quantidade_ocupadas']).invalid">
                    <app-message
                      [control]="form.get(['vagas_info', 'vaga_mulher_quantidade_ocupadas'])"
                      error="required"
                      message="O campo é obrigatório"
                    ></app-message>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col d-flex justify-content-start align-items-center switch-col">
                  <div class="br-switch" role="presentation">
                    <input
                      checked="checked"
                      type="checkbox"
                      role="switch"
                      id="vaga_pets"
                      name="vaga_pets"
                      formControlName="vaga_pets"
                      (change)="
                        changeSwitch($event, [
                          ['vagas_info', 'vaga_pets_quantidade'],
                          ['vagas_info', 'vaga_pets_quantidade_ocupadas']
                        ])
                      "
                    />
                    <label for="vaga_pets">Recebe pets?</label>
                  </div>
                </div>
                <div class="col">
                  <br-input
                    type="number"
                    min="0"
                    id="vaga_pets_quantidade"
                    name="vaga_pets_quantidade"
                    placeholder="Número de vagas pets"
                    label="Número de vagas pets:"
                    formControlName="vaga_pets_quantidade"
                    (input)="handleInput(['vagas_info', 'vaga_pets_quantidade'])"
                  >
                  </br-input>
                  <div *ngIf="form.get(['vagas_info', 'vaga_pets_quantidade']).invalid">
                    <app-message
                      [control]="form.get(['vagas_info', 'vaga_pets_quantidade'])"
                      error="required"
                      message="O campo é obrigatório"
                    ></app-message>
                  </div>
                </div>
                <div class="col">
                  <br-input
                    type="number"
                    min="0"
                    id="vaga_pets_quantidade_ocupadas"
                    name="vaga_pets_quantidade_ocupadas"
                    placeholder="Número de vagas ocupadas para pets"
                    label="Número de vagas ocupadas para pets:"
                    formControlName="vaga_pets_quantidade_ocupadas"
                    (input)="handleInput(['vagas_info', 'vaga_pets_quantidade_ocupadas'])"
                  >
                  </br-input>
                  <div *ngIf="form.get(['vagas_info', 'vaga_pets_quantidade_ocupadas']).invalid">
                    <app-message
                      [control]="form.get(['vagas_info', 'vaga_pets_quantidade_ocupadas'])"
                      error="required"
                      message="O campo é obrigatório"
                    ></app-message>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>

          <fieldset style="padding: 20px; border: 1px solid lightgray">
            <legend>CLASSIFICAÇÃO DO ABRIGO</legend>

            <!-- tipo abrigo -->
            <div class="mb-3">
              <div class="row">
                <div class="col">
                  <br-select
                    multiple
                    label="Tipo de local"
                    placeholder="Tipo de local"
                    [options]="localOptions"
                    (change)="changeSelect($event, ['local_info', 'tipo_local'])"
                  >
                  </br-select>
                </div>
                <div class="col">
                  <br-select
                    label="Classificação do local"
                    placeholder="Classificação do local"
                    [options]="classificacaoOptions"
                    (change)="changeSelect($event, ['local_info', 'classificacao_local'])"
                  >
                  </br-select>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div *ngIf="form.get(['local_info', 'tipo_local']).value?.indexOf('Abrigo') >= 0" class="mt-3">
                    <br-select
                      multiple
                      label="Tipo de abrigo"
                      placeholder="Tipo de abrigo"
                      [options]="abrigoOptions"
                      (change)="changeSelect($event, ['local_info', 'tipo_abrigo'])"
                    >
                    </br-select>
                  </div>
                </div>
                <div class="col">
                  <div
                    formGroupName="local_info"
                    *ngIf="form.get(['local_info', 'classificacao_local']).value?.indexOf('Outro') >= 0"
                    class="mt-3"
                  >
                    <br-input
                      type="text"
                      id="classificacao_local_outro"
                      name="classificacao_local_outro"
                      placeholder="Digite outra classificação do local"
                      label="Digite outra classificação do local:"
                      formControlName="classificacao_local_outro"
                      (input)="handleInput(['local_info', 'classificacao_local_outro'])"
                    ></br-input>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>

          <fieldset style="padding: 20px; border: 1px solid lightgray">
            <legend>INFRAESTRUTURA</legend>

            <!-- infra do abrigo -->
            <div formGroupName="infra_info" class="mb-3">
              <div class="row">
                <div class="col">
                  <br-input
                    [type]="'text'"
                    id="metragem"
                    name="metragem"
                    placeholder="Qual a metragem do ambiente"
                    label="Qual a metragem do ambiente"
                    formControlName="metragem"
                    (input)="handleInput(['infra_info', 'metragem'])"
                  >
                  </br-input>
                  <div
                    *ngIf="form.get(['infra_info', 'metragem']).invalid && form.get(['infra_info', 'metragem']).touched"
                  >
                    <div *ngIf="form.get('metragem').errors.required">
                      <app-message
                        [control]="form.get('metragem')"
                        error="required"
                        message="O campo é obrigatório"
                      ></app-message>
                    </div>
                  </div>
                </div>

                <div class="col">
                  <br-input
                    type="number"
                    min="0"
                    id="banheiros"
                    name="banheiros"
                    placeholder="Quantidade de banheiros"
                    label="Quantidade de banheiros:"
                    formControlName="banheiros"
                    (input)="handleInput(['infra_info', 'banheiros'])"
                  >
                  </br-input>
                  <div *ngIf="form.get(['infra_info', 'banheiros']).invalid && form.get('banheiros').touched">
                    <div *ngIf="form.get(['infra_info', 'banheiros']).errors.required">
                      <app-message
                        [control]="form.get(['infra_info', 'banheiros'])"
                        error="required"
                        message="O campo é obrigatório"
                      ></app-message>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <br-input
                    type="number"
                    min="0"
                    id="chuveiros"
                    name="chuveiros"
                    placeholder="Quantidade de chuveiros"
                    label="Quantidade de chuveiros"
                    formControlName="chuveiros"
                    (input)="handleInput(['infra_info', 'chuveiros'])"
                  >
                  </br-input>
                  <div
                    *ngIf="
                      form.get(['infra_info', 'chuveiros']).invalid && form.get(['infra_info', 'chuveiros']).touched
                    "
                  >
                    <div *ngIf="form.get(['infra_info', 'chuveiros']).errors.required">
                      <app-message
                        [control]="form.get(['infra_info', 'chuveiros'])"
                        error="required"
                        message="O campo é obrigatório"
                      ></app-message>
                    </div>
                  </div>
                </div>

                <div class="col">
                  <br-input
                    type="number"
                    min="0"
                    id="colchoes"
                    name="colchoes"
                    placeholder="Quantidade de colchões"
                    label="Quantidade de colchoes"
                    formControlName="colchoes"
                    (input)="handleInput(['infra_info', 'colchoes'])"
                  >
                  </br-input>
                  <div
                    *ngIf="form.get(['infra_info', 'colchoes']).invalid && form.get(['infra_info', 'colchoes']).touched"
                  >
                    <div *ngIf="form.get(['infra_info', 'colchoes']).errors.required">
                      <app-message
                        [control]="form.get(['infra_info', 'colchoes'])"
                        error="required"
                        message="O campo é obrigatório"
                      ></app-message>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col d-flex justify-content-start align-items-center switch-col">
                  <div class="br-switch" role="presentation">
                    <input
                      checked="checked"
                      type="checkbox"
                      role="switch"
                      id="acessibilidade"
                      name="acessibilidade"
                      formControlName="acessibilidade"
                    />
                    <label for="acessibilidade">Há acessibilidade?</label>
                  </div>
                </div>
                <div class="col">
                  <br-input
                    [type]="'text'"
                    id="obs_acessibilidade"
                    name="obs_acessibilidade"
                    placeholder="Quais acessibilidades"
                    label="Quais acessibilidades?"
                    formControlName="obs_acessibilidade"
                    (input)="handleInput(['infra_info', 'obs_acessibilidade'])"
                  >
                  </br-input>
                </div>
              </div>

              <div class="row">
                <div class="col d-flex justify-content-start align-items-center switch-col">
                  <div class="br-switch" role="presentation">
                    <input
                      checked="checked"
                      type="checkbox"
                      role="switch"
                      id="climatizacao"
                      name="climatizacao"
                      formControlName="climatizacao"
                    />
                    <label for="climatizacao">Há infraestrutura de climatização?</label>
                  </div>
                </div>
                <div class="col">
                  <br-input
                    [type]="'text'"
                    id="obs_climatizacao"
                    name="obs_climatizacao"
                    placeholder="Quais e quantos aparelhos de climatização"
                    label="Quais e quantos aparelhos de climatização?"
                    formControlName="obs_climatizacao"
                    (input)="handleInput(['infra_info', 'obs_climatizacao'])"
                  >
                  </br-input>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col d-flex justify-content-start align-items-center switch-col">
                  <div class="br-switch" role="presentation">
                    <input
                      checked="checked"
                      type="checkbox"
                      role="switch"
                      id="fornecimento_agua"
                      name="fornecimento_agua"
                      formControlName="fornecimento_agua"
                    />
                    <label for="fornecimento_agua">Há fornecimento de água?</label>
                  </div>
                </div>

                <div class="col d-flex justify-content-start align-items-center switch-col">
                  <div class="br-switch" role="presentation">
                    <input
                      checked="checked"
                      type="checkbox"
                      role="switch"
                      id="enfermaria"
                      name="enfermaria"
                      formControlName="enfermaria"
                    />
                    <label for="enfermaria">Há enfermaria?</label>
                  </div>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col d-flex justify-content-start align-items-center switch-col">
                  <div class="br-switch" role="presentation">
                    <input
                      checked="checked"
                      type="checkbox"
                      role="switch"
                      id="fornecimento_eletrica"
                      name="fornecimento_eletrica"
                      formControlName="fornecimento_eletrica"
                    />
                    <label for="fornecimento_eletrica">Há fornecimento de energia?</label>
                  </div>
                </div>

                <div class="col d-flex justify-content-start align-items-center switch-col">
                  <div class="br-switch" role="presentation">
                    <input
                      checked="checked"
                      type="checkbox"
                      role="switch"
                      id="gerador"
                      name="gerador"
                      formControlName="gerador"
                    />
                    <label for="gerador">Há gerador</label>
                  </div>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col d-flex justify-content-start align-items-center switch-col">
                  <div class="br-switch" role="presentation">
                    <input
                      checked="checked"
                      type="checkbox"
                      role="switch"
                      id="cozinha"
                      name="cozinha"
                      formControlName="cozinha"
                    />
                    <label for="cozinha">Tem cozinha?</label>
                  </div>
                </div>
                <div class="col"></div>
              </div>
            </div>

            <!-- infra de atendimento -->
            <div formGroupName="atendimento_info" class="row mt-3">
              <div class="col">
                <br-input
                  type="number"
                  min="0"
                  id="qtd_voluntarios"
                  name="qtd_voluntarios"
                  placeholder="Quantidade de voluntários"
                  label="Quantidade de voluntários?"
                  formControlName="qtd_voluntarios"
                  (input)="handleInput(['atendimento_info', 'qtd_voluntarios'])"
                >
                </br-input>
              </div>
              <div class="col"></div>
            </div>

            <div formGroupName="atendimento_info" class="row mt-3">
              <div class="col d-flex justify-content-start align-items-center switch-col">
                <div class="br-switch" role="presentation">
                  <input
                    checked="checked"
                    type="checkbox"
                    role="switch"
                    id="atendimento"
                    name="atendimento"
                    formControlName="atendimento"
                  />
                  <label for="atendimento">Há atendimento de saúde?</label>
                </div>
              </div>

              <div class="col">
                <br-select
                  label="Quais atendimentos de saúde"
                  placeholder="Quais atendimentos de saúde?"
                  [options]="atendimentoOptions"
                  multiple
                  (change)="changeSelect($event, ['atendimento_info', 'apoio_medico'])"
                >
                </br-select>
              </div>
            </div>

            <div formGroupName="atendimento_info" class="row mt-3">
              <div class="col d-flex justify-content-start align-items-center switch-col">
                <div class="br-switch" role="presentation">
                  <input
                    checked="checked"
                    type="checkbox"
                    role="switch"
                    id="medicamentos"
                    name="medicamentos"
                    formControlName="medicamentos"
                  />
                  <label for="medicamentos">Há fornecimento de medicamentos?</label>
                </div>
              </div>

              <div class="col">
                <br-input
                  [type]="'text'"
                  id="apoio_medicamentos"
                  name="apoio_medicamentos"
                  placeholder="Quais medicamentos"
                  label="Quais medicamentos?"
                  formControlName="apoio_medicamentos"
                  (input)="handleInput(['atendimento_info', 'apoio_medicamentos'])"
                >
                </br-input>
              </div>
            </div>
          </fieldset>

          <div class="row">
            <div class="col">
              <br-input
                [type]="'text'"
                id="observations"
                name="observations"
                placeholder="Observações"
                label="Observações:"
                formControlName="observations"
                (input)="handleInput(['observations'])"
              >
              </br-input>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-3 d-flex justify-content-md-end stick-on-top">
        <br-button
          id="enviar"
          label="Enviar Formulário"
          type="primary"
          class="mt-3 mr-1"
          (click)="onSubmit()"
          tooltip-text="Salvar informações"
          tooltip-place="top"
          tooltip-type="info"
        >
        </br-button>
        <br-button
          id="fechar"
          label="Fechar Formulário"
          type="secondary"
          class="mt-3 mr-1"
          (click)="closeModal()"
          tooltip-text="Fechar o formulário"
          tooltip-place="top"
          tooltip-type="info"
        >
        </br-button>
      </div>
    </form>
  </div>
</div>

<div class="row row-action">
  <div class="col">
    <h1>Lista de abrigos</h1>
  </div>
  <div class="col items-col-left" appAuthorization [level]="authorizationService.AUTHORIZATION_LEVEL_SUPORTE">
    <br-button
      id="addAbrigo"
      label="Cadastrar Abrigo"
      type="primary"
      class="mt-3 mr-1"
      (click)="openModalAdd()"
      icon="plus"
      title="Cadastrar um novo abrigo"
    ></br-button>
    <br-button
      *ngIf="permission"
      id="exportTable"
      label="Exportar"
      icon="table"
      type="primary"
      class="mt-3 mr-1"
      title="Exportar dados em excel"
      (click)="exportToExcel()"
    ></br-button>
  </div>
</div>

<div class="row">
  <div class="col">
    <div class="card-deck">
      <div class="card">
        <div class="card-body">
          <h6 class="card-title">Total de Abrigos</h6>
          <p class="card-text">{{ (listaFiltrada | async).length }}</p>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <h6 class="card-title">Total de Vagas</h6>
          <p class="card-text">{{ calcularTotalVagas(listaFiltrada | async) }}</p>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <h6 class="card-title">Total de Vagas Ocupadas</h6>
          <p class="card-text">{{ calcularTotalVagasOcupadas(listaFiltrada | async) }}</p>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <h6 class="card-title">Total de Vagas Disponíveis</h6>
          <p class="card-text">{{ calcularVagasDisponiveis(listaFiltrada | async) }}</p>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <h6 class="card-title">Porcentagem de Vagas Ocupadas</h6>
          <p class="card-text">{{ calcularPorcentagemVagasOcupadas(listaFiltrada | async) }}%</p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col">
    <br-select
      multiple
      class="mr-1"
      style="z-index: 2"
      [options]="citiesOptionsSearch"
      label="Cidades:"
      (change)="handleChangeCity($event)"
    >
    </br-select>
  </div>
  <div class="col">
    <br-select
      multiple
      class="mr-1"
      style="z-index: 2"
      [options]="tipoAbrigoOptionsSearch"
      label="Tipo de Abrigo:"
      (change)="handleChangeTipoAbrigo($event)"
    >
    </br-select>
  </div>
  <div class="col">
    <br-input
      type="text"
      placeholder="Pesquisar por abrigo ou cidade"
      label="Busca:"
      [(ngModel)]="searchText"
      (input)="search()"
    ></br-input>
  </div>
  <div class="col">
    <div class="d-flex align-items-end">
      <br-select
        class="mr-1"
        style="z-index: 2"
        [options]="orderByOptions"
        label="Ordernar por:"
        (change)="handleOrderBy($event)"
      >
      </br-select>
      <br-button
        *ngIf="sortOrder === 'asc'"
        icon="sort-amount-up"
        circle
        type="secondary"
        (click)="handleSortOrder('desc')"
      ></br-button>
      <br-button
        *ngIf="sortOrder === 'desc'"
        icon="sort-amount-down"
        circle
        type="secondary"
        (click)="handleSortOrder('asc')"
      ></br-button>
    </div>
  </div>
</div>

<div class="row">
  <div class="col">
    <div class="table-wrapper">
      <table class="br-table">
        <thead>
          <tr>
            <th>Ação</th>
            <th>Nome</th>
            <th>Tipo Abrigo</th>
            <th>Tipo Local</th>
            <th>Cidade</th>
            <th>Endereço</th>
            <th>Gestor da Operação</th>

            <th width="180px">Vagas para Pessoas</th>
            <th>Vagas para Pets</th>

            <!-- <th>Reservas</th> -->
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let abrigo of currentPageItems">
            <td>
              <div class="actions-button-flex">
                <br-button
                  type="primary"
                  icon="edit"
                  circle
                  class="small mr-1"
                  title="Editar abrigo"
                  (click)="openEditModalData(abrigo)"
                  appAuthorization
                  [level]="authorizationService.AUTHORIZATION_LEVEL_CONTROLADOR"
                ></br-button>
              </div>
            </td>
            <td [ngClass]="{ disabled: !abrigo.ativo }">{{ abrigo.nome }}</td>
            <td [ngClass]="{ disabled: !abrigo.ativo }">{{ abrigo.local_info["tipo_abrigo_grid"] }}</td>
            <td [ngClass]="{ disabled: !abrigo.ativo }">{{ abrigo.local_info["tipo_local_grid"] }}</td>
            <td [ngClass]="{ disabled: !abrigo.ativo }">{{ abrigo.endereco.cidade }}</td>
            <td [ngClass]="{ disabled: !abrigo.ativo }">{{ abrigo.endereco.rua }}</td>
            <td [ngClass]="{ disabled: !abrigo.ativo }">
              <table class="br-table">
                <tr>
                  <td>{{ abrigo.nome_gestor }}</td>
                </tr>
                <tr>
                  <td>{{ abrigo.telefone_gestor }}</td>
                </tr>
              </table>
            </td>

            <td [ngClass]="{ disabled: !abrigo.ativo }">
              <table class="br-table">
                <tr>
                  <td>Geral: {{ abrigo.vagas_info.vagas_ocupadas }} de {{ abrigo.vagas_info.vagas_totais }}</td>
                </tr>
                <tr *ngIf="abrigo.vagas_info.vaga_mulher">
                  <td *ngIf="abrigo.vagas_info.vaga_mulher">
                    Mulheres: {{ abrigo.vagas_info.vaga_mulher_quantidade_ocupadas }} de
                    {{ abrigo.vagas_info.vaga_mulher_quantidade }}
                  </td>
                </tr>
              </table>
            </td>

            <td *ngIf="abrigo.vagas_info.vaga_pets">
              {{ abrigo.vagas_info.vaga_pets_quantidade_ocupadas }} de
              {{ abrigo.vagas_info.vaga_pets_quantidade }}
            </td>
            <td *ngIf="!abrigo.vagas_info.vaga_pets"></td>

            <!-- <td [ngClass]="{ disabled: !abrigo.ativo }">
              <table class="br-table" *ngIf="abrigo.vagas_info.vaga_mulher || abrigo.vagas_info.vaga_pets">
                <tr *ngIf="abrigo.vagas_info.vaga_mulher">
                  <td>Mulheres e Crianças: {{ abrigo.vagas_info.vaga_mulher_quantidade }}</td>
                </tr>
                <tr *ngIf="abrigo.vagas_info.vaga_pets">
                  <td>Pets: {{ abrigo.vagas_info.vaga_pets_quantidade }}</td>
                </tr>
              </table>
            </td> -->
          </tr>
        </tbody>
      </table>
    </div>

    <app-pagination
      label="abrigos"
      [itemsPerPage]="itemsPerPage"
      [currentPage]="currentPage"
      [totalPages]="totalPages"
      [totalItems]="totalItems"
      (pageChange)="handlePageChange($event)"
      (itemsPerPageChange)="handleItemsPerPageChange($event)"
    >
    </app-pagination>
  </div>
</div>
