import { Injectable } from '@angular/core'
import { Abrigo, AbrigoService } from 'src/app/core/services/repository/abrigo.service'
import AbrigoDTO from '../DTOs/AbrigoDTO'

interface InputPayload {
  formValues: AbrigoDTO
  abrigoId?: string
  update_in?: Date
}

@Injectable({
  providedIn: 'root',
})
export class CreateOrUpdateAbrigoUseCase {
  constructor(private readonly abrigoService: AbrigoService) {}

  firstLetterUppercase(nome: string) {
    const nomeUppercase = nome.trim().split(' ')

    for (let i = 0; i < nomeUppercase.length; i++) {
      nomeUppercase[i] = nomeUppercase[i][0]?.toUpperCase() + nomeUppercase[i]?.substring(1)
    }

    return nomeUppercase.join(' ')
  }

  converterParaNumero(valor: any): number {
    const numero = parseFloat(valor)

    return isNaN(numero) ? 0 : numero
  }

  async run({ formValues, abrigoId }: InputPayload) {
    const payload: Abrigo = {
      tipo: formValues.local_info.tipo_local ?? null,
      rede_prefeitura: formValues.local_info.rede_prefeitura ?? false,
      classificacao_local: formValues.local_info.classificacao_local ?? null,
      classificacao_local_outro: formValues.local_info.classificacao_local_outro ?? null,
      orientacao_acesso: formValues.orientacao_acesso || null,
      nome: formValues.nome && typeof formValues.nome == 'string' ? this.firstLetterUppercase(formValues.nome) : null,
      abrigopm: formValues.local_info.tipo_abrigo ?? null,
      nome_contato: formValues.nome_gestor && typeof formValues.nome == 'string' ? this.firstLetterUppercase(formValues.nome_gestor) : null,
      telefone: formValues.telefone_gestor ?? null,
      equipe_apoio: formValues.equipe_apoio ?? null,
      nome_gestor_suprimentos: formValues.nome_gestor_suprimentos ?? null,
      telefone_gestor_suprimentos: formValues.telefone_gestor_suprimentos ?? null,

      vagas: this.converterParaNumero(formValues.vagas_info.vagas_totais) ?? null,
      vagas_ocupadas: this.converterParaNumero(formValues.vagas_info.vagas_ocupadas) ?? null,
      vagas_disponiveis: this.converterParaNumero(formValues.vagas_info.vagas_disponiveis) ?? null,
      vagas_mulher: this.converterParaNumero(formValues.vagas_info.vaga_mulher_quantidade) ?? null,
      vagas_mulher_ocupadas: this.converterParaNumero(formValues.vagas_info.vaga_mulher_quantidade_ocupadas) ?? null,
      vagas_mulheres_criancas: this.converterParaNumero(formValues.vagas_info.vaga_mulheres_criancas_quantidade) ?? null,
      vagas_mulheres_criancas_ocupadas: this.converterParaNumero(formValues.vagas_info.vaga_mulheres_criancas_quantidade_ocupadas) ?? null,
      vagas_gestantes: this.converterParaNumero(formValues.vagas_info.vaga_gestantes_quantidade) ?? null,
      vagas_gestantes_ocupadas: this.converterParaNumero(formValues.vagas_info.vaga_gestantes_quantidade_ocupadas) ?? null,
      vagas_moradores_rua: this.converterParaNumero(formValues.vagas_info.vaga_moradores_rua_quantidade) ?? null,
      vagas_moradores_rua_ocupadas: this.converterParaNumero(formValues.vagas_info.vaga_moradores_rua_quantidade_ocupadas) ?? null,
      vagas_adultos: this.converterParaNumero(formValues.vagas_info.vaga_adultos_quantidade) ?? null,
      vagas_adultos_ocupadas: this.converterParaNumero(formValues.vagas_info.vaga_adultos_quantidade_ocupadas) ?? null,
      vagas_idosos: this.converterParaNumero(formValues.vagas_info.vaga_idosos_quantidade) ?? null,
      vagas_idosos_ocupadas: this.converterParaNumero(formValues.vagas_info.vaga_idosos_quantidade_ocupadas) ?? null,
      vagas_autistas: this.converterParaNumero(formValues.vagas_info.vaga_autistas_quantidade) ?? null,
      vagas_autistas_ocupadas: this.converterParaNumero(formValues.vagas_info.vaga_autistas_quantidade_ocupadas) ?? null,
      vagas_pcd: this.converterParaNumero(formValues.vagas_info.vaga_pcd_quantidade) ?? null,
      vagas_pcd_ocupadas: this.converterParaNumero(formValues.vagas_info.vaga_pcd_quantidade_ocupadas) ?? null,
      vagas_pet: this.converterParaNumero(formValues.vagas_info.vaga_pets_quantidade) ?? null,
      vagas_pet_ocupadas: this.converterParaNumero(formValues.vagas_info.vaga_pets_quantidade_ocupadas) ?? null,
      vagas_pet_quais: formValues.vagas_info.vaga_pets_quais ?? null,

      banheiros: formValues.infra_info.banheiros ?? null,
      colchoes: formValues.infra_info.colchoes ?? null,
      cozinha: formValues.infra_info.cozinha ?? null,
      cep: formValues.endereco.cep ?? null,
      city: formValues.endereco.cidade ?? null,
      address: formValues.endereco.rua ?? null,
      ponto_referencia: formValues.ponto_referencia ?? null,
      atendimento_info: formValues.atendimento_info ?? null,
      informacao_adicional: formValues.informacao_adicional ?? null,
      infra_info: formValues.infra_info ?? null,
      itensUteis: formValues.itensUteis ?? null,
      observations: formValues.observations ?? null,
      ativo: formValues.ativo,
      update_in: formValues.update_in ?? null,
    }

    if (abrigoId) payload.id = abrigoId
    return this.abrigoService.addOrUpdate(payload)
  }
}
