export const NecessidadesEspeciaisOptionsDTO = [
  {
    value: 'Saúde mental',
    label: 'Saúde mental',
    selected: false,
  },
  {
    value: 'Usuário de substâncias químicas (álcool ou drogas)',
    label: 'Usuário de substâncias químicas (álcool ou drogas)',
    selected: false,
  },
  {
    value: 'Idosos com dependência de cuidador',
    label: 'Idosos com dependência de cuidador',
    selected: false,
  },
]
