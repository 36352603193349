import { Component, OnInit } from '@angular/core'
import { FormArray, FormBuilder, FormGroup } from '@angular/forms'
import { BehaviorSubject } from 'rxjs'
import { AuthorizationService } from 'src/app/core/services/authorization.service'
import { Abrigo, AbrigoService } from 'src/app/core/services/repository/abrigo.service'
import { removeAccents } from 'src/app/shared/utils/removeAccents'
import { CreateOrUpdateAbrigoUseCase } from './useCases/createOrUpdateAbrigo.useCase'
import { GetAbrigoUseCase } from './useCases/getAbrigos.useCase'

import { HttpClient } from '@angular/common/http'

import { ExcelService } from 'src/app/core/services/excel.service'
import { AuthService } from 'src/app/core/services/repository/firebase/auth.service'
import { LoaderService } from 'src/app/shared/components/loader/loader.service'
import AbrigoDTO from './DTOs/AbrigoDTO'
import { AbrigoOptionsDTO } from './DTOs/AbrigoOptionsDTO'
import { AtendimentoOptiosnDTO } from './DTOs/AtendimentoOptionsDTO'
import { ClassificacaoOptionsDTO } from './DTOs/ClassificacaoOptionsDTO'
import { LocalOptionsDTO } from './DTOs/LocalOptionsDTO'
import { OrderByOptionsDTO } from './DTOs/OrderByOptionsDTO'
import { StatusOptionsDTO } from './DTOs/StatusOptionsDTO'

@Component({
  selector: 'app-abrigos',
  templateUrl: './abrigos.component.html',
  styleUrls: ['./abrigos.component.scss'],
})
export class AbrigosComponent implements OnInit {
  static readonly CADASTRO_GERAL = 0
  static readonly CADASTRO_DADOS_ABRIGO = 1
  static readonly CADASTRO_NECESSIDADE_ABRIGO = 2

  public message: string
  public categories: any[]
  public messageType: string
  public submitted: boolean = false
  public selectedCategorie: string
  public form: FormGroup
  public formUteis: FormGroup
  public abrigos: AbrigoDTO[] = []
  public abrigos2: Abrigo[] = []
  public editAbrigo: AbrigoDTO
  public editIndex: number = null
  public idAbrigo: string = ''
  public nomeAbrigo: string = ''
  public modalShow: boolean = false
  public titleModal: string = ''
  public typeEdit: number = AbrigosComponent.CADASTRO_GERAL
  public searchText: string = ''
  public sortOrder: 'asc' | 'desc' = 'asc'
  public listaFiltrada: BehaviorSubject<AbrigoDTO[]> = new BehaviorSubject([])

  public itensUteis: any

  public usuario = null
  public itensUtilOpened: boolean = false
  public erroCEP: boolean = false

  public permission: boolean = false
  public statusOptions = StatusOptionsDTO
  public localOptions = LocalOptionsDTO
  public classificacaoOptions = ClassificacaoOptionsDTO
  public abrigoOptions = AbrigoOptionsDTO
  public atendimentoOptions = AtendimentoOptiosnDTO
  public volunteersArray: FormArray

  public orderByOptions = OrderByOptionsDTO
  public tipoAbrigoOptionsSearch = AbrigoOptionsDTO
  public citiesOptionsSearch: any

  public currentPage: number = 1
  public itemsPerPage: number = 9
  public totalPages: number = 0
  public totalItems: number = 0

  constructor(
    private fb: FormBuilder,
    private _http: HttpClient,
    public authorizationService: AuthorizationService,
    private excelService: ExcelService,
    private _authService: AuthService,
    private loaderService: LoaderService,
    private abrigoService: AbrigoService,
    private getAbrigoUseCase: GetAbrigoUseCase,
    private createOrUpdateAbrigoUseCase: CreateOrUpdateAbrigoUseCase
  ) {
    this.usuario = this._authService.currentUser

    this.listaFiltrada.subscribe((data) => this._onListUpdated(data))
  }

  async ngOnInit() {
    this.loaderService.show()

    this.categories = [
      { value: 'alimentos', viewValue: 'Alimentos' },
      { value: 'alojamento', viewValue: 'Alojamento' },
      { value: 'higiene', viewValue: 'Higiene' },
      { value: 'limpeza', viewValue: 'Limpeza' },
      { value: 'pet', viewValue: 'Pet' },
      { value: 'socorros', viewValue: 'Primeiros Socorros' },
      { value: 'vestuario', viewValue: 'Vestuario' },
    ]

    await this.fetchAbrigos()

    // TODO: Implementar regras, faze provisória
    this.permission =
      this.usuario.email === 'liana.rigon@procempa.com.br' || !!this.authorizationService.AUTHORIZATION_LEVEL_SUPORTE

    this.loaderService.hide()
  }

  async orderAbrigosBy(text: string, sort: 'asc' | 'desc') {}

  async handleSortOrder(sort: 'asc' | 'desc') {
    this.sortOrder = sort
    this.search()
  }

  selectedOrderBy: string
  handleOrderBy(ev: any) {
    this.sortOrder = 'asc'
    this.selectedOrderBy = ev.detail[0].value
    this.search()
  }

  loadTableData(abrigos) {
    for (let abrigo of abrigos) {
      abrigo.local_info['tipo_local_grid'] = Array.isArray(abrigo.local_info.tipo_local)
        ? abrigo.local_info.tipo_local.join(', ')
        : abrigo.local_info.tipo_local
      abrigo.local_info['tipo_abrigo_grid'] = Array.isArray(abrigo.local_info.tipo_abrigo)
        ? abrigo.local_info.tipo_abrigo.join(', ')
        : abrigo.local_info.tipo_abrigo
    }
  }

  async fetchAbrigos() {
    this.submitted = true
    this.abrigos = await this.getAbrigoUseCase.run()
    this.loadTableData(this.abrigos)
    this.abrigos2 = await this.getAbrigoUseCase.run2()
    this.citiesOptionsSearch = this.abrigoService.getCityToFilter(this.abrigos2, false)
    this.search()
    this.submitted = false
    // this.listaFiltrada.next(this.abrigos);
  }

  converterParaNumero(valor: any): number {
    const numero = parseFloat(valor)

    return isNaN(numero) ? 0 : numero
  }

  calcularTotalVagas(abrigos: any[]): number {
    return abrigos.reduce((total, abrigo) => total + this.converterParaNumero(abrigo.vagas_info.vagas_totais), 0)
  }

  calcularTotalVagasOcupadas(abrigos: any[]): number {
    return abrigos.reduce((total, abrigo) => total + this.converterParaNumero(abrigo.vagas_info.vagas_ocupadas), 0)
  }

  calcularVagasDisponiveis(abrigos: any[]): number {
    return abrigos.reduce(
      (total, abrigo) =>
        total +
        (this.converterParaNumero(abrigo.vagas_info.vagas_totais) -
          this.converterParaNumero(abrigo.vagas_info.vagas_ocupadas)),
      0
    )
  }

  calcularPorcentagemVagasOcupadas(abrigos: any[]): string {
    const totalVagas = this.calcularTotalVagas(abrigos)
    const totalVagasOcupadas = this.calcularTotalVagasOcupadas(abrigos)

    if (totalVagas === 0) {
      return '0'
    }

    return ((totalVagasOcupadas / totalVagas) * 100).toFixed(2)
  }

  updateAbrigo(idAbrigo, abrigoSaved) {
    this.abrigos.find((abrigo) => abrigo.id == idAbrigo).itensUteis = abrigoSaved.itensUteis
  }

  handleEditAbrigo(abrigo: AbrigoDTO) {
    this.editAbrigo = abrigo

    if (!abrigo) return

    abrigo.local_info['tipo_local_grid'] = Array.isArray(abrigo.local_info.tipo_local)
      ? abrigo.local_info.tipo_local.join(', ')
      : abrigo.local_info.tipo_local

    abrigo.local_info['tipo_abrigo_grid'] = Array.isArray(abrigo.local_info.tipo_abrigo)
      ? abrigo.local_info.tipo_abrigo.join(', ')
      : abrigo.local_info.tipo_abrigo
  }

  updateIn(value: any): any {
    if (typeof value === 'string') {
      const date = new Date(value)

      if (!isNaN(date.getTime())) {
        return date.toLocaleString('pt-BR')
      }

      return 'Data inválida'
    }

    return value
  }

  closeModal() {
    this.modalShow = false
  }

  openModalAdd() {
    this.handleEditAbrigo(null)
    this.modalShow = true
    this.typeEdit = AbrigosComponent.CADASTRO_GERAL
    this.titleModal = 'Cadastrar abrigo'
  }

  openEditModalData(abrigo: AbrigoDTO) {
    this.handleEditAbrigo(abrigo)

    this.modalShow = true
    this.typeEdit = AbrigosComponent.CADASTRO_DADOS_ABRIGO
    this.titleModal = 'Editar dados do abrigo'
  }

  async onFormSubmitted() {
    this.closeModal()

    this.submitted = true
    this.message = 'Formulário enviado com sucesso!'
    this.messageType = 'success'

    setTimeout(() => {
      this.submitted = false
    }, 10000)

    await this.fetchAbrigos()
  }

  searchCities: string[]
  handleChangeCity(ev: any) {
    this.searchCities = Array.isArray(ev.detail[0]) ? ev.detail[0]?.map((city) => city.value) : [ev.detail[0].value]
    this.search()
  }
  selectedStatus: boolean | null
  handleStatusChange(ev: any) {
    this.selectedStatus = ev.detail[0].value
    this.search()
  }

  searchShelterType: string[]
  handleChangeTipoAbrigo(ev: any) {
    this.searchShelterType = Array.isArray(ev.detail[0])
      ? ev.detail[0]?.map((shelterType) => shelterType.value)
      : [ev.detail[0].value]

    this.search()
  }

  getNestedValue(obj, field) {
    return field.split('.').reduce((acc, part) => acc && acc[part], obj)
  }

  sortByField(data, field) {
    return data.sort((a, b) => {
      const aValue = this.getNestedValue(a, field)
      const bValue = this.getNestedValue(b, field)

      if (this.sortOrder === 'asc') {
        if (aValue < bValue) return -1
        if (aValue > bValue) return 1
      } else if (this.sortOrder === 'desc') {
        if (aValue > bValue) return -1
        if (aValue < bValue) return 1
      }
      return 0
    })
  }

  sortBySpotField(data, total, ocupadas) {
    return data.sort((a, b) => {
      const a_spots = a.vagas_info[total] - a.vagas_info[ocupadas]
      const b_spots = b.vagas_info[total] - b.vagas_info[ocupadas]
      if (this.sortOrder === 'asc') {
        if (a_spots < b_spots) return -1
        if (a_spots > b_spots) return 1
      } else if (this.sortOrder === 'desc') {
        if (a_spots > b_spots) return -1
        if (a_spots < b_spots) return 1
      }
      return 0
    })
  }

  timeoutSearch: any

  search() {
    if (this.timeoutSearch) clearTimeout(this.timeoutSearch)

    this.timeoutSearch = setTimeout(() => {
      let searchTerm = Array.isArray(this.searchText) ? this.searchText[0] : this.searchText
      searchTerm = removeAccents(searchTerm?.toLowerCase())

      if (!searchTerm || searchTerm?.trim() === '') searchTerm = null

      if (this.searchText || this.searchCities?.length || this.searchShelterType?.length || this.selectedOrderBy) {
        const achados = this.abrigos.filter((abrigo) => {
          const nome = typeof abrigo.nome === 'string' ? removeAccents(abrigo.nome.toLowerCase()) : ''
          const rua = removeAccents(abrigo.endereco.rua?.toLowerCase())
          const cidade = abrigo.endereco.cidade
          const tipoAbrigo = abrigo.local_info.tipo_abrigo
          const status = abrigo.ativo

          const bNome = nome.includes(searchTerm) || rua.includes(searchTerm) || searchTerm === null
          const bCidade = this.searchCities?.includes(cidade) || this.searchCities?.length === 0
          const bClassi =
            this.searchShelterType.some((e) => tipoAbrigo?.includes(e)) || this.searchShelterType.length === 0
          const bStatus = this.selectedStatus === null ? true : status === this.selectedStatus

          return bNome && bCidade && bClassi && bStatus
        })

        if (this.selectedOrderBy !== '') {
          switch (this.selectedOrderBy) {
            case 'name':
              this.sortByField(achados, 'nome')
              break
            case 'city':
              this.sortByField(achados, 'endereco.cidade')
              break
            case 'manager':
              this.sortByField(achados, 'nome_gestor')
              break
            case 'available_spots':
              this.sortBySpotField(achados, 'vagas_totais', 'vagas_ocupadas')
              break
            case 'woman_spots':
              this.sortBySpotField(achados, 'vaga_mulher_quantidade', 'vaga_mulher_quantidade_ocupadas')
              break
            case 'woman_kid_spots':
              this.sortBySpotField(
                achados,
                'vaga_mulheres_criancas_quantidade',
                'vaga_mulheres_criancas_quantidade_ocupadas'
              )
              break
            case 'pregnant_spots':
              this.sortBySpotField(achados, 'vaga_gestantes_quantidade', 'vaga_gestantes_quantidade_ocupadas')
              break
            case 'homeless_spots':
              this.sortBySpotField(achados, 'vaga_moradores_rua_quantidade', 'vaga_moradores_rua_quantidade_ocupadas')
              break
            case 'adult_spots':
              this.sortBySpotField(achados, 'vaga_adultos_quantidade', 'vaga_adultos_quantidade_ocupadas')
              break
            case 'elderly_spots':
              this.sortBySpotField(achados, 'vaga_idosos_quantidade', 'vaga_idosos_quantidade_ocupadas')
              break
            case 'autistic_spots':
              this.sortBySpotField(achados, 'vaga_autistas_quantidade', 'vaga_autistas_quantidade_ocupadas')
              break
            case 'pcd_spots':
              this.sortBySpotField(achados, 'vaga_pcd_quantidade', 'vaga_pcd_quantidade_ocupadas')
              break
            case 'pet_spots':
              this.sortBySpotField(achados, 'vaga_pets_quantidade', 'vaga_pets_quantidade_ocupadas')
              break
            default:
              break
          }
        }
        this.listaFiltrada.next(achados)
      } else {
        this.listaFiltrada.next(this.abrigos)
      }
    }, 500)
  }

  exportToExcel() {
    let abrigos = []

    for (const abrigo of this.abrigos) {
      const {
        id,
        endereco,
        vagas_info,
        equipe_apoio: equipe,
        local_info,
        infra_info,
        atendimento_info,
        itensUteis,
        observations,
        ...rest
      } = abrigo

      const equipe_apoio = equipe
        .map((pessoa) => {
          if (!pessoa || !pessoa.apoio_responsavel) {
            return null
          }

          return `${pessoa.apoio_responsavel} - ${pessoa.apoio_telefone} `
        })
        .join('\n')

      const itens_uteis = itensUteis
        .map((item) => {
          if (!item.item && !item.label && !item.type) {
            return null
          }

          return `${item.quantidade}x ${item.item || item.label || item.type} `
        })
        .join('\n')

      abrigos.push({
        ...rest,
        ...endereco,
        ...vagas_info,
        observations,
        equipe_apoio,
        itens_uteis,
        ...local_info,
        ...infra_info,
        ...atendimento_info,
      })
    }

    let dateAtual = new Date()
    let day = String(dateAtual.getDate()).padStart(2, '0')
    let month = String(dateAtual.getMonth() + 1).padStart(2, '0') // Adicionamos +1 ao mês porque o método getMonth retorna de 0 a 11
    let year = dateAtual.getFullYear()
    let concateDate = day + '_' + month + '_' + year

    const colunas = Object.keys(abrigos[0])

    const abrigosPorColunas = colunas.map((coluna) => abrigos.map((item) => item[coluna]))

    let largurasDasColunas = []

    for (const coluna of abrigosPorColunas) {
      const larguraMaxima = coluna.reduce((acumulador, atual) => {
        return Math.max(acumulador, `${atual}`.length)
      }, 15)

      largurasDasColunas.push({
        wch: larguraMaxima,
      })
    }

    this.excelService.exportToExcel(abrigos, `lista_abrigos_${concateDate}.xlsx`, largurasDasColunas)
  }

  private _onListUpdated(items: AbrigoDTO[]) {
    this.totalItems = items.length

    this.handleResetPagination()
  }

  handlePageChange(newPage: number) {
    this.currentPage = newPage
  }

  handleItemsPerPageChange(newItemsPerPage: number) {
    this.itemsPerPage = newItemsPerPage

    this.handleResetPagination()
  }

  handleResetPagination() {
    this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage)
    this.currentPage = 1
  }

  get currentPageItems(): AbrigoDTO[] {
    const start = (this.currentPage - 1) * this.itemsPerPage
    const end = start + this.itemsPerPage

    return this.listaFiltrada.value.slice(start, end)
  }

  isValidDate(dateString: string): boolean {
    const date = new Date(dateString)

    return !isNaN(date.getTime())
  }
}
