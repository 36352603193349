<fieldset [formGroup]="form">
  <legend>INFRAESTRUTURA</legend>

  <!-- infra do abrigo -->
  <div formGroupName="infra_info" class="mb-3">
    <div class="row">
      <div class="col-6">
        <br-input
          [type]="'text'"
          id="metragem"
          name="metragem"
          placeholder="Metragem do ambiente"
          label="Metragem do ambiente"
          formControlName="metragem"
          (input)="formContainer.handleInput(['infra_info', 'metragem'])"
        >
        </br-input>
      </div>
    </div>

    <div class="divider"></div>

    <div class="row">
      <div class="col">
        <br-input
          type="number"
          min="0"
          id="banheiros"
          name="banheiros"
          placeholder="Quantidade de banheiros"
          label="Quantidade de banheiros"
          formControlName="banheiros"
          (input)="formContainer.handleInput(['infra_info', 'banheiros'])"
        >
        </br-input>
      </div>

      <div class="col">
        <br-input
          type="number"
          min="0"
          id="banheiros"
          name="banheiros"
          placeholder="Quantidade de banheiros químicos disponibilizados pela prefeitura"
          label="Quantidade de banheiros químicos disponibilizados pela prefeitura"
          formControlName="banheiros_prefeitura"
          (input)="formContainer.handleInput(['infra_info', 'banheiros_prefeitura'])"
        >
        </br-input>
      </div>
    </div>

    <div class="row">
      <div class="col-4 d-flex justify-content-start align-items-center switch-col">
        <div class="br-switch switch-full" role="presentation">
          <input
            checked="checked"
            type="checkbox"
            role="switch"
            id="necessidade_servico_limpeza_banheiros"
            name="necessidade_servico_limpeza_banheiros"
            formControlName="necessidade_servico_limpeza_banheiros"
          />
          <label for="necessidade_servico_limpeza_banheiros"
            >Há necessidade de serviços de limpeza/sucção de banheiros químicos para a prefeitura?</label
          >
        </div>
      </div>
    </div>

    <div class="divider"></div>

    <div class="row">
      <div class="col">
        <br-input
          type="number"
          min="0"
          id="chuveiros"
          name="chuveiros"
          placeholder="Quantidade de chuveiros"
          label="Quantidade de chuveiros"
          formControlName="chuveiros"
          (input)="formContainer.handleInput(['infra_info', 'chuveiros'])"
        >
        </br-input>
      </div>

      <div class="col">
        <br-input
          type="number"
          min="0"
          id="chuveiros_prefeitura"
          name="chuveiros_prefeitura"
          placeholder="Quantidade de chuveiros disponibilizados pela prefeitura"
          label="Quantidade de chuveiros disponibilizados pela prefeitura"
          formControlName="chuveiros_prefeitura"
          (input)="formContainer.handleInput(['infra_info', 'chuveiros_prefeitura'])"
        >
        </br-input>
      </div>
    </div>

    <div class="divider"></div>

    <div class="row">
      <div class="col-4 d-flex justify-content-start align-items-center switch-col">
        <div class="br-switch switch-full" role="presentation">
          <input
            checked="checked"
            type="checkbox"
            role="switch"
            id="acessibilidade"
            name="acessibilidade"
            formControlName="acessibilidade"
          />
          <label for="acessibilidade">Há acessibilidade?</label>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <br-input
          [type]="'text'"
          id="obs_acessibilidade"
          name="obs_acessibilidade"
          placeholder="Quais acessibilidades"
          label="Quais acessibilidades?"
          formControlName="obs_acessibilidade"
          (input)="formContainer.handleInput(['infra_info', 'obs_acessibilidade'])"
        >
        </br-input>
      </div>
    </div>

    <div class="divider"></div>

    <div class="row">
      <div class="col-4 d-flex justify-content-start align-items-center switch-col">
        <div class="br-switch switch-full" role="presentation">
          <input
            checked="checked"
            type="checkbox"
            role="switch"
            id="climatizacao"
            name="climatizacao"
            formControlName="climatizacao"
          />
          <label for="climatizacao">Há infraestrutura de climatização?</label>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <br-input
          [type]="'text'"
          id="obs_climatizacao"
          name="obs_climatizacao"
          placeholder="Quais e quantos aparelhos de climatização"
          label="Quais e quantos aparelhos de climatização?"
          formControlName="obs_climatizacao"
          (input)="formContainer.handleInput(['infra_info', 'obs_climatizacao'])"
        >
        </br-input>
      </div>
    </div>

    <div class="divider"></div>

    <div class="row">
      <div class="col-4 d-flex justify-content-start align-items-center switch-col">
        <div class="br-switch switch-full" role="presentation">
          <input
            checked="checked"
            type="checkbox"
            role="switch"
            id="conectividade"
            name="conectividade"
            formControlName="conectividade"
          />
          <label for="conectividade">Há conectividade no local para a equipe que atua no abrigo?</label>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <br-input
          [type]="'text'"
          id="obs_conectividade"
          name="obs_conectividade"
          placeholder="A conectividade foi fornecida pela prefeitura ou por operadora?"
          label="A conectividade foi fornecida pela prefeitura ou por operadora?"
          formControlName="obs_conectividade"
          (input)="formContainer.handleInput(['infra_info', 'obs_conectividade'])"
        >
        </br-input>
      </div>
    </div>

    <div class="divider"></div>

    <div class="row">
      <div class="col d-flex justify-content-start align-items-center switch-col">
        <div class="br-switch" role="presentation">
          <input
            checked="checked"
            type="checkbox"
            role="switch"
            id="cozinha"
            name="cozinha"
            formControlName="cozinha"
          />
          <label for="cozinha">Há estrutura de cozinha no local?</label>
        </div>
      </div>

      <div class="col d-flex justify-content-start align-items-center switch-col">
        <div class="br-switch" role="presentation">
          <input
            checked="checked"
            type="checkbox"
            role="switch"
            id="fornecimento_agua"
            name="fornecimento_agua"
            formControlName="fornecimento_agua"
          />
          <label for="fornecimento_agua">Há fornecimento de água normal?</label>
        </div>
      </div>

      <div class="col d-flex justify-content-start align-items-center switch-col">
        <div class="br-switch" role="presentation">
          <input
            checked="checked"
            type="checkbox"
            role="switch"
            id="caminhao_pipa"
            name="caminhao_pipa"
            formControlName="caminhao_pipa"
          />
          <label for="caminhao_pipa">Depende de caminhão pipa?</label>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-4 d-flex justify-content-start align-items-center switch-col">
        <div class="br-switch" role="presentation">
          <input
            checked="checked"
            type="checkbox"
            role="switch"
            id="fornecimento_eletrica"
            name="fornecimento_eletrica"
            formControlName="fornecimento_eletrica"
          />
          <label for="fornecimento_eletrica">Há fornecimento de energia?</label>
        </div>
      </div>

      <div class="col-4 d-flex justify-content-start align-items-center switch-col">
        <div class="br-switch" role="presentation">
          <input
            checked="checked"
            type="checkbox"
            role="switch"
            id="gerador"
            name="gerador"
            formControlName="gerador"
          />
          <label for="gerador">Há gerador?</label>
        </div>
      </div>
    </div>

    <div class="divider"></div>
  </div>

  <div formGroupName="atendimento_info">
    <div class="row">
      <div class="col-4">
        <br-input
          type="number"
          min="0"
          id="qtd_voluntarios"
          name="qtd_voluntarios"
          placeholder="Quantidade de voluntários"
          label="Quantidade de voluntários?"
          formControlName="qtd_voluntarios"
          (input)="formContainer.handleInput(['atendimento_info', 'qtd_voluntarios'])"
        >
        </br-input>
      </div>
    </div>
  </div>
</fieldset>
