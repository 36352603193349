export const AUTH = 'login'

export const HOME = 'home'

export const MAP = 'mapa'

export const ABRIGOS = {
  BASE: 'abrigo',
  ADD: 'add',
  LIST: 'abrigos',
}

export const ABRIGADO = {
  BASE: 'abrigado',
  ADD: 'add',
  LIST: 'abrigados',
}

export const USUARIOS = 'usuarios'
