import { Component, Input, OnChanges, SimpleChanges, inject } from '@angular/core'
import AbrigoDTO from '../../../DTOs/AbrigoDTO'
import { AbrigoOptionsDTO } from '../../../DTOs/AbrigoOptionsDTO'
import { ClassificacaoOptionsDTO } from '../../../DTOs/ClassificacaoOptionsDTO'
import { LocalOptionsDTO } from '../../../DTOs/LocalOptionsDTO'
import { FormCadastroDeAbrigoComponent } from '../../form-cadastro-de-abrigo.component'

@Component({
  selector: 'app-classification-fieldset',
  templateUrl: './classification-fieldset.component.html',
  styleUrls: ['./classification-fieldset.component.scss'],
})
export class ClassificationFieldsetComponent implements OnChanges {
  @Input('edit')
  public forEdit: AbrigoDTO

  private container = inject(FormCadastroDeAbrigoComponent)

  public localOptions = LocalOptionsDTO
  public classificacaoOptions = ClassificacaoOptionsDTO
  public abrigoOptions = AbrigoOptionsDTO

  get formContainer() {
    return this.container
  }

  get form() {
    return this.container.form
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.handleEdit(changes.forEdit.currentValue)
  }

  private handleEdit(abrigo: AbrigoDTO) {
    this.localOptions = this.localOptions.map((local) => {
      local.selected = abrigo?.local_info.tipo_local?.includes(local.value)

      return local
    })

    this.classificacaoOptions = this.classificacaoOptions.map((classification) => {
      classification.selected = abrigo?.local_info.classificacao_local?.includes(classification.value)

      return classification
    })

    this.abrigoOptions = this.abrigoOptions.map((option) => {
      option.selected = abrigo?.local_info.tipo_abrigo?.includes(option.value)
      return option
    })
  }

  handleSelectOption(option: { value: string; label: string; selected: boolean }) {
    const control = this.form.controls.local_info.controls.tipo_local

    if (!control.value.includes(option.value)) {
      return control.value.push(option.value)
    }

    this.form.get('local_info').patchValue({
      ...this.form.get('local_info').value,
      tipo_local: control.value.filter((value) => value != option.value),
    })

    return control
  }
}
