import { Pipe, PipeTransform } from '@angular/core'
import { Items } from 'src/app/pages/necessidades-form/necessidades-form.component'

@Pipe({
  name: 'itemMeasure',
})
export class itemMeasurePipe implements PipeTransform {
  transform(item: any, items: Items[]): any {
    if (!item && !items) {
      return item
    }

    const itemFromSystem = items.find((itemUtil) => {
      return item.item.toString().toLocaleLowerCase() === itemUtil.value.toLocaleLowerCase()
    })

    if (!itemFromSystem) {
      return '-'
    }

    return itemFromSystem.measure
  }
}
